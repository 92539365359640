import React, {useState, useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { requestForToken, onMessageListener } from './firebase';
import logo from "../Images/logo_new.svg"
// import "./Notification.css";

const Notification = () => {
  const [notification, setNotification] = useState({title: '', body: ''});
  const [appLang, setAppLang] = useState('');
  const notify = () =>  toast(<ToastDisplay/>); 
  function ToastDisplay() {
    return (
    //   <div className='notification_container'>
    //   <div className='logo'>
    //      <img src={logo} className='img' />
    //    </div>
    //  <div className='content'>
    //       <p className='content_head'><b>{notification?.title}</b></p>
    //     <p className='content_body'>{notification?.body}</p>
    //       </div>
    // </div>

    <div class="notification_container">
        <div class="notification_img_container">
        <img src={logo} className='notification_img' />
        </div>
        <div class="notification_text_container">
          <p class="notification_text_head"><b>{notification?.title}</b></p>
          <p class="notification_text_body" style={{textAlign:(appLang == 'ar'?'right':'left'), direction:(appLang == 'ar'?'rtl':'ltr')}}>{notification?.body}</p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setAppLang(localStorage.getItem('nurse_language'));
    if (notification?.title ){
     notify()
    }
  }, [notification])

  requestForToken();

  onMessageListener()
    .then((payload) => {
      setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
    })
    .catch((err) => console.log('failed: ', err));

  return (
     <Toaster/>
  )
}

export default Notification
