/*global google*/
import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker
} from "react-google-maps";
class Map extends Component {
  state = {
    directions: null
  };

  componentDidMount() {
    const directionsService = new google.maps.DirectionsService();

    const origin = { lat: 40.756795, lng: -73.954298 };
    const destination = { lat: 41.756795, lng: -78.954298 };

    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING
      },
      (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result
          });
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }

  render() {
    debugger;
    const GoogleMapExample = withGoogleMap(props => (
      <GoogleMap
      defaultCenter={{ lat: (this.props.locationdata.checkin_latitude? parseFloat(this.props.locationdata.checkin_latitude) : parseFloat(this.props.locationdata.latitude)), lng: (this.props.locationdata.checkin_longitude? parseFloat(this.props.locationdata.checkin_longitude): parseFloat(this.props.locationdata.longitude)) }}
      defaultZoom={13}
    > 

    <Marker position={{ lat: (this.props.locationdata.checkin_latitude? parseFloat(this.props.locationdata.checkin_latitude) : parseFloat(this.props.locationdata.latitude)), lng: (this.props.locationdata.checkin_longitude? parseFloat(this.props.locationdata.checkin_longitude): parseFloat(this.props.locationdata.longitude)) }} />
      {/* <DirectionsRenderer
        directions={this.state.directions}
      /> */}
    </GoogleMap>
    ));

    return (
      <div>
         <GoogleMapExample
          containerElement={<div style={{ height: `calc(100vh - 250px)`, width: "100%" }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      
    );
  }
}

export default Map;