import React, { Component } from "react";
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Nurse_login from './components/Login/Login';
import Forgot from './components/Login/Forgot';
import ResetPassword from "./components/Login/ResetPassword"
import MiniDrawer from './components/Drawer page/Drawerpage';
import PaymentSuccess from "./components/PaymentSuccess/PaymentSuccess";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import axios from "axios";
import history from "./route";
import Notification from './firebaseNotifications/Notification';
import OnlineStatus from "./onlinedecter";
import NotificationComponent from "./components/Notification/Notification.jsx";

//export const apiurl = 'http://52.200.251.222:8158/api/v1/';
//export const apiurl = 'https://tom.theonemoment.com/';
//export const apiurl = 'http://157.241.0.129:8000/';
// export const apiurl = 'http://localhost:8000/';
// export const apiurl = 'https://gateway.theonemoment.net/';

// export const apiurl =  'https://gateway.theonemoment.net/';
export const apiurl =  'https://gateway.theonemoment.com/';
// export const paymentsuccessurl = "http://157.241.0.129:3000/paymentsuccess";
export const paymentsuccessurl = window.location.origin + "/paymentsuccess";
//Staging
export const kneturl = "https://knettest.theonemoment.com/SendPerformREQuest.php?";
//Production
//export const kneturl = "https://knet.theonemoment.com/SendPerformREQuest.php?";
//staging
//export const decryptKey = "G252MRU33W7YB5Q1";
export const decryptKey = "NX706H5HECB8W336";
//production
//export const decryptKey = "N5CVV5M85UH6H005";

var tokenInterVel = "";

axios.interceptors.request.use(function (request) {
  request.headers["authorization"] = "Bearer " + localStorage.getItem("tokenHeader");
  return request;
})

export const initialize = () => {
  let reFreshToken = localStorage.getItem("refreshtoken");
  if (reFreshToken != undefined && reFreshToken != null && reFreshToken != "") {
    tokenGeneration();
    tokenInterVel = setInterval(() => {
      tokenGeneration();
    }, 3480000);
  }
}

export const formatMoney = (number) => {
  const num = Number(number)
  const options = {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3
  };

  const formattedNumber = num.toLocaleString('en-US', options);
  const parts = formattedNumber.split('.');

  if (parts.length === 1) {
    return `${formattedNumber}.000`;
  } else {
    const decimalPart = parts[1].padEnd(3, '0');
    return `${parts[0]}.${decimalPart}`;
  }
}

export const tokenGeneration = () => {
  let reFreshToken = localStorage.getItem("refreshtoken");
  if (reFreshToken != "") {
    axios({ method: "POST", url: apiurl + "tokenGenerate", data: { "refreshtoken": localStorage.getItem("refreshtoken") } }).then((response) => {
      if (response.data.msg == "success") {
        localStorage.setItem("tokenHeader", response.data.token);
      } else {
        localStorage.setItem("tokenHeader", "");
        localStorage.setItem("refreshtoken", "");
        clearInterval(tokenInterVel);
        history.push('/');
      }
    })
  }
}

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderData: false,
    };
  }
  initialize = () => {
    let reFreshToken = localStorage.getItem("refreshtoken");
    // if (reFreshToken != undefined && reFreshToken != null && reFreshToken != "") {
    this.tokenGeneration();
    tokenInterVel = setInterval(() => {
      this.tokenGeneration();
    }, 3480000);
    // } else {
    //   this.setState({ renderData: true });
    // }
  }

  componentDidMount() {
    // this.initialize();
  }

  navigateToBack = (path) => {
    history.push('/');
  };

  tokenGeneration = () => {
    axios({ method: "POST", url: apiurl + "tokenGenerate", data: { "refreshtoken": localStorage.getItem("refreshtoken") } }).then((response) => {
      if (response.data.msg != "Authentication failed") {
        localStorage.setItem("tokenHeader", response.data.token);
        this.setState({ renderData: true });
      } else {
        this.setState({ renderData: true });
        this.navigateToBack("/");
      }
    })
  }

  render() {
    return (
      <div>
        <OnlineStatus />
        <Router basename="nursemodule/?/">
          <Route exact path="/" component={Nurse_login} />
          <Route path={"/forgot"} component={Forgot} />
          <Route path="/ResetPassword/:uuid" component={ResetPassword} />
          <Route path="/Home" component={MiniDrawer} />
          <Route path="/paymentsuccess" component={PaymentSuccess} />
          <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route path="/notification" component={NotificationComponent} />
        </Router>
        <Notification />

      </div>
    );
  }
}

