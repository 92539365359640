import React from "react";
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import moment from "moment";
import Labelbox from "../../../helpers/labelbox/labelbox";

export default class Monthpicker extends React.Component {
    state = {
        item: [{
            startDate: this.props.startDate ? this.props.startDate : '',
            endDate: this.props.endDate ? this.props.endDate : '',
        }],
        selectedMonth: this.props.s_month ? this.props.s_month : '',
        openDateRange: false,
        SelectRange: ''
    }

    handleMonthChange = (event) => {
        debugger;
        const selectedMonthEvent = event.target.value;
        if (selectedMonthEvent !== '') {
            const selectedMonth1 = moment(selectedMonthEvent);
            const startOfMonth = selectedMonth1.clone().startOf('month').format('YYYY-MM-DD');
            const endOfMonth = selectedMonth1.clone().endOf('month').format('YYYY-MM-DD');
            this.setState({
                selectedMonth: selectedMonthEvent,
                item: [{
                    startDate: startOfMonth,
                    endDate: endOfMonth
                }]
            }, () => this.props.rangeDate(this.state.item));
        } 
        // else {
        //     const currentDate = moment();
        //     const startOfMonthDate = currentDate.clone().startOf('month');
        //     const endOfMonthDate = currentDate.clone().endOf('month');

        //     const startOfMonth = format(startOfMonthDate.toDate(), 'yyyy-MM-dd');
        //     const endOfMonth = format(endOfMonthDate.toDate(), 'yyyy-MM-dd');

        //     this.setState({
        //         selectedMonth: selectedMonthEvent,
        //         item: [{
        //             startDate: startOfMonth,
        //             endDate: endOfMonth
        //         }]
        //     }, () => this.props.rangeDate(this.state.item));
        // }
    }

    render() {
        return (
            <div style={{ zIndex: "1" }}>
                <div className="rangePicker_Master">
                    <div className="rangePicker_Label">
                        {
                        localStorage.nurse_language=='ar'?"اختر الشهر :" :"Select Month :"
                        }
                        
                    </div>
                    <div className="rangePicker_InpputIconAlign" >
                        <input type="month" id="start" name="start" min="2018-03" value={this.state.selectedMonth}
                            onChange={this.handleMonthChange} />
                    </div>
                </div>
                {this.state.openDateRange &&
                    <input type="month" id="start" name="start" min="2018-03" value="2018-05" />
                }
            </div>
        )
    }
}
