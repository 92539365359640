import React, { Component } from "react";
import Nursepage from "./Nurse_dash_page";
import Paper from "@material-ui/core/Paper";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import './Nurse_dash_page.css';
import i18n from "../../i18n/i18n";
import { withTranslation } from 'react-i18next';

class Nurse_dash_master extends Component {
  handleSelectDate = (data) => {
    if (data && data[0]) {
      this.refs.nurseDashboardPageRef.AppointmentGetData(data[0]);
    }
  };
  render() {
    const {t}=this.props
    return (
      <div>
        <Paper>
          <div className="title_dashboard"   style={{direction: (this.props.i18n.language == 'ar' ? 'rtl' : '') }}>
            <div className="title_header"> {t('nurse_dashboard_heading')} </div>

            {/* <DateRangeSelect
              dynalign={"dashboard-date-range-picker"}
              rangeDate={(item) => this.handleSelectDate(item)}
              t={t}
              i18n={this.props.i18n}
            /> */}
          </div>

          <Nursepage ref="nurseDashboardPageRef" t={t} i18n={this.props.i18n}/>
        </Paper>
      </div>
    );
  }
}
export default withTranslation()(Nurse_dash_master);
