/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Labelbox from '../../helpers/labelbox/labelbox'
import Button from '@material-ui/core/Button';
import './UploadInstruction.css'
// import uploadimage from '../../Images/upload-button.png'
import Profile from '../../Images/1.jpg'
import Green from '../../Images/green.svg'
import './UploadInstruction.css'

export default class UploadMedia extends Component {
    constructor(props)
    {
        super(props)
        this.state={name:""}
    }
    render() {
        const {t}= this.props
        return (
            <div>
               <div className={`${this.props.i18n.language=='ar'?"justify-content-end":""} green_upload_container  d-flex align-items-center`}>
                   <div className={`${this.props.i18n.language=='ar'?"order_1":"order_0"} green_uploadint`}></div>
                   <span className="upload_ins">
                      {t('upload_instr_1')}
                    </span>
                </div>
               <div className={`${this.props.i18n.language=='ar'?"justify-content-end":""} green_upload_container  d-flex align-items-center`}>
                   <div className={`${this.props.i18n.language=='ar'?"order_1":"order_0"} green_uploadint`}></div>
                   <span  className="upload_ins">
                   {t('upload_instr_2')}
                    </span>
                </div>
               <div className={`${this.props.i18n.language=='ar'?"justify-content-end":""} green_upload_container  d-flex align-items-center`}>
                   <div className={`${this.props.i18n.language=='ar'?"order_1":"order_0"} green_uploadint`}></div>
                   <span  className="upload_ins">
                   {t('upload_instr_3')}
                    </span>
                </div>
                {/* <div className={`${this.props.i18n.language=='ar'?"justify-content-end":""} green_upload_container  d-flex align-items-center`}>
                   <div className={`${this.props.i18n.language=='ar'?"order_1":"order_0"} green_uploadint`}></div>
                   <span  className="upload_ins">
                   {t('upload_instr_4')} {this.props.size} {t('upload_instr_5')}
                    </span>
                </div> */}
            </div>
        )
    }
}
