import React from "react";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import "./TicketTable.css";
import Axios from "axios";
import { apiurl } from "../../App";
import TicketModal from "./TicketModal";
import TicketView from "./TicketView";
import plus from "../../Images/plus.png";
import TickMark from '../../Images/Group23311.svg';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
let moment = require("moment");
class TicketTable extends React.Component {
  state = {
    open: false,
    openview: false,
    editopen: false,
    getTableData: [],
    tabledata: [],
    props_loading: false,
    editdetails: [],
    fulltabledata: [],
    Search: null,
    deleteopen: false,
    openTicketSuccess: false,
    ticket_no: "",
    open_ticket_view: false,
    ticket_success_msg: ""
  };

  modelopen = (data, id) => {
    if (data === "view") {
      let editdetails = this.state.fulltabledata.filter((data) => {
        return data.ticket_id === id;
      });
      this.setState({ editdetails: editdetails[0], open_ticket_view: true });
    } else if (data === "edit") {
      let editdetails = this.state.fulltabledata.filter((data) => {
        return data.ticket_id === id;
      });
      this.setState({
        editdetails: editdetails[0],
        editopen: true,
      });
    }
    else if (data === "reopen") {
      let editdetails = this.state.fulltabledata.filter((data) => {
        return data.ticket_no === id;
      });
      this.setState({ ticket_no: editdetails[0]['ticket_no'] })
      this.ticketStatusChange(editdetails[0]['ticket_no'], 'RO')
    }
    else if (data === "resolved") {
      let editdetails = this.state.fulltabledata.filter((data) => {
        return data.ticket_no === id;
      });
      this.setState({ ticket_no: editdetails[0]['ticket_no'] })
      this.ticketStatusChange(editdetails[0]['ticket_no'], 'RE')
    }
  };

  closemodal = () => {
    this.setState({ editopen: false, editopen: false, deleteopen: false });
  };
  componentDidMount() {
    this.setState({ props_loading: true });
    this.getTableData();

    this.intervalId = setInterval(() => {
      this.setState({ props_loading: true });
      this.getTableData();
    }, 120000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  convertTo12HourFormat = (time24) => {
    if (time24) {
      const [hour, minute] = time24.split(":");
      let period = "am";

      let hour12 = parseInt(hour);

      if (hour12 >= 12) {
        if (hour12 > 12) {
          hour12 -= 12;
        }
        period = "pm";
      }
      const hour12Formatted = hour12.toString().padStart(2, "0");
      return `${hour12Formatted}:${minute} ${period}`;
    } else {
      return "N/A";
    }
  }

  formatDate(date) {
    const dateeeee = new Date(date);
    const hours = dateeeee.getHours();
    const minutes = dateeeee.getMinutes();
    const zeromin = 0;
    const new_minutes = minutes < 10 ? `${zeromin}${minutes}` : minutes;
    const period = hours < 12 ? 'AM' : 'PM';
    const formattedHours = hours % 12 || 12;

    return `${formattedHours}:${new_minutes} ${period}`;
  }

  convertTo12HourFormat = (time24) => {
    if (time24) {
      const [hour, minute] = time24.split(":");
      let period = "am";

      let hour12 = parseInt(hour);

      if (hour12 >= 12) {
        if (hour12 > 12) {
          hour12 -= 12;
        }
        period = "pm";
      }
      const hour12Formatted = hour12.toString().padStart(2, "0");
      return `${hour12Formatted}:${minute} ${period}`;
    } else {
      return "N/A";
    }
  }

  getTableData = () => {
    Axios({
      method: "post",
      url: apiurl + "admin/get_vendor_ticket",
      data: {
        vendor_id: localStorage.nurse_vendor_id ? localStorage.nurse_vendor_id : 18,
        limit: 1000,
        pageno: 1,
      },
    }).then((response) => {
      let tabledata = [];
      let fulltabledata = [];
      if (response.data.status != 0) {
        response.data.data.map((val, index) => {
          let response = val.comments_arr ? JSON.parse(val.comments_arr)[(JSON.parse(val.comments_arr).length - 1)]['comments'] : ""
          tabledata.push({
            ticket_id: val.ticket_no,
            screen_name: val.screen_name,
            status: this.getStatusByCode(val.status),
            date: moment(val.ticket_date).format("DD-MMM-YY"),
            time: this.convertTo12HourFormat(val.booked_time),
            id: val.ticket_id,
          });
          fulltabledata.push(val);
        });
      }
      this.state.tabledata = tabledata;
      this.setState({

        fulltabledata: fulltabledata,
        props_loading: false,
      });
    });
  };

  getStatusByCode = (data) => {
    switch (data) {
      case "P":
        return "Pending"
        break;
      case "RO":
        return "Reopened"
        break;
      case "C":
        return "Closed"
        break;
      case "RE":
        return "Resolved"
        break;
      default:
        return ""
        break;
    }
  }

  handleClickopen = () => {
    this.setState({ open: true });
  };
  handleClickclose = () => {
    this.setState({ open: false });
  };

  ticketSuccess = (ticket_no) => {
    const { t } = this.props;
    const Ticketraisedsuccessfully = t("Ticketraisedsuccessfully")
    this.setState({ open: false, openTicketSuccess: true, ticket_no: ticket_no, ticket_success_msg: Ticketraisedsuccessfully });
  }

  handleCloseSuccess = () => {
    this.setState({ openTicketSuccess: false, props_loading: true }, () => {
      this.getTableData()
    })
  }

  handleTicketViewClose = () => {
    this.setState({ open_ticket_view: false });
  }

  ticketStatusChange = (id, status) => {
    Axios({
      method: "post",
      url: apiurl + "admin/update_ticket_status",
      data: {
        ticketId: id,
        status: status,
        module_name: "Nurse",
      },
    }).then((response) => {
      if (response.data.data[0]['ErrorCode'] == "9999") {
        this.setState({ open: false, openTicketSuccess: true, ticket_no: this.state.ticket_no, ticket_success_msg: (status == 'RO'
            ? localStorage.nurse_language == 'ar' ? '!تم إعادة فتح الشكوى بنجاح' : "Your ticket reopened successfully!"
            : localStorage.nurse_language == 'ar' ? '!تم حل الشكوى بنجاح' : "Your ticket resolved successfully!"
          ) });  
      }
      else {
        this.setState({ open: false, openTicketSuccess: true, ticket_no: this.state.ticket_no, ticket_success_msg: (status == 'RO'
           ? 'Your ticket reopened failed!'
           : 'Your ticket resolved failed!'
          ) });

      }
    });
  }
  render() {
    const { t } = this.props
    const heading = (localStorage.nurse_language == 'ar') ? [
      { id: "", label: this.props.t("tbl_head_action") },
      { id: "time", label: this.props.t("tbl_head_time") },
      { id: "date", label: this.props.t("tbl_head_date") },
      { id: "status", label: this.props.t("tbl_head_status") },
      { id: "screen_name", label: this.props.t("tbl_head_screen_name") },
      { id: "ticket_id", label: this.props.t("tbl_head_ticket_id") },
      { id: "", label: this.props.t("tbl_head_s_no") },
    ] : [
      { id: "", label: this.props.t("tbl_head_s_no") },
      { id: "ticket_id", label: this.props.t("tbl_head_ticket_id") },
      { id: "screen_name", label: this.props.t("tbl_head_screen_name") },
      { id: "status", label: this.props.t("tbl_head_status") },
      { id: "date", label: this.props.t("tbl_head_date") },
      { id: "time", label: this.props.t("tbl_head_time") },
      { id: "", label: this.props.t("tbl_head_action") },
    ]
    return (
      <div>
        <div className="dashboard_header head_font">
          <div className="dashboard_title">{t('support_head')}</div>
          <div className="current_container">
            <img
              className="plus-icon"
              src={plus}
              alt={"hi"}
              style={{ width: 35, height: 35, cursor: "pointer" }}
              onClick={() => { this.handleClickopen() }}
            />
          </div>
        </div>
        <div className="ticket_table_div">
          <Tablecomponent
            heading={heading}
            rowdata={this.state.tabledata}
            modelopen={(e, id) => this.modelopen(e, id)}
            ShowTicketButton="true"
            EditIcon={"close"}
            DeleteIcon={"close"}
            LocationOnIcon={"close"}
            HistoryIcon={"close"}
            props_loading={this.state.props_loading}
            t={t}
            i18n={this.props.i18n}
          />
        </div>

        <Modalcomp
          visible={this.state.open}
          xswidth={"xs"}
          title={t('add_ticket')}
          clrchange="text_clr_change"
          closemodal={(e) => this.handleClickclose(e)}
          t={t}
          i18n={this.props.i18n}
        >
          {<TicketModal
            className="manage-modal"
            open={this.state.open}
            closemodal={this.handleClickclose}
            getTableData={this.getTableData}
            ticketSuccess={this.ticketSuccess}
            t={t}
            i18n={this.props.i18n}
          />}
        </Modalcomp>

        <Modalcomp
          visible={this.state.open_ticket_view}
          xswidth={"xs"}
          title={t('view_ticket')}
          clrchange="text_clr_change"
          closemodal={(e) => this.handleTicketViewClose(e)}
          t={t}
          i18n={this.props.i18n}
        >
          {<TicketView
            className="manage-modal"
            closemodal={this.handleTicketViewClose}
            {...this.state}
            t={t}
            i18n={this.props.i18n}
          />}
        </Modalcomp>



        <Dialog
          className='Dialogmodal ticket_table_success'
          open={this.state.openTicketSuccess}
          aria-labelledby="customized-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          disableBackdropClick={true}
        >
          <div>
            <div style={{ marginTop: '3rem', textAlign: 'center' }}>
              <img src={TickMark} className="success_tick_mark" />
            </div>
            <div className="ticket_success_head" style={{ marginTop: '1rem', textAlign: 'center', marginBottom: '1rem', fontSize: '20px', color: 'let(--app-theme)', fontWeight: '400 !important' }}>{t("success_head")} </div>
            <div style={{ marginTop: '1rem', textAlign: 'center', fontSize: '16px', color: 'black', fontWeight: '400 !important' }}> {this.state.ticket_success_msg}</div>
            <div style={{ textAlign: 'center', fontSize: '16px', marginBottom: '1rem', color: 'black', fontWeight: '400 !important',direction:localStorage.nurse_language == 'ar'?"rtl":"" }}>{t("yourTicketIDis")}{this.state.ticket_no}.</div>
            <div className="contract_approve_btn_div" style={{ marginBottom: '3rem' }}>
              <Button className="ticketSubmit" onClick={() => { this.handleCloseSuccess() }}> {t("ok")} </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
};

TicketTable.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  closemodal: PropTypes.func.isRequired,
  getTableData: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editData: PropTypes.shape({
    id: PropTypes.string,
  }),
};
TicketTable.defaultProps = {
  editData: null,
};
export default TicketTable;
