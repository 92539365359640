import React from "react";
import "./PrintData.css";
import Logo from "../../Images/Logo.png";
import whiteLogo from "../../Images/tom_white.svg";
import dateFormat from "dateformat";
import { formatMoney } from "../../App";

export default class PrintData extends React.Component {
  renderTableChunk = (dataChunk, startIndex) => {
    const { type, HeadingButton } = this.props;
    const representativePrintData = HeadingButton && HeadingButton.length > 0 ? HeadingButton[0] : null;

    return (
      <div className="printtabledata" key={startIndex}>
        <div className="row">
          <img className="col-2 tom_logo_print" src={Logo} style={{ width: "300px", height: "60px" }} alt="image error" />
          {/* <div className="col-12 printDataTitle">Revenue Details</div> */}
          {type === 'totalrec' && (<div className="printDataTitle col-12">Settlement Details </div>)}
          {type === 'Revenue' && (<div className="printDataTitle col-12">Revenue Details </div>)}
          <div className="col-12 printDataSecTitle">All values in KWD</div>
          <div className="col-12">
            <table style={{ marginBottom: '10vh' }}>
              {type === 'totalrec' && (
                <thead>
                  <tr className="print_header">
                    <th className="print_header_text23" style={{ textAlign: "center" }}>S.No</th>
                    <th className="print_header_text23" style={{ textAlign: "center" }}>Settlement Month</th>
                    <th className="print_header_text23" style={{ textAlign: "center" }}>Transaction</th>
                    <th className="print_header_text23" style={{ textAlign: 'center' }}>Settlement ID</th>
                    <th className="print_header_text23" style={{ textAlign: 'center' }}>Receivable</th>
                  </tr>
                  <tr>
                    <th className="print_header_textss"></th>
                    <th className="print_header_textss"></th>
                    <th className="print_header_textss"></th>
                    <th className="print_head_Text_color1" style={{ textAlign: 'center' }}>Total</th>
                    <th className="print_head_Text_color1" style={{ textAlign: 'right' }}>{representativePrintData ? representativePrintData.revenueTotal : ''}</th>
                  </tr>
                </thead>
              )}
              {type === 'Revenue' && (
                <thead>
                  <tr className="print_header">
                    <th className="print_header_text" style={{ textAlign: "center" }}>S.No</th>
                    <th className="print_header_text" style={{ textAlign: "center" }}>Date</th>
                    <th className="print_header_text" style={{ textAlign: "center" }}>Member</th>
                    <th className="print_header_text" style={{ textAlign: "center" }}>Nurse Name</th>
                    <th className="print_header_text" style={{ textAlign: 'center' }}>Fee</th>
                    <th className="print_header_text" style={{ textAlign: 'center' }}>
                      <div className="Tom_logo_text"> <img className="row_image" src={whiteLogo} alt="image error" />Share</div>
                    </th>
                    <th className="print_header_text" style={{ textAlign: 'center' }}>Income</th>
                  </tr>
                  <tr>
                    <th className="print_header_textss"></th>
                    <th className="print_header_textss"></th>
                    <th className="print_header_textss"></th>
                    <th className="print_head_Text_color1" style={{ textAlign: 'center' }}>Total</th>
                    <th className="print_head_Text_color1" style={{ textAlign: 'right' }}>{representativePrintData ? representativePrintData.revenueFee : ''}</th>
                    <th className="print_head_Text_color1" style={{ textAlign: 'right' }}>{representativePrintData ? representativePrintData.revenueTomshare : ''}</th>
                    <th className="print_head_Text_color1" style={{ textAlign: 'right' }}>{representativePrintData ? representativePrintData.revenueTotal : ''}</th>
                  </tr>
                </thead>
              )}
              {/* Add other conditions for different types similarly */}
              <tbody>
                {dataChunk.map((printdata, index) => (
                  <tr key={index + startIndex}>
                    {type === 'Revenue' && (
                      <>
                        <td className="print_header_textss " style={{ textAlign: 'center' }}>{index + 1}</td>
                        <td className="print_header_textss" style={{ textAlign: 'center' }}>{dateFormat(printdata.book_date, "dd-mmm-yy")}</td>
                        <td className="print_header_textss">{printdata.name}</td>
                        <td className="print_header_textss">{printdata.nurseName}</td>
                        <td className="print_header_textss" style={{ textAlign: 'right' }}>{formatMoney(printdata.rate)}</td>
                        <td className="print_header_textss" style={{ textAlign: 'right' }}>{formatMoney(printdata.tomShare)}</td>
                        <td className="print_header_textss" style={{ textAlign: 'right' }}>{formatMoney(printdata.income)}</td>
                      </>
                    )}
                    {type === 'totalrec' && (
                      <>
                        <td className="print_header_textss" style={{ textAlign: 'center' }}>{index + startIndex + 1}</td>
                        <td className="print_header_textss" style={{ textAlign: 'center' }}>{dateFormat(printdata.settlement_date, 'mmm-yy')}</td>
                        <td className="print_header_textss" style={{ textAlign: 'center' }}>{printdata.transaction_count}</td>
                        <td className="print_header_textss" style={{ textAlign: 'center' }}>{printdata.settlement_no}</td>
                        <td className="print_header_textss" style={{ textAlign: 'right' }}>{printdata.settlement_amount}</td>
                      </>
                    )}
                    {/* Add other conditions for different types similarly */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>


        <div className="page-break"></div>
      </div>
    );
  };

  render() {
    const rowsPerPage = 30; // Adjust this number based on your page layout and font size
    const { printTableData } = this.props;
    const totalChunks = Math.ceil(printTableData.length / rowsPerPage);

    const tableChunks = [];
    for (let i = 0; i < totalChunks; i++) {
      const startIndex = i * rowsPerPage;
      const dataChunk = printTableData.slice(startIndex, startIndex + rowsPerPage);
      tableChunks.push(this.renderTableChunk(dataChunk, startIndex));
    }

    return <div>{tableChunks}</div>;
  }
}
