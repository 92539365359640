import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import axios from "axios";
import Card from "@material-ui/core/Card";
import AppBar from "@material-ui/core/AppBar";
import { apiurl } from "../../App";
import NotFound from "../../assests/images/NotFound.svg";
import './Notification.css';

class NotificationComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Var_NotificationData: [],
            Var_NoData: [],
            loading: true,
            Var_DoctorDetailShow: null,
            Var_Dates: new Date().toLocaleDateString('fr-CA'),
            Var_getClinicWiseDoctor: [],
            Var_doctorSpecialitySelect: "",
            Var_specialityid: null,
            currentDate: new Date(),
        };
    }

    componentDidMount() {
        this.newNotification();
    }

    handleOnClick = (path) => {
        this.props.history.push("/Home" + path);
    };

    newNotification = () => {
        if (localStorage.getItem("nurse_vendor_id")) {
            axios({
                method: "POST",
                url: apiurl + "admin/notification_list",
                data: {
                    vendor_id: localStorage.getItem("nurse_vendor_id")
                        ? localStorage.getItem("nurse_vendor_id")
                        : 0,
                },
            }).then((response) => {
                if (response.data.length !== 0) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        if (response.data.data[i].created_on != '' && response.data.data[i].created_on != null) {
                            const dateTimeString = response.data.data[i].created_on;
                            const dateObject = new Date(dateTimeString);
                            const year = dateObject.getFullYear();
                            const month = String(dateObject.getMonth() + 1).padStart(2, '0');
                            const day = String(dateObject.getDate()).padStart(2, '0');
                            const hours = String(dateObject.getHours()).padStart(2, '0');
                            const minutes = String(dateObject.getMinutes()).padStart(2, '0');
                            const seconds = String(dateObject.getSeconds()).padStart(2, '0');
                            const date = `${year}-${month}-${day}`;
                            const time = `${hours}:${minutes}:${seconds}`;
                            response.data.data[i].notification_date = (date) ? this.formatDate(date) : '';
                            response.data.data[i].time = (time) ? this.formatTime(time) : '';
                        }
                    }
                    this.setState({
                        Var_NotificationData: response.data.data,
                        loading: false
                    });
                } else {
                    this.setState({
                        Var_NotificationData: response.data.data.length,
                        loading: false
                    });
                }
            })
                .catch((error) => {
                    console.error(JSON.stringify(error));
                });
        } else {
            window.localStorage.clear();
            window.location.assign("/?/nursemodule");
        }
    }

    formatDate(inputDate) {
        let dateParts = inputDate.split("-");
        let year = dateParts[0].substring(2);
        let month = parseInt(dateParts[1], 10);
        let day = parseInt(dateParts[2], 10);
        let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let formattedDate = day.toString().padStart(2, '0') + "-" + monthNames[month - 1] + "-" + year;
        return formattedDate;
    }

    formatTime(inputTime) {
        let timeParts = inputTime.split(":");
        let hours = parseInt(timeParts[0], 10);
        let minutes = timeParts[1];
        let amOrPm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12 || 12;
        let formattedTime = hours.toString().padStart(2, '0') + ":" + minutes + " " + amOrPm;
        return formattedTime;
    }

    notificationLink = (data) => {
        const dec = data.description.includes("deposited into your account")
        switch (data.content_type) {
            case "Ticket":
                this.handleOnClick("/ticket");
                break;
            case "Deal":
                this.handleOnClick("/deals");
                localStorage.setItem('contentTypeData', "DealNotify");
                break;
            case "Advertisement reviewed":
                this.handleOnClick("/advertisement");
                localStorage.setItem('contentTypeData', "AdvertisementNotify");
                break;
            case "Advertisement Rejected":
                this.handleOnClick("/advertisement");
                localStorage.setItem('contentTypeData', "AdvertisementNotify");
                break;
            case "Advertisement Approved":
                this.handleOnClick("/advertisement");
                localStorage.setItem('contentTypeData', "AdvertisementNotify");
                break;
            case "Booking":
                this.handleOnClick("/bookingyettostart");
                break;
            case "Deal Booking":
                this.handleOnClick("/bookingyettostart");
                break;
            case null:
                if(dec){
                    this.handleOnClick("/revenue");
                }
                break;
        }
    }

    render() {
        const { loading, Var_NotificationData, Var_NoData } = this.state;
        const { t } = this.props;
        const { currentDate } = this.state;
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
        const day = ('0' + currentDate.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;

        return (
            <AppBar
                position="fixed"
            >
                <div>
                    <div className="sub_header">
                        <div className="sub_header_body" style={{textAlign:this.props.i18n.language == 'ar' ? 'right' : 'left', direction: (this.props.i18n.language == 'ar' ? 'rtl' : '')}}>
                            <div className="back_navigation" onClick={() => this.handleOnClick("/dashboard")}>
                                { this.props.i18n.language != 'ar' && 
                                    <i className="fa fa-angle-left" aria-hidden="true" style={{textAlign:this.props.i18n.language == 'ar' ? 'right' : 'left', direction: (this.props.i18n.language == 'ar' ? 'rtl' : '')}}></i>
                                }
                                { this.props.i18n.language == 'ar' && 
                                    <i className="fa fa-angle-right" aria-hidden="true" style={{textAlign:this.props.i18n.language == 'ar' ? 'right' : 'left', direction: (this.props.i18n.language == 'ar' ? 'rtl' : '')}}></i>
                                }
                                <span style={{marginRight:this.props.i18n.language == 'ar' ? '13px' : ''}}>
                                    {t("notification")}
                                </span>
                            </div>
                            <div className="flex_grow"></div>
                            <div className="header_right_content"></div>
                        </div>
                    </div>
                    <div className='content_view' style={{ boxShadow: 'none !important' }}>
                        {Var_NotificationData.length > 0 &&
                            Var_NotificationData.map((item, i) => (
                                <Card className='notification_content_card' onClick={() => this.notificationLink(item)} style={{cursor:"pointer"}} key={i}>
                                    <div className="d_flex" style={{direction: (this.props.i18n.language == 'ar' ? 'rtl' : '')}}>
                                        <div className="notification_content">
                                            <p className='email_id content' style={{textAlign:this.props.i18n.language == 'ar' ? 'right' : 'left'}}>{item.description}</p>
                                        </div>
                                        <div className="email_id content_time" style={{textAlign:this.props.i18n.language == 'ar' ? 'left' : '', direction: (this.props.i18n.language == 'ar' ? 'ltr' : '')}}>{item.notification_date + ', ' + item.time}</div>
                                    </div>
                                </Card>
                            ))
                        }
                        {
                            Var_NotificationData === 0 &&
                            <div style={{ display: Var_NoData, height: "70vh" }}>
                                <p className="cancel_no_data">
                                    <img src={NotFound} className="cancel_no_data_image" alt="No Data Found" />
                                    No Data Found
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </AppBar>
        )
    }
}

export default withTranslation()(NotificationComponent);