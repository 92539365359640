import React, { Component } from 'react';
import dateFormat from "dateformat";
import ReactSVG from "react-svg";
import "../CommonPDF/PDF.css";
import pdf from "../../Images/pdf.svg";
import html2pdf from 'html2pdf.js';
import { notification } from "antd";
import moment from 'moment';
import Logo from "../../Images/Logo.png";
import tomimg from "../../assests/images/tomImagewhite.png";
import { formatMoney } from "../../App";

export default class PDF extends Component {
 
    Notification = () => {
        notification.info({
            description: "No Data Found",
            placement: "topRight",
        });
    };

    generatePdf = () => {
        const { weekMonthYearData, filename } = this.props;
        if (weekMonthYearData.length === 0) {
            this.Notification(); // Notify if no data
            return;
        }
        const element = document.getElementById('pdfContainer');
        if (element) {
            const filenameToUse = (filename || "Document") + ".pdf"; // dynamic filename generation
            html2pdf()
                .from(element)
                .set({
                    filename: filenameToUse,
                    jsPDF: {
                        unit: 'pt',
                        format: 'a4',
                        orientation: 'portrait'
                    },
                    html2canvas: {
                        scale: 4,  // Increase the scale factor for higher resolution
                        useCORS: true,
                        dpi: 500,  // Increase the DPI (dots per inch)
                        letterRendering: true,
                    },
                    pagebreak: { mode: 'avoid-all' } // Avoid page breaks inside elements
                })
                .save();
        } else {
            console.error("PDF container not found.");
        }
    };

    calculateRowsPerPage = () => {
        // Example calculation based on the content height
        const contentHeight = 800; // Height available for content in points (1 inch = 72 points)
        const headerHeight = 100; // Height of header in points
        const footerHeight = 50; // Height of footer in points
        const rowHeight = 30; // Estimated height of each row in points

        // Calculate available space for content
        const availableSpace = contentHeight - headerHeight - footerHeight;

        // Calculate rowsPerPage dynamically
        const rowsPerPage = Math.floor(availableSpace / rowHeight);
        return rowsPerPage;
    };

    componentDidMount() {
        this.centerContentOnPages();
    }

    componentDidUpdate() {
        this.centerContentOnPages();
    }

    centerContentOnPages = () => {
        const pageContainers = document.getElementsByClassName('page-container');
        if (pageContainers.length > 0) {
            Array.from(pageContainers).forEach((container, index) => {
                const pageContent = container.querySelector('.page-content');
                if (pageContent && index !== 0) { // Skip setting margin-top for the first page
                    // Add margin-top for all subsequent pages
                    pageContent.style.marginTop = '50px'; // Adjust the value (50px) as needed
                }
            });
        }
    };

    render() {
        const { weekMonthYearData, headRows, headingTitle, HeadingButton, HeadingButton1, type } = this.props;
        const rowsPerPage = this.calculateRowsPerPage(); // Calculate rows per page dynamically
        const totalChunks = Math.ceil(weekMonthYearData.length / rowsPerPage); // Total number of pages
        const chunkedData = [];

        // Chunking the data
        for (let i = 0; i < weekMonthYearData.length; i += rowsPerPage) {
            chunkedData.push(weekMonthYearData.slice(i, i + rowsPerPage));
        }

        return (
            <>
                <ReactSVG
                    src={pdf}
                    onClick={this.generatePdf}
                    style={{
                        cursor: 'pointer',
                        marginRight: this.props.lang === 'ar' ? '15px' : '15px',
                        marginLeft: this.props.lang === 'ar' ? '' : '15px'
                    }}
                />
                <div style={{ display: 'none' }}>
                    <div className="pdfttabledata" id="pdfContainer">
                        {chunkedData.map((chunk, pageIndex) => (
                            <div key={pageIndex} className="page-container" style={{ marginBottom: pageIndex !== 0 ? '30%' : '30%' }}>
                                {/* Elements for each page */}
                                <div className="page-content">
                                    <img className="tom_logo_print" src={Logo} alt="image error" />
                                    <div className="pdfDataTitle">
                                        {headingTitle}
                                    </div>
                                    <div className='kwd_vlaues_font'>All values in KWD</div>
                                </div>

                                {/* Table for each page */}
                                <table className="page">
                                    <thead className="print_header">
                                        <tr>
                                            {headRows.map((row) => (
                                                <th key={row.id} className="print_header_text1">
                                                    {row.id === "tomShare" ? (
                                                        <div><img className='header_tom' src={tomimg} alt="Tom Share" /> Share</div>
                                                    ) : (
                                                        row.label
                                                    )}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    {pageIndex === 0 && HeadingButton?.length > 0 && (
                                        <thead className="print_header_total">
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='print_head_Text_color_Total'>Total</td>
                                                <td className='print_head_Text_color'>{HeadingButton[0].revenueTotal}</td>
                                                <td className='print_head_Text_color'>{HeadingButton[0].revenueTomshare}</td>
                                                <td className='print_head_Text_color'>{HeadingButton[0].revenueFee}</td>
                                            </tr>
                                        </thead>
                                    )}
                                    {pageIndex === 0 && HeadingButton1?.length > 0 && (
                                        <thead className="print_header_total">
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className='print_head_Text_color_Total'>Total</td>
                                                <td className='print_head_Text_color'>{HeadingButton1[0].revenueTotal}</td>
                                            </tr>
                                        </thead>
                                    )}
                                    <tbody>
                                        {chunk.map((printdata, index) => (
                                            <tr key={index}>
                                                {headRows.map((row) => (
                                                    <td
                                                        key={row.id}
                                                        className="print_header_textss1"
                                                        style={
                                                            row.id === 'tomShare' && type === 'Revenue' ? { textAlign: 'right' } :
                                                                row.id === 'income' && type === 'Revenue' ? { textAlign: 'right' } :
                                                                    row.id === 'rate' ? { textAlign: 'right' } :
                                                                        row.id === 'cost' ? { textAlign: 'right' } :
                                                                            row.id === 'customer' ? { textAlign: 'left' } :
                                                                                row.id === 'nursename' ? { textAlign: 'left' } :
                                                                                    row.id === 'nurseName' ? { textAlign: 'left' } :
                                                                                        row.id === 'totalcost' ? { textAlign: 'right' } :
                                                                                            row.id === 'status' && row.id === 'from_date' && row.id === 'booked_date' && row.id === 'to_date' ? { textAlign: 'center' } :
                                                                                                row.id === 'settlement_amount' && type === 'settlement' ? { textAlign: 'right' } :
                                                                                                    row.id === 'totalcost' && type === 'Revenue' ? { textAlign: 'right' } :
                                                                                                        row.id === 'totalcost' ? { textAlign: 'right' } :
                                                                                                            row.id === 'name' && type === 'Revenue' ? { textAlign: 'left' } :
                                                                                                                type === 'Revenue' ? { textAlign: 'center' } :
                                                                                                                    type === 'settlement' ? { textAlign: 'center' } : { textAlign: 'center' }
                                                        }
                                                    >
                                                        {row.id === 'sno' ?
                                                            pageIndex * rowsPerPage + index + 1 :
                                                            row.type === 'date' ?
                                                                dateFormat(printdata[row.id], "dd-mmm-yy") :
                                                                row.type === 'month' ?
                                                                    moment(printdata[row.id]).format("MMM-YY") :
                                                                    row.type === 'amt' ? formatMoney(printdata[row.id])
                                                                        : printdata[row.id]}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {/* Page Count */}
                                <div className="page-count">
                                    Page {pageIndex + 1} of {totalChunks}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }

}