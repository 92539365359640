import React from 'react';
import "./printdata.css"
import Logo from "../../Images/Logo.png";
// import { timerDifference } from "../../App";
import { formatMoney } from "../../App";

class PrintData extends React.Component {

  renderTableChunk = (dataChunk, startIndex, totalChunks, rowsPerPage) => {
    const { printtabledata, HeadingButton, type } = this.props;
    const representativePrintData = HeadingButton && HeadingButton.length > 0 ? HeadingButton[0] : null;
    const totalPages = Math.ceil(totalChunks / rowsPerPage);
    const currentPage = Math.ceil(startIndex / rowsPerPage) + 1;

    return (
      <div className="printtabledata" key={startIndex}>
        <img className="tom_logo_print" src={Logo} alt="image error" />
        <div className="printDataTitle">Idle Nurse</div>
        <div className='kwd_vlaues_font'>All values in KWD</div>
        <table>
          <thead className="print_header">
            <tr>
              <th className="print_header_text" style={{ textAlign: "center" }}>S.No</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Nurse Name</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Gender</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Age</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Experience</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Nationality</th>
              <th className="print_header_text" style={{ textAlign: "center" }}>Idle Days</th>
            </tr>
          </thead>
          <tbody>
            {dataChunk.map((printdata, index) => (
              <tr key={startIndex + index}>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{index + 1}</td>
                <td className="print_header_textss">{printdata.nursename}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.gender}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.age}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}> {printdata.experience}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.Nationality}</td>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.IdleDays}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="print_footer">
          Page {currentPage} of {totalPages}
        </div>
        {dataChunk.length === rowsPerPage && startIndex + rowsPerPage < totalChunks && <div className="page-break"></div>}
      </div>
    );
  };

  render() {
    const rowsPerPage = 30; // Adjust this number based on your page layout and font size
    const { printTableData } = this.props;
    const totalChunks = Math.ceil(printTableData.length / rowsPerPage);

    const tableChunks = [];
    for (let i = 0; i < totalChunks; i++) {
      const startIndex = i * rowsPerPage;
      const dataChunk = printTableData.slice(startIndex, startIndex + rowsPerPage);
      tableChunks.push(this.renderTableChunk(dataChunk, startIndex, printTableData.length, rowsPerPage));
    }

    return <div>{tableChunks}</div>;
  }
}

export default PrintData;