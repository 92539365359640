import React, { memo } from "react";
import Divider from "@material-ui/core/Divider";
import "./NotificationPopup.css";

const NotificationPopup = memo((props) => {
  return (
    <div className="notification_popup" style={{ maxHeight: '200px' }}>
      {props.notificationList.length == 0 ? (
        <div className="no-notifi-text">No Notification</div>
      ) : (
        props.notificationList.map((item, i) => (
          <div key={item.notification_id} className="noti-row">
            <div>{item.description || ""}</div>
            <Divider />
          </div>
        ))
      )}
    </div>
  );
});

export default NotificationPopup;
