import React, { Component } from "react";
import "./TicketMaster.css";
import Paper from "@material-ui/core/Paper";
import TicketTable from "./TicketTable";
import { withTranslation } from 'react-i18next';

class TicketMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const { t } = this.props
    return (
      <div className={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} ticketmaster`}>
        <Paper>

          <TicketTable t={t} i18n={this.props.i18n}></TicketTable>
        </Paper>

      </div>
    );
  }
}
export default withTranslation()(TicketMaster);
