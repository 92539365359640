import React, { Component } from "react";
import BookingDetails from "./BookingDetails";
import { Paper } from "@material-ui/core";
import DealList from "./DealList";
import { withTranslation } from 'react-i18next';

class DealsMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_tab: 1,
      edit_booking_data: {},
    };
  }
  Changetab = (data) => {
    this.setState({ active_tab: data, edit_booking_data: {} });
  };

  edit_details = (data) => {
    this.setState({ active_tab: 1, edit_booking_data: data });
  };
  
  componentWillMount() {
    if(localStorage.getItem("contentTypeData")=="DealNotify"){
      this.state.active_tab=2;
      localStorage.removeItem('contentTypeData');
    }else{
      this.state.active_tab=1;
    }
  }
  render() {
    const {t} = this.props
    return (
      <div>
        <Paper>
          <div className="dashboard_header head_font">
            <div style={{ width: "50%",order: (this.props.i18n.language=='ar'?1:0), textAlign: (this.props.i18n.language=='ar'?'end':'left')  }}>
              {/* <div className="dashboard_title">{t('deals')}</div> */}
              <div className="dashboard_title">{t('deals_head')}</div>
            </div>
            <div
              style={{ width: "50%", justifyContent: (this.props.i18n.language == 'ar' ? 'left' : "end"),direction: (this.props.i18n.language == 'ar' ? 'rtl' : ""), display: "flex" }}
            >
              <div
                className={
                  "advertisementtab " +
                  (this.state.active_tab == 1 ? "advertisementtab_active" : "")
                }
                onClick={(e) => {
                  this.Changetab(1);
                }}
              >
                {" "}
                {t('create_deal')}{" "}
              </div>
              <div
                className={
                  "advertisementtab " +
                  (this.state.active_tab == 2 ? "advertisementtab_active" : "")
                }
                onClick={(e) => {
                  this.Changetab(2);
                }}
              >
                {" "}
                {t('deal_list')}{" "}
              </div>
            </div>
          </div>
          {this.state.active_tab == 1 && (
            <BookingDetails edit_booking_data={this.state.edit_booking_data} t={t} i18n={this.props.i18n}/>
          )}
          {this.state.active_tab == 2 && (
            <DealList edit_details={this.edit_details.bind()}  t={t} i18n={this.props.i18n}/>
          )}
        </Paper>
      </div>
    );
  }
}
export default withTranslation()(DealsMaster);
