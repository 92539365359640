import React, { Component } from "react";
import "./RevenueChart.css";
import "./RevenueMaster.css";
import "./RevenueTable.css";
import Paper from "@material-ui/core/Paper";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import Monthpicker from "./mothpicker/Monthpicker";
import moment from 'moment';
import axios from "axios";
// import logos from '../../Images/logos.png';
import tomwhite from "../../Images/tomImagewhite.svg"
import logo from "../../Images/TOM_logo.svg"
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
// chart 
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// temp
import { Input, notification } from "antd";
import Pdf from "../../Images/pdf.svg";
import Print from "../../Images/print.svg";
import Excel from "../../Images/excel.svg";

import more from "../../Images/more.svg"
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import ReactToPrint from "react-to-print";
import ReactExport from "react-data-export";
import PrintData from "./PrintData";
import ReactSVG from "react-svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { apiurl, formatMoney } from "../../App";
import dateFormat from "dateformat";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import ProfileView from "../TotalOrders/ProfileView";
import Filter from '../../Images/filter.svg';
import AdvanceFilter from "../AdvanceFillter/AdvanceFilter";
// import PrescriptionDetailView from "../PrescriptionDetailView/PrescriptionDetailView";
// import PrescriptionDetailView from "../PrescriptionEntry/";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class RevenueChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Root: null,
      ToggleValue: 'revenue',
      Toggle_className: '',
      ToggleRevenue: 'switch',
      TogglePharma: '',
      ToggleTomshare: '',
      ToggleTotalRec: 'switch',
      ToggleRecSOF: '',
      ToggleBalanRec: '',
      CardData: {},
      ChartData: [],
      CardIsActive: true,
      currentDate: new Date(),
      // temp
      props_loading: true,
      tableData: [],
      revenueTotal: 0,
      search: null,
      fromDate: "",
      toDate: "",
      openview: false,
      prescriptionId: null,
      open_filter: false,
      prescriptionopenview: false,
      selected_doctor_id: 0,
      prescriptionEntryData: null,
      chartColor: '#510f30',

      initialdate: moment(new Date()).format('YYYY-MM-DD'),
      currentmonth: moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format('YYYY-MM-DD'),
      isMonthRangeEnabled: false,
      idControl: 0
    };
  }

  componentDidMount() {
    // console.log(this.state.currentDate.toLocaleDateString('en-CA'));
    // this.fetchData(this.state.ToggleValue);

    const obj_data = {
      "vendorId": localStorage.getItem('nurse_vendor_id')
    }
    axios({
      method: "POST",
      url: apiurl + "BookRoom/getVendorDate",
      data: obj_data,
    })
      .then((response) => {
        if (response.data.status == 1) {
          this.setState({
            initialdate: moment(response.data.data[0].vendorDate).format('YYYY-MM-DD'),
          });
        } else {

        }
      }
      )
      .catch((error) => {
        console.log(error);
      });


    this.fetchData(this.state.ToggleValue);

  }

  fetchData = async (type) => {
    try {
      this.setState({ ChartData: [], CardData: {} })
      const [revenueDataResponse, revenueDataChartResponse] = await Promise.all([
        axios.post(apiurl + 'nurse/getNurseRevenueDashboard', {
          p_nurseId: localStorage.getItem('nurse_vendor_id'),
          p_fromDate: this.state.fromDate,
          p_toDate: this.state.toDate
        }),
        axios.post(apiurl + 'nurse/getNurseRevenueChartDtl', {
          p_nurseId: localStorage.getItem('nurse_vendor_id'),
          p_fromDate: this.state.fromDate,
          p_toDate: this.state.toDate
        })
      ]);

      // Process the responses
      const tableData = revenueDataResponse.data.data.map((val) => ({ val }));
      const chartData = revenueDataChartResponse?.data?.data;
      //  debugger
      this.setState({
        CardData: tableData[0].val,
        ChartData: chartData,
        props_loading: false,
        enableSearch: false
      });
      if (this.state.Root) {
        this.state.Root.dispose();
        this.setState({ Root: null })
      }
      debugger
      if (type === "revenue") {
        let modifiedData = this.state.ChartData.map((item) => {
          let { revenueAmount, ...rest } = item;
          const amount = revenueAmount !== null ? Number(revenueAmount) : 0;
          return { amount, ...rest }
        });
        this.ChartRender(modifiedData);
      } else if (type === "pharmacy") {
        let modifiedData = this.state.ChartData.map((item) => {
          let { nurseShare, ...rest } = item;
          const amount = nurseShare !== null ? Number(nurseShare) : 0;
          return { amount, ...rest }
        });
        this.ChartRender(modifiedData);
      } else if (type === "tomshare") {
        let modifiedData = this.state.ChartData.map((item) => {
          let { tomShare, ...rest } = item;
          const amount = tomShare !== null ? Number(tomShare) : 0;
          return { amount, ...rest }
        });
        this.ChartRender(modifiedData);
      } else if (type === 'totalrec') {
        let modifiedData = this.state.ChartData.map((item) => {
          let { nurseShare, ...rest } = item;
          const amount = nurseShare !== null ? Number(nurseShare) : 0;
          return { amount, ...rest }
        });
        this.ChartRender(modifiedData);
      } else if (type === 'recsof') {
        let modifiedData = this.state.ChartData.map((item) => {
          let { paidSoFar, ...rest } = item;
          const amount = paidSoFar !== null ? Number(paidSoFar) : 0;
          return { amount, ...rest }
        });
        this.ChartRender(modifiedData);
      } else if (type === 'balanrec') {
        let modifiedData = this.state.ChartData.map((item) => {
          let { balanceToPaid, ...rest } = item;
          const amount = balanceToPaid !== null ? Number(balanceToPaid) : 0;
          return { amount, ...rest }
        });
        this.ChartRender(modifiedData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  ChartRender = (data) => {
    let { Root } = this.state;
    if (data) {
      if (!Root) {
        Root = am5.Root.new("1chartdiv");
        Root._logo.dispose();
        Root.setThemes([
          am5themes_Animated.new(Root)
        ]);
        this.setState({ Root });

        // Create chart
        var chart = Root.container.children.push(am5xy.XYChart.new(Root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX",
          layout: Root.verticalLayout
        }));

        // setTbData(data);
        var cursor = chart.set("cursor", am5xy.XYCursor.new(Root, {}));
        cursor.lineY.set("visible", false);

        // Create X-Axis
        var xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(Root, {
            renderer: am5xy.AxisRendererX.new(Root, {
              cellStartLocation: 0.2,
              cellEndLocation: 0.8,
              minGridDistance: 60,
            }),
            tooltip: am5.Tooltip.new(Root, {
              themeTags: ["axis"],
              animationDuration: 100
            }),
            categoryField: "labelValue"
          })
        );
        xAxis.get("renderer").labels.template.setAll({
          oversizedBehavior: "truncate",
          textAlign: "center",
          maxWidth: 90
        });
        var xRenderer = xAxis.get("renderer");
        xRenderer.grid.template.set("visible", false);

        xAxis.data.setAll(data);

        let yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(Root, {
            extraMax: 0.1,
            renderer: am5xy.AxisRendererY.new(Root, {
              strokeOpacity: 0.1
            })
          })
        );
        var paretoAxisRenderer = am5xy.AxisRendererY.new(Root, { opposite: true });
        paretoAxisRenderer.grid.template.set("forceHidden", true);
        var yAxis1 = chart.yAxes.push(am5xy.ValueAxis.new(Root, {
          renderer: paretoAxisRenderer,
        }));

        var xRenderer = yAxis.get("renderer");
        xRenderer.grid.template.set("visible", false);

        // let start_value = Math.max(...data.map((item) => Math.max(item.amount)));
        // yAxis.set("min", 0);
        // yAxis.set("max", start_value+(start_value*0.20));

        // var mycolor = ["#510f30","#00a49a", "#f49c7a", "#00a49a","#00e8d2","#f12c74","#f49c7a","#00a49a","#f8e0d8"]

        var series1 = chart.series.push(
          am5xy.ColumnSeries.new(Root, {
            name: "",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "amount",
            categoryXField: "labelValue",
          })
        );
        series1.bullets.push(function () {
          return am5.Bullet.new(Root, {
            locationX: 0.5,
            locationY: 1,
            sprite: am5.Label.new(Root, {
              centerY: am5.p100,
              centerX: am5.p50,
              text: "{valueY}",
              fontSize: 11,
              populateText: true,
              // oversizedBehavior: "hide",
            }),
          });
        });
        series1.data.setAll(data);

        series1.columns.template.setAll({
          //  tooltipText: "Transaction:{transactionCount}",
          tooltipY: am5.percent(10),
          width: 50,
          // cornerRadiusTL: 5,
          // cornerRadiusTR: 5,
          strokeOpacity: 0,
          fill: am5.color(this.state.chartColor),
        });

        // use when need multiple color
        // series1.columns.template.adapters.add("fill", function(fill, target) {
        //   var columnsindex = series1.columns.indexOf(target);
        //   return mycolor[columnsindex];this.state.ToggleValue
        // });  

        // series1.columns.template.events.on("click", (ev) => {
        //   let clicked_value = ev.target._dataItem.dataContext.labelValue.toLowerCase();
        //   debugger
        // setTitle(String(props.vendor_type));
        // data.map(res => {
        //   console.log(res.labelValue.toLowerCase(), clicked_value.toLowerCase(), "week route");
        //   if (res.labelValue.toLowerCase() === clicked_value.toLowerCase()) {
        //     const { history } = this.props;
        //     debugger
        //     if (this.setSettlementRoute(this.state.ToggleValue) == 0) {
        //       history.push(`/home/revenueChart/revenue/${res.fromDate}/${res.toDate}/0`);
        //     } else {
        //       history.push(`/home/revenueChart/settlement/${res.fromDate}/${res.toDate}/${this.setSettlementRoute(this.state.ToggleValue)}`);
        //     }
        // if(props.issettlement==='settlement'){
        //  navigate(`/settlement/settled/${props.VendorType.toLowerCase()}/${res.fromDate}/${res.toDate}`); 
        // }else if(props.issettlement==='transaction'){
        //   navigate(`/transaction/${props.VendorType.toLowerCase()}/${res.fromDate}/${res.toDate}`); 
        // }
        // else{
        //   navigate(`/income/${props.VendorType.toLowerCase()}/${res.fromDate}/${res.toDate}`); 
        // }
        //     }
        //   })
        // });

      } else {
        this.state.Root.dispose();
      }
    }
  }

  reloadChart = () => {
    // debugger
    const { Root } = this.state;
    if (Root) {
      Root.dispose();
      this.setState({ Root: null });
    }
    this.fetchData(this.state.ToggleValue);

  }

  changeChartValue = () => {
    // if(this.state.Root){
    //     this.state.Root.dispose();
    //     this.setState({Root : null})
    //   }

    let modifiedData = [];
    if (this.state.ToggleValue === 'revenue') {
      let modifiedData = this.state.ChartData.map((item) => {
        let { revenueAmount, ...rest } = item;
        const amount = revenueAmount !== null ? Number(revenueAmount) : 0;
        return { amount, ...rest }
      });
      this.ChartRender(modifiedData);
    } else if (this.state.ToggleValue === 'pharmacy') {
      let modifiedData = this.state.ChartData.map((item) => {
        let { nurseShare, ...rest } = item;
        const amount = nurseShare !== null ? Number(nurseShare) : 0;
        return { amount, ...rest }
      });
      this.ChartRender(modifiedData);
    } else if (this.state.ToggleValue === 'tomshare') {
      let modifiedData = this.state.ChartData.map((item) => {
        let { tomShare, ...rest } = item;
        const amount = tomShare !== null ? Number(tomShare) : 0;
        return { amount, ...rest }
      });
      this.ChartRender(modifiedData);
    } else if (this.state.ToggleValue === 'totalrec') {
      let modifiedData = this.state.ChartData.map((item) => {
        let { nurseShare, paidSoFar, ...rest } = item;
        const amount = nurseShare !== null ? Number(nurseShare) : 0;
        return { amount, ...rest }
      });
      this.ChartRender(modifiedData);
    } else if (this.state.ToggleValue === 'recsof') {
      let modifiedData = this.state.ChartData.map((item) => {
        let { paidSoFar, ...rest } = item;
        const amount = paidSoFar !== null ? Number(paidSoFar) : 0;
        return { amount, ...rest }
      });
      this.ChartRender(modifiedData);
    } else if (this.state.ToggleValue === 'balanrec') {
      let modifiedData = this.state.ChartData.map((item) => {
        let { nurseShare, paidSoFar, ...rest } = item;
        const amount = nurseShare !== null ? Number(nurseShare - paidSoFar) : 0;
        return { amount, ...rest }
      });
      this.ChartRender(modifiedData);
    }

    //  if(this.state.Root){
    //   this.state.Root.dispose();
    //   this.setState({Root : null})
    // }

  }

  setSettlementRoute(data) {
    return (data === "totalrec" || data === "recsof" || data === "balanrec") ? data : 0;
  }

  handleRadioChange = (event) => {
    const toggle = event.target.value;
    // debugger
    this.setState({ ToggleValue: toggle }, () => this.fetchData(toggle));
    if (toggle == "revenue") {
      this.setState({ ToggleRevenue: 'switch', TogglePharma: '', ToggleTomshare: '' });
    } else if (toggle === "pharmacy") {
      this.setState({ ToggleRevenue: '', TogglePharma: 'switch', ToggleTomshare: '' });
    } else if (toggle === "tomshare") {
      this.setState({ ToggleRevenue: '', TogglePharma: '', ToggleTomshare: 'switch' });
    }

    else if (toggle == "totalrec") {
      this.setState({ ToggleTotalRec: 'switch', ToggleRecSOF: '', ToggleBalanRec: '' });
    } else if (toggle === "recsof") {
      this.setState({ ToggleTotalRec: '', ToggleRecSOF: 'switch', ToggleBalanRec: '' });
    } else if (toggle === "balanrec") {
      this.setState({ ToggleTotalRec: '', ToggleRecSOF: '', ToggleBalanRec: 'switch' });
    }
    // this.changeChartValue();
    // 
  };

  getRangeDate = (item) => {
    this.setState(
      {
        fromDate: dateFormat(item[0].startDate, "yyyy-mm-dd"),
        toDate: dateFormat(item[0].endDate, "yyyy-mm-dd"),
        initialdate: dateFormat(item[0].startDate, "yyyy-mm-dd"),
        currentmonth: dateFormat(item[0].endDate, "yyyy-mm-dd"),
        isMonthRangeEnabled: true,
        idControl: 1
      },
      () => this.reloadChart()
    );

  };

  changeCard = (data) => {
    const { history } = this.props;
    history.push(`/home/revenueChart/revenue/${this.state.initialdate}/${this.state.currentmonth}/${this.state.idControl}`);

    // if (data === 'R') {
    //   if (["totalrec", "recsof", "balanrec"].includes(this.state.ToggleValue)) {
    //     this.setState({ ToggleValue: "revenue", CardIsActive: true, chartColor: '#510f30' }, () => this.fetchData(this.state.ToggleValue));
    //   } else {
    //     this.setState({ CardIsActive: false, }, () => this.fetchData(this.state.ToggleValue));
    //   }
    // } else if (data === 'S') {
    //   if (["revenue", "pharmacy", "tomshare"].includes(this.state.ToggleValue)) {
    //     this.setState({ ToggleValue: "totalrec", CardIsActive: false, chartColor: '#ff0068' }, () => this.fetchData(this.state.ToggleValue));
    //   } else {
    //     this.setState({ CardIsActive: false }, () => this.fetchData(this.state.ToggleValue));
    //   }
    // }
  };

  render() {
    const { Search } = Input;
    const { t } = this.props;
    const multiDataSet = [];
    return (
      <div>
        <Paper>
          <div className="uploadsmasterheader revenue_head_div">
            <div className="title_header">
              {/* {t('revenue_and_settlement_head')} */}
              {`${t("revenue_and_settlement_head")} - ${this.state.idControl !== 1 ? moment(this.state.initialdate).format('MMM YY').toUpperCase() + '-' + moment(this.state.currentmonth).format('MMM YY').toUpperCase() : moment(this.state.initialdate).format('MMM YY').toUpperCase()}`}

            </div>{/* //t("revenue_head") */}
            <div
              style={{
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
              }}
              className="range_picker_reduce"
            >
              <div className={`${this.props.i18n.language == 'ar' ? "order_3" : ""} `}>
                {/* <DateRangeSelect
                  Datetype="revenue"
                  revenueRange
                  dynalign={"dynaposition"}
                  rangeDate={(item) => this.getRangeDate(item)}
                  Rtype={true}
                  t={t}
                  i18n={this.props.i18n}
                /> */}
                <Monthpicker
                  rangeDate={(item) => this.getRangeDate(item)}
                />
              </div>


            </div>
          </div>
          <div className="revenue-master-table323">
            <>
              <div className="card_container" >
                <div className={`revenue_card`} onClick={() => this.changeCard('R')} style={{direction:this.props.i18n.language=='ar'?"rtl": ""}}>
                  {/* <CardContent> */}
                  {/* <h5 className="text-center card_title" style={{ marginBottom: '20px' }}>Revenue</h5> */}
                  <table><tbody className="setm_chart ">
                    <tr className="add_border nurse_nrew" style={ this.props.i18n.language === "ar" ? {textAlign:"right"}:{}}>
                      <td>{t("revenue")}</td>
                      <td className="text-end">{this.state.CardData.totalRevenue ? formatMoney(this.state.CardData.totalRevenue) : 0}<span className="font_9"> {t("kwd")}</span></td>
                    </tr>
                    <tr className="add_border nurse_nrew" style={ this.props.i18n.language === "ar" ? {textAlign:"right"}:{}}>
                      <td>{t("Income")}</td>
                      <td className="text-end">{this.state.CardData.nurseShare ? formatMoney(this.state.CardData.nurseShare) : 0}<span className="font_9"> {t("kwd")}</span></td>
                    </tr>
                    <tr className="add_border nurse_nrew" style={ this.props.i18n.language === "ar" ? {textAlign:"right"}:{}}>
                      <td>
                        <img src={tomwhite} style={{ width: '32px' }} />
                        {t("Share")}</td>
                      <td className="text-end">{this.state.CardData.tomShare ? formatMoney(this.state.CardData.tomShare) : 0}<span className="font_9"> {t("kwd")}</span></td>
                    </tr>
                    {/* <tr className="add_border">
                            <td>Medicine</td>
                            <td className="text-end">{this.state.CardData.unitCount ? this.state.CardData.unitCount : 0}<span className="font_9"> UNIT</span></td>
                        </tr> */}
                    <tr style={ this.props.i18n.language === "ar" ? {textAlign:"right"}:{}}>
                      <td>{t("Transaction")}</td>
                      <td className="text-end">{this.state.CardData.transactionCount ? this.state.CardData.transactionCount : 0}<span className="font_9"> TRX</span></td>
                    </tr>
                  </tbody>
                  </table>
                  {/* </CardContent> */}
                </div>
                {/* <div className={`settle_card ${this.state.CardIsActive ? '' : 'settle_card_active'}`} onClick={() => this.changeCard('S')}>
           
                  <h5 className="text-center card_title">Settlement by  <img src={tomwhite} style={{ width: '32px' }} />
              
                  </h5>
                  <table><tbody className="setm_chart">
                    <tr className="add_border">
                      <td>Revenue</td>
                      <td className="text-end">{this.state.CardData.totalRevenue ? this.state.CardData.totalRevenue : 0}<span className="font_9"> KWD</span></td>
                    </tr>
                    <tr className="add_border">
                      <td>
                        <img src={tomwhite} style={{ width: '32px' }} />
                        Share</td>
                      <td className="text-end">{this.state.CardData.tomShare ? this.state.CardData.tomShare : 0}<span className="font_9"> KWD</span></td>
                    </tr>
                    <tr className="add_border">
                      <td>Total Receivable</td>
                      <td className="text-end">{this.state.CardData.nurseShare ? Number(this.state.CardData.nurseShare) : 0}<span className="font_9"> KWD</span></td>
                    </tr>
                    <tr className="add_border">
                      <td>Received so far</td>
                      <td className="text-end">{this.state.CardData.paidSoFar ? this.state.CardData.paidSoFar : 0}<span className="font_9"> KWD</span></td>
                    </tr>
                    <tr>
                      <td>Balance Receivable</td>
                      <td className="text-end">{(Number(this.state.CardData.nurseShare ? this.state.CardData.nurseShare : 0) - Number(this.state.CardData.paidSoFar ? this.state.CardData.paidSoFar : 0)).toFixed(3)}<span className="font_9"> KWD</span></td>
                    </tr>
                  </tbody>
                  </table>
          
                </div> */}
              </div></>
          </div>
          {
            this.state.CardIsActive ?
              <div className="toggler">
                <div className="switches-container">
                  <input type="radio" id="revenue1" name="switchPlan" value="revenue" checked="checked" onChange={this.handleRadioChange} />
                  <label htmlFor="revenue1" className={this.state.ToggleRevenue}>{t("revenue")}</label>
                  <input type="radio" id="pharma" name="switchPlan" value="pharmacy" onChange={this.handleRadioChange} />
                  <label htmlFor="pharma" className={this.state.TogglePharma}>{t("Income")}</label>
                  <input type="radio" id="tomshare" name="switchPlan" value="tomshare" onChange={this.handleRadioChange} />
                  <label htmlFor="tomshare" className={this.state.ToggleTomshare}><span ><img style={{ height: '15px' }} src={this.state.ToggleTomshare != 'switch' ? logo : tomwhite}></img> {t("Share")}</span></label></div>
              </div> :
              <div className="toggler">
                <div className="switches-container">
                  <input type="radio" id="totalrec" name="switchPlan" value="totalrec" checked="checked" onChange={this.handleRadioChange} />
                  <label htmlFor="totalrec" className={this.state.ToggleTotalRec}>Total Receivable</label>
                  <input type="radio" id="recsof" name="switchPlan" value="recsof" onChange={this.handleRadioChange} />
                  <label htmlFor="recsof" className={this.state.ToggleRecSOF}>Received so far</label>
                  <input type="radio" id="balrec" name="switchPlan" value="balanrec" onChange={this.handleRadioChange} />
                  <label htmlFor="balrec" className={this.state.ToggleBalanRec}>Balance Receivable</label></div>
              </div>
          }

          <p className="curr_value" style={{ color: '#ff0068' }}>{t("allAmountInKWD")}</p>
          <div className="chart-container">
            <div id="1chartdiv" style={{ width: "100%", height: "40vh", margin: 'auto' }}></div>
          </div>
        </Paper>

      </div>
    )
  }
}
export default withTranslation()(RevenueChart);