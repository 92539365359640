/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "antd/dist/antd.css";
import "./BookingDetails.css";
import "./AdvertisementMaster.css";
import "./AdvertiseList.css";
import EditIcon from "@material-ui/icons/Edit";
import Axios from "axios";
import { apiurl } from "../../App";
import Stepper from "./Stepper";
import ReactPagination from "../Pagination/Pagination";
import Grid from "@material-ui/core/Grid";
import { Tabs, Spin } from "antd";
import Button from "@material-ui/core/Button";
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from "prop-types";
import { formatMoney } from "../../App";

const { TabPane } = Tabs;

const data = [{ month: "Jan.", count: 69, city: "tokyo" }];
const scale = {
  month: { alias: "Month" },
  count: { alias: "Sales" },
};

class AdvertiseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      del_id: "",
      ad_details: [],
      total_count: "",
      limit: 10,
      pageno: 1,
      dataOnload: true,
      activeKey: "1",
      edit_id: 0,
      is_show_repeat: false,
      edit_ad_location: "",
      edit_days: "",
      edit_total_cost: "",
      edit_fees_day: "",
      edit_start_date: "",
      edit_end_date: "",
      thumbnail_imageurl: "",
      adsize: "1",
      editbookingDetails: {},
      is_show_edit: true,
      active_pageno: 0,
      excluded_dates: [],
      isshowexclude: false,
      imamgepath_type: "",
      editbookingDetailstepper: {},
      editstatus: ""
    };
  }

  getAdBooking = () => {
    this.setState({ dataOnload: true, total_count: 0 });
    Axios({
      method: "POST",
      url: apiurl + "Diet/getAd_Booking",
      data: {
        vendor_id: localStorage.getItem("nurse_vendor_id")
          ? localStorage.getItem("nurse_vendor_id")
          : "11",
        limit: this.state.limit,
        pageno: this.state.pageno,
      },
    })
      .then((response) => {
        response.data.data[0].details.forEach((row, i) => {
          row["activerow"] = 0;
          let excluded_dates = [];
          if (row.excluded_dates != "") {
            row.excluded_dates.split(",").map((obj) => {
              excluded_dates.push({ "display_date": moment(obj, "YYYY-MM-DD").format("DD-MMM-YY"), "date": obj })
            })
          }
          row.excluded_dates = excluded_dates
        });
        if (response.data.data[0].details.length > 0) {
          response.data.data[0].details[0]["activerow"] = 1;
          this.setState({
            editbookingDetails: response.data.data[0].details[0],
            editbookingDetailstepper: response.data.data[0].details[0]
          });

          this.setEditDetail(response.data.data[0].details[0]);
        }
        this.setState(
          {
            ad_details: response.data.data[0].details,
            total_count: response.data.data[0].total_count,
            dataOnload: false,
          },
          () => console.log("sfdshfjsdhfjsdhfsdf", this.state.ad_details)
        );
      })
      .catch((error) => {
      });
  };

  componentWillMount() {
    this.getAdBooking();
  }

  getAdDetails = (data) => {
    this.setState({ pageno: data === 1 ? 1 : data, dataOnload: true, active_pageno: data });
    Axios({
      method: "POST",
      url: apiurl + "Diet/getAd_Booking",
      data: {
        vendor_id: localStorage.getItem("nurse_vendor_id")
          ? localStorage.getItem("nurse_vendor_id")
          : "11",
        limit: this.state.limit,
        pageno: data + 1,
      },
    })
      .then((response) => {
        response.data.data[0].details.forEach((row, i) => {
          row["activerow"] = 0;
          let excluded_dates = [];
          if (row.excluded_dates != "") {
            row.excluded_dates.split(",").map((obj) => {
              excluded_dates.push({ "display_date": moment(obj, "YYYY-MM-DD").format("DD-MMM-YY"), "date": obj })
            })
          }
          row.excluded_dates = excluded_dates
        });
        if (response.data.data[0].details.length > 0) {
          response.data.data[0].details[0]["activerow"] = 1;

          this.setState({
            editbookingDetails: response.data.data[0].details[0],
          });
          this.setEditDetail(response.data.data[0].details[0]);
        }

        this.setState({
          ad_details: response.data.data[0].details,
          dataOnload: false,
          total_count: response.data.data[0].total_count,
        });
      })
      .catch((error) => {
      });
  };

  setEditDetail = (data) => {
    let todate = moment();
    let enddate = moment(data.ad_end_date_display, "DD-MM-YYYY");
    let editbookingDetails = data;
    let is_show_edit = (data.ad_approve_status == "P" || data.ad_approve_status == "R") ? true : false;
    let is_show_repeat = todate.diff(enddate, "days") > 0 ? true : false;
    this.setState({
      edit_id: data.del_id,
      edit_ad_location: data.ad_location,
      edit_days: data.ad_total_days,
      edit_total_cost: data.ad_total_cost,
      edit_fees_day: data.ad_fee_per_day,
      edit_start_date: data.ad_start_date_display,
      edit_end_date: data.ad_end_date_display,
      thumbnail_imageurl: data.ad_filename,
      ad_size: data.ad_size,
      is_show_repeat: is_show_repeat,
      is_show_edit: is_show_edit,
      excluded_dates: data.excluded_dates,
      imamgepath_type: data.ad_type,
      editbookingDetails
    });
  };
  SelectedRow(data) {
    this.state.ad_details.forEach((row, i) => {
      if (row["id"] == data["id"]) {
        row["activerow"] = 1;
      } else {
        row["activerow"] = 0;
      }
    });
    this.setState({ editstatus: data.ad_approve_status });

    this.setState({ editbookingDetails: data });
    this.setEditDetail(data);
  }

  setstepperdetails(data) {
    this.setState({ editbookingDetails: data });
    this.setEditDetail(data);
    this.SelectedRow(data);
  }

  getAdvertisementBody() {
    if (this.state.ad_details && this.state.ad_details.length > 0) {
      return this.state.ad_details.map((bookingDetails, index) =>
        this.props.i18n.language == "ar" ? (
          <tr
            className={bookingDetails.activerow == 1 ? "active_row" : ""}
            style={{ border: "2px dashed #D1D1D1", cursor: "pointer" }}
            key={bookingDetails.id}
            onClick={(e) => {
              this.setState({
                editbookingDetailstepper: bookingDetails
              })
              this.SelectedRow(bookingDetails);

            }}
          >
            <td
              style={{
                paddingLeft: "20px",
                fontSize: "14px",
                color: "#707070",
                textAlign: "right",
              }}
            >
              {" "}
              {bookingDetails.id}{" "}
            </td>
            <td
              style={{
                padding: "15px",
                fontSize: "14px",
                color: "#707070",
                textAlign: "right",
              }}
            >
              {" "}
              {bookingDetails.ad_name}{" "}
            </td>
          </tr>
        ) : (
          <tr
            className={bookingDetails.activerow == 1 ? "active_row" : ""}
            style={{ border: "2px dashed #D1D1D1", cursor: "pointer" }}
            key={bookingDetails.id}
            onClick={(e) => {
              this.setState({
                editbookingDetailstepper: bookingDetails
              })
              this.SelectedRow(bookingDetails);
            }}
          >
            <td style={{ padding: "15px", fontSize: "14px", color: "#707070" }}>
              {" "}
              {bookingDetails.ad_name}{" "}
            </td>
            <td
              style={{
                paddingLeft: "20px",
                fontSize: "14px",
                color: "#707070",
              }}
            >
              {" "}
              {bookingDetails.id}{" "}
            </td>
          </tr>
        )
      );
    } else {
      return (
        <div>
          <tr>
            {" "}
            <td style={{ padding: "5px", fontSize: "14px", color: "#707070" }}>
              {" "}
              {this.props.t("no_data_found")}{" "}
            </td>{" "}
          </tr>
        </div>
      );
    }
  }

  repeat_ad() {
    this.state.editbookingDetails.isrepeat = true;
    this.props.edit_details(this.state.editbookingDetails);
    console.log("editdetail", this.state.editbookingDetails);
  }

  render() {
    const { t } = this.props;
    return (
      <Spin className="spinner_align" spinning={this.state.dataOnload}>
        <div className="booking_createlist booking_createlist--advertisement booking_ad_list">
          <Grid container className="calendar_container" spacing={3}>
            <Grid item xs={12} md={9} className={`${this.props.i18n.language == 'ar' ? "order_1" : ""}`}>
              <div
                style={{
                  display: this.state.ad_details == 0 ? "none" : "block",
                }}
              >
                <Grid container className="calendar_container" spacing={3}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ padding: this.props.i18n.language == 'ar' ? "5rem 25px 0px 5px" : "5rem 5px 0px 25px" }}
                    className={`${this.props.i18n.language == 'ar' ? "order_2" : ""}`}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "5px",
                      }}
                    >
                      <div style={{ width: "50%", order: (this.props.i18n.language == 'ar' ? 1 : 0) }}>
                        <label
                          className="list_head"
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            color: "black",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {t('ad_location')}
                        </label>
                        <label
                          className="list_subhead"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            color: "#707070",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {this.state.edit_ad_location}
                        </label>
                      </div>
                      <div style={{ width: "50%", order: (this.props.i18n.language == 'ar' ? 0 : 1) }}>
                        <label
                          className="list_head"
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            color: "black",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {t('days')}
                        </label>
                        <label
                          className="list_subhead"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            color: "#707070",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {this.state.edit_days}
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "25px",
                      }}
                    >
                      <div style={{ width: "50%", order: (this.props.i18n.language == 'ar' ? 1 : 0) }}>
                        <label
                          className="list_head"
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            color: "black",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {t('tbl_head_from')}
                        </label>
                        <label
                          className="list_subhead"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            color: "#707070",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {this.state.edit_start_date}
                        </label>
                      </div>
                      <div style={{ width: "50%", order: (this.props.i18n.language == 'ar' ? 0 : 1) }}>
                        <label
                          className="list_head"
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            color: "black",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {t('tbl_head_to')}
                        </label>
                        <label
                          className="list_subhead"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            color: "#707070",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {this.state.edit_end_date}
                        </label>
                      </div>
                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "25px",
                      }}
                    >
                      <div style={{ width: "50%", order: (this.props.i18n.language == 'ar' ? 1 : 0) }}>
                        <label
                          className="list_head"
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            color: "black",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {t('fee_per_day')} <span style={{ fontSize: '10px' }}>{t('kwD')}</span>
                        </label>
                        <label
                          className="list_subhead"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            color: "#707070",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {formatMoney(this.state.edit_fees_day)}
                        </label>
                      </div>
                      <div style={{ width: "50%", order: (this.props.i18n.language == 'ar' ? 0 : 1) }}>
                        <label
                          className="list_head"
                          style={{
                            fontSize: "16px",
                            width: "100%",
                            color: "black",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {t('total_fee_with_kwd')}<span style={{ fontSize: '10px' }}>
                            {t('kwD')}
                          </span>
                        </label>
                        <label
                          className="list_subhead"
                          style={{
                            fontSize: "14px",
                            width: "100%",
                            color: "#707070",
                            textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                          }}
                        >
                          {formatMoney(this.state.edit_total_cost)}
                        </label>
                      </div>
                    </div>
                    <div style={{ width: "100%", marginTop: "25px" }}>
                      <label
                        className="list_head"
                        style={{
                          fontSize: "16px",
                          width: "100%",
                          color: "black",
                          textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                        }}
                      >
                        {t('excluded_days')}
                      </label>
                      {this.state.excluded_dates.length == 0 &&
                        <div style={{ display: 'flex', paddingTop: '9px', justifyContent: (this.props.i18n.language == 'ar' ? 'end' : 'flex-start') }}>
                          <label
                            className={`${localStorage.nurse_language == 'ar' ? "ar_nil" : ""} list_subhead`}
                            style={{
                              fontSize: "14px",
                              color: "#707070",
                              width: '100%',
                              textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left')
                            }}
                          >Nil</label>
                        </div>
                      }
                      {this.state.excluded_dates.length != 0 &&
                        <div style={{
                          display: 'flex',
                          justifyContent: (this.props.i18n.language == 'ar' ? 'right' : 'flex-start'),
                          direction: (this.props.i18n.language == 'ar' ? 'rtl' : '')
                        }}>
                          <label
                            className="list_subhead"
                            style={{
                              fontSize: "14px",
                              color: "#707070",
                              textAlign: (this.props.i18n.language == 'ar' ? 'right' : 'left'),
                              direction: (this.props.i18n.language == 'ar' ? 'ltr' : '')
                            }}
                          >
                            {this.state.excluded_dates[0]["display_date"]}
                          </label>
                          {
                            this.state.excluded_dates.length > 1 ? <span style={{ fontSize: '15px', paddingLeft: '0px' }}>, </span> : ''}
                          <span style={{
                            display: (this.state.excluded_dates.length > 1 ? 'block' : 'none'), fontSize: '12px', paddingLeft: '3px', paddingTop: '3px',

                          }} className="readmore_cls" onClick={() => { this.setState({ isshowexclude: true }) }}> ... {t('view_more')} </span> </div>
                      }
                      {this.state.isshowexclude &&
                        <div className="listexcludemorediv" style={{ right: '0px !important' }}>
                          <div style={{ textAlign: 'right' }}>
                            <CloseIcon className="excludemorediv_close" onClick={() => { this.setState({ isshowexclude: false }) }}></CloseIcon>
                          </div>
                          <div className="excludemoredatemaindiv">
                            <div style={{ display: 'table' }}>
                              {this.state.excluded_dates.map((obj) => (
                                <div className="excludemoredatediv">
                                  {obj["display_date"]}
                                </div>
                              ))
                              }
                            </div>
                          </div>
                        </div>}

                    </div>

                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: (this.props.i18n.language == 'ar' ? 'right' : 'start'),
                        marginTop: "4rem",
                      }}
                    >
                      {this.state.editstatus != "A" &&
                        <Button
                          className={`${localStorage.nurse_language == 'ar' ? "ar_edit_book" : ""} edit_book`}
                          style={{
                            display:
                              this.state.is_show_edit == true
                                ? "block"
                                : "none",
                            marginRight: "5px",
                          }}
                          onClick={(e) => {
                            this.props.edit_details(this.state.editbookingDetails);
                          }}
                        >
                          {" "}
                          <EditIcon className={`${localStorage.nurse_language == 'ar' ? "ar_list_edit" : ""} list_edit`} /> {t('edit')}{" "}
                        </Button>
                      }

                      <Button
                        className="repet_book"
                        style={{
                          display: this.state.is_show_repeat ? "block" : "none",
                        }}
                        onClick={(e) => {
                          this.repeat_ad();
                        }}
                      >
                        {" "}
                        {t('repeat')}{" "}
                      </Button>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{ padding: "40px 0px 0px 20px", direction: localStorage.nurse_language == 'ar' ? 'rtl' : '' }}
                    className={`${this.props.i18n.language == 'ar' ? "order_1" : ""}`}
                  >
                    <Stepper businessDays={this.state.editbookingDetailstepper} t={t}
                      i18n={this.props.i18n} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <div
                      style={{
                        width: "75%",
                        margin: "auto",
                        paddingTop: "5rem",
                        marginLeft: localStorage.nurse_language == 'ar' ? 'auto' : '0px',
                        marginRight: localStorage.nurse_language == 'ar' ? '0px' : 'auto',
                        paddingLeft: '0px'
                      }}
                    >
                      <div className="ad_list_mobile_icon">
                        {this.state.imamgepath_type.toLowerCase() == "video" &&
                          <video

                            className={
                              this.state.ad_size == "1"
                                ? "ad_list_inner_img_half"
                                : "ad_list_inner_img_full"
                            }
                            src={this.state.thumbnail_imageurl}
                            style={{
                              display:
                                this.state.thumbnail_imageurl == ""
                                  ? "none"
                                  : "block",
                            }}
                            controls
                          />
                        }
                        {this.state.imamgepath_type.toLowerCase() != "video" &&
                          <img

                            className={
                              this.state.ad_size == "1"
                                ? "ad_list_inner_img_half"
                                : "ad_list_inner_img_full"
                            }
                            src={this.state.thumbnail_imageurl}
                            style={{
                              display:
                                this.state.thumbnail_imageurl == ""
                                  ? "none"
                                  : "block",
                            }}
                          ></img>
                        }
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={3} className="alignad_box">

              <Grid item xs={12} md={12} className="create_container">
                <div className="">
                  <div className="" style={{ border: "0px" }}>
                    <table style={{ width: "100%" }}>

                      {this.props.i18n.language == 'ar' ? (
                        <thead>
                          <tr>

                            <th
                              style={{
                                textAlign: "right",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "black",

                              }}
                            >
                              {" "}
                              {t('ad_id')}{" "}
                            </th>
                            <th
                              style={{
                                textAlign: "right",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: "500",
                                order: (this.props.i18n.language == 'ar' ? 1 : 0)
                              }}
                            >
                              {" "}
                              {t('name')}{" "}
                            </th>
                          </tr>
                        </thead>
                      ) : (
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: "500",
                                order: (this.props.i18n.language == 'ar' ? 1 : 0)
                              }}
                            >
                              {" "}
                              {t('name')}{" "}
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "black",

                              }}
                            >
                              {" "}
                              {t('ad_id')}{" "}
                            </th>
                          </tr>
                        </thead>
                      )}

                      <tbody>{this.getAdvertisementBody()}</tbody>
                    </table>
                  </div>
                </div>

                {this.state.total_count !== "" &&
                  this.state.total_count > 10 && (
                    <div className="pagination__container">
                      <div className="pagination__box">
                        <ReactPagination
                          limit={this.state.limit}
                          total_count={this.state.total_count}
                          getAdDetails={this.getAdDetails}
                          initialPage={this.state.active_pageno}
                        />
                      </div>
                    </div>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Spin>
    );
  }
};

AdvertiseList.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  editData: PropTypes.object,
  editData: PropTypes.shape({
    media_filename: PropTypes.string,
    t: PropTypes.func.isRequired,
    id: PropTypes.string,
  }),
};
AdvertiseList.defaultProps = {
  editData: null,
};
export default AdvertiseList;
