import React from "react";
import "./PrintData.css";
import Logo from "../../Images/Logo.png";

export default class PrintData extends React.Component {
  render() {
    var printBodyData = this.props.printtableData.map((printdata, index) => {
      return (
        <tr>
          <td className="print_header_textss">{index + 1}</td>
          <td className="print_header_textss">{printdata.customer}</td>
          <td className="print_header_textss">{printdata.working_hours}</td>
          <td className="print_header_textss">{printdata.from_date}</td>
          <td className="print_header_textss">{printdata.to_date}</td>
          <td className="print_header_textss">{printdata.no_of_days}</td>
          <td className="print_header_textss">{printdata.totalcost}</td>
        </tr>
      );
    });

    return (
      <div className="printtabledata">
        <img className="tom_logo_print" src={Logo} alt="image error"/>
        <div className="printDataTitle">Customer History</div>
        <table>
          <thead className="print_header">
            <th className="print_header_text">S.No</th>
            <th className="print_header_text">Member</th>
            <th className="print_header_text">Duty Hours</th>
            <th className="print_header_text">From</th>
            <th className="print_header_text">To</th>
            <th className="print_header_text">No. of Days</th>
            <th className="print_header_text">Fee (KWD)</th>
          </thead>
          <tbody>{printBodyData}</tbody>
        </table>
      </div>
    );
  }
}
