import React from "react";
import "./printdata.css"

export default class PrintData extends React.Component {
  render() {
    var printBodyData = this.props.printTableData.map((printdata, index) => {
      return (
        <tr>
          <td>{index + 1}</td>
          <td>{printdata.customer}</td>
          <td>{printdata.nursename}</td>
          <td>{printdata.noofmonths}</td>
          <td>{printdata.cancellleddate}</td>
          <td>{printdata.time}</td>
        </tr>
      )
    })

    return (
      <div className="printtabledata">
        <div className="printDataTitle">Canceled Appointments</div>
        <table>
          <thead>
            <th>S.No</th>
            <th>Member</th>
            <th>Nurse</th>
            <th>Duty Hours</th>
            <th>Canceled on</th>
          </thead>
          <tbody>
            {printBodyData}
          </tbody>
        </table>
      </div>
    );
  }
}