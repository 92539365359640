import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import "./PrivacyPolicy.css";
import Logo from "../../Images/Logo.png";

export default class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div>
          <div style={{ padding: "15px", fontSize: "16px" }}>
            <div style={{ textAlign: "center" }}>
              {" "}
              <img className="logo_image" src={Logo} />{" "}
            </div>
            <p className="p_heading"> Terms of use </p>
            <p>
              {" "}
              We hope you love using The One Moment App. We really do. This
              document contains important information that you need to consider
              before making an important decision.{" "}
            </p>
            <p className="p_heading"> Effective Date: Apr 01, 20222 </p>
            <p>
              {" "}
              The following terms and conditions constitute an agreement between
              you and The One Moment. (“The One Moment,” “we,” or “us”), the
              operator of The One Moment App (the “Site”) and related websites,
              applications, services and mobile applications provided by The One
              Moment and on/in which these Terms of Use are posted or referenced
              (collectively, the “Services”). These terms of use (the “Terms of
              Use”), together with our PRIVACY POLICY, ACCEPTABLE USE POLICY and
              ADDITIONAL TERMS (each of which are incorporated herein by
              reference, and collectively, this “Agreement”) govern your use of
              the Services, whether or not you have created an account. You must
              agree to and accept all of the terms of this Agreement, or you
              don’t have the right to use the Services.
            </p>
            <p>
              {" "}
              BY USING OR OTHERWISE ACCESSING THE SERVICES AND/OR BY CREATING AN
              ACCOUNT WITH US, YOU AGREE TO THE TERMS OF THIS AGREEMENT,
              INCLUDING THE INFORMATION PRACTICES DISCLOSED IN OUR PRIVACY
              POLICY, THE USE RESTRICTIONS IN THE ACCEPTABLE USE POLICY AND THE
              TERMS AND CONDITIONS IN THE ADDITIONAL TERMS. IF YOU DO NOT AGREE
              WITH THIS AGREEMENT, YOU CANNOT USE THE SERVICES.{" "}
            </p>
            <p>
              {" "}
              ARBITRATION NOTICE AND CLASS ACTION WAIVER: YOU AGREE THAT
              DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING,
              INDIVIDUAL ARBITRATION AND THAT YOU WAIVE YOUR RIGHT TO
              PARTICIPATE IN A CLASS OR COLLECTIVE ACTION LAWSUIT, OR CLASS OR
              COLLECTIVE ARBITRATION.{" "}
            </p>
            <p>
              {" "}
              Please note that we offer the Services “AS IS” and without
              warranties. If you create an account or use the Services on behalf
              of an individual or entity other than yourself, you represent that
              you are authorized by such individual or entity to accept this
              Agreement on such individual’s or entity’s behalf.{" "}
            </p>
            <p className="p_heading"> 1. ABOUT THE SITE </p>
            <p>
              {" "}
              Portions of the Services can be viewed without a The One Moment
              account. To benefit from all of the Services we offer, you must
              create a The One Moment account and provide certain basic
              information about yourself, which you authorize The One Moment to
              use and disclose as described in our Privacy Policy.{" "}
            </p>
            <p>
              {" "}
              You acknowledge that although some Content may be provided by
              healthcare professionals, the provision of such Content does not
              create a medical professional/patient relationship, and does not
              constitute an opinion, medical advice, or diagnosis or treatment,
              but is provided to assist you in choosing a doctor, dentist or
              other healthcare specialist, professional, provider, organization,
              or agents or affiliates thereof (collectively, “Healthcare
              Provider”). “Content” means content, text, data, graphics, images,
              photographs, video, audio, information, suggestions, guidance, and
              other materials provided, made available or otherwise found
              through the Services and/or Site, including, without limitation,
              Content provided in direct response to your questions or postings.{" "}
            </p>
            <p>
              {" "}
              WHILE WE MAKE REASONABLE EFFORTS TO PROVIDE YOU WITH ACCURATE
              CONTENT, WE MAKE NO GUARANTEES, REPRESENTATIONS OR WARRANTIES,
              WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO PROFESSIONAL
              QUALIFICATIONS, EXPERTISE, QUALITY OF WORK, PRICE OR COST
              INFORMATION, INSURANCE COVERAGE OR BENEFIT INFORMATION, OR ANY
              OTHER CONTENT AVAILABLE THROUGH THE SERVICES. IN NO EVENT SHALL WE
              BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DECISION MADE OR ACTION
              TAKEN BY YOU IN RELIANCE ON ANY SUCH CONTENT. FURTHERMORE, WE DO
              NOT IN ANY WAY ENDORSE OR RECOMMEND ANY INDIVIDUAL OR ENTITY
              LISTED OR ACCESSIBLE THROUGH THE SERVICES.{" "}
            </p>
            <p className="p_heading"> 2. WE DO NOT PROVIDE MEDICAL ADVICE </p>
            <p>
              {" "}
              The Content that you obtain or receive from The One Moment, its
              employees, contractors, partners, sponsors, advertisers, licensors
              or otherwise through the Services, is for informational,
              scheduling and payment purposes only. All medically related
              information, including, without limitation, information shared via
              The One Moment Answers, The One Moment blog, The One Moment social
              channels, The One Moment emails and text messages, and The One
              Moment advertising, comes from independent healthcare
              professionals and organizations and is for informational purposes
              only.{" "}
            </p>
            <p>
              {" "}
              WHILE WE HOPE THE CONTENT IS USEFUL IN YOUR HEALTHCARE JOURNEY, IT
              IS NOT INTENDED AS A SUBSTITUTE FOR, NOR DOES IT REPLACE,
              PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT. DO NOT
              DISREGARD, AVOID OR DELAY OBTAINING MEDICAL ADVICE FROM A
              QUALIFIED HEALTHCARE PROVIDER, UNDER ANY CIRCUMSTANCE. DO NOT USE
              THE SERVICES FOR EMERGENCY MEDICAL NEEDS. IF YOU EXPERIENCE A
              MEDICAL EMERGENCY, IMMEDIATELY CALL A HEALTHCARE PROFESSIONAL AND
              911. YOUR USE OF THE CONTENT IS SOLELY AT YOUR OWN RISK. NOTHING
              STATED OR POSTED ON THE SITE OR AVAILABLE THROUGH ANY SERVICES IS
              INTENDED TO BE, AND MUST NOT BE TAKEN TO BE, THE PRACTICE OF
              MEDICINE, DENTISTRY, NURSING, OR OTHER PROFESSIONAL HEALTHCARE
              ADVICE, OR THE PROVISION OF MEDICAL CARE.{" "}
            </p>
            <p>
              {" "}
              We do not recommend or endorse any specific tests, Healthcare
              Providers, procedures, opinions, or other information that may
              appear through the Services. If you rely on any Content, you do so
              solely at your own risk. We encourage you to independently confirm
              any Content relevant to you with other sources, including the
              Healthcare Provider’s office, medical associations relevant to the
              applicable specialty, your state medical boards, and the
              appropriate licensing or certification authorities to verify
              listed credentials and education.{" "}
            </p>
            <p className="p_heading"> 3. NO DOCTOR PATIENT RELATIONSHIP </p>
            <p>
              {" "}
              DOCTORS, DENTISTS, NURSES, AND OTHER MEDICAL PROFESSIONALS USE THE
              SERVICES TO SHARE CONTENT WITH YOU, BUT YOUR USE OF THIS CONTENT
              IS NOT A SUBSTITUTE FOR HEALTHCARE. NO LICENSED MEDICAL
              PROFESSIONAL/PATIENT RELATIONSHIP IS CREATED WHEN YOU USE THE
              SERVICES OR CONTENT. THIS IS TRUE WHETHER SUCH CONTENT IS PROVIDED
              BY OR THROUGH THE USE OF THE SERVICES OR THROUGH ANY OTHER
              COMMUNICATIONS FROM THE ONE MOMENT INCLUDING, WITHOUT LIMITATION,
              THE “FIND A DOCTOR” FEATURE, THE ONE MOMENT ANSWERS, THE ONE
              MOMENT BLOG, THE ONE MOMENT SOCIAL CHANNELS, THE ONE MOMENT EMAILS
              OR TEXT MESSAGE LINKS TO OTHER SITES, OR ANY ASSISTANCE WE MAY
              PROVIDE TO HELP YOU FIND AN APPROPRIATE HEALTHCARE PROVIDER IN ANY
              FIELD.{" "}
            </p>
            <p>
              {" "}
              The One Moment encourages Healthcare Providers to use the Services
              responsibly, but we have no control over, and cannot guarantee the
              availability of, any Healthcare Provider at any particular time.
              We will not be liable for cancelled or otherwise unfulfilled
              appointments, or any injury or loss resulting therefrom, or for
              any other injury or loss resulting or arising from, or related to,
              the use of the Site or Services whatsoever.{" "}
            </p>
            <p className="p_heading">
              {" "}
              4. AUTHORIZATION AND ACKNOWLEDGEMENT; IMPORTANT INFORMATION ABOUT
              HEALTHCARE PROVIDER RELATIONSHIPS AND HEALTHCARE PROVIDER LISTS{" "}
            </p>
            <p>
              {" "}
              In connection with using the Site and the Services to locate and
              schedule appointments with Healthcare Providers, you understand
              that:{" "}
            </p>
            <p>
              {" "}
              YOU ARE RESPONSIBLE FOR CHOOSING YOUR OWN HEALTHCARE PROVIDER,
              INCLUDING WITHOUT LIMITATION, DETERMINING WHETHER THE APPLICABLE
              HEALTHCARE PROVIDER IS SUITABLE FOR YOUR HEALTHCARE NEEDS BASED ON
              SPECIALTY, EXPERIENCE, QUALIFICATION, LICENSES AND OTHER IMPORTANT
              FACTS AND CIRCUMSTANCES THAT COULD IMPACT YOUR CARE.{" "}
            </p>
            <p>
              {" "}
              The One Moment or its designee takes certain limited steps to (a)
              verify that Healthcare Providers participating in the Services
              hold certain active licenses, certifications or registrations
              required by law to practice the specialties of the services
              offered by them through the Services, and (b) verify that
              Healthcare Providers are not listed in the Kuwait Ministry of
              Exclusion database. The One Moment may also exclude Healthcare
              Providers from our Services who, in The One Moment’s discretion,
              have engaged in inappropriate or unprofessional conduct.{" "}
            </p>
            <p>
              {" "}
              Some Healthcare Providers listed through the Services enter into
              contracts with us, and may pay us a fee in order to be marketed
              through or to use the Services. To help you find Healthcare
              Providers who may be suitable for your needs, and enable the
              maximum choice and diversity of Healthcare Providers participating
              in the Services, we will provide you with lists and/or profiles of
              Healthcare Providers. These results are based on information that
              you provide to us, such as insurance information, geographical
              location, and healthcare specialty. They may also be based on
              other criteria (including, for example, Healthcare Provider
              availability, past selections by and/or ratings of Healthcare
              Providers by you or by other The One Moment users, and past
              experience of The One Moment users with Healthcare Providers).
              Note that The One Moment (a) does not recommend or endorse any
              Healthcare Providers, (b) does not make any representations or
              warranties with respect to these Healthcare Providers or the
              quality of the healthcare services they may provide, and (c) does
              not receive any additional fees from Healthcare Providers for
              featuring them (i.e., higher or better placement on lists) through
              the Services (subject to Sponsored Results as described below).
              Note, however, to the extent that you use the Services as provided
              by your employer, The One Moment may provide lists and/or profile
              previews based also on criteria determined by your employer and
              your employer’s agents or advisors. In addition, to the extent
              that The One Moment serves as a platform for, or provides
              technical support to, the provider directory associated with your
              health coverage, Healthcare Providers will appear in the directory
              based on criteria determined by your health plan or plan sponsor,
              as applicable. We may show you advertisements or sponsored results
              (“Sponsored Results”) on the Site, including above the search
              results. The One Moment receives additional fees from Healthcare
              Providers for providing Sponsored Results. Sponsored Results shown
              through the Services are not, and should not be considered, an
              endorsement or recommendation by The One Moment of the Healthcare
              Provider.{" "}
            </p>
            <p className="p_heading">
              {" "}
              5. THE SERVICES AND CONTENT ARE INFORMATIONAL AND EDUCATIONAL
              RESOURCES{" "}
            </p>
            <p>
              {" "}
              The Services are an informational and educational resource for
              consumers and Healthcare Providers. We may, but have no obligation
              to, publish Content through the Services that is reviewed by our
              editorial personnel. No party (including The One Moment) involved
              in the preparation or publication of such works guarantee that the
              Content is timely, accurate or complete, and they will not be
              responsible or liable for any errors or omissions in, or for the
              results obtained from the use of, such Content.{" "}
            </p>
            <p className="p_heading"> Healthcare Provider Content: </p>
            <p>
              {" "}
              Healthcare Provider and practice Content is intended for general
              reference purposes only. Healthcare Provider Content may be
              provided by the Healthcare Provider and/or office staff, and
              collected from multiple other data sources that may not be
              confirmed by the Healthcare Provider. Such Content can change
              frequently and may become out of date, incomplete or inaccurate.
              Neither the Site nor The One Moment provides any advice or
              qualification certification about any particular Healthcare
              Provider.{" "}
            </p>
            <p className="p_heading"> Procedures/Products/Services: </p>
            <p>
              {" "}
              Procedures, products, services and devices discussed and/or
              marketed through the Services are not applicable to all
              individuals, patients or all clinical situations. Any procedures,
              products, services or devices represented through the Services by
              advertisers, sponsors, and other participants of the Services,
              either paid or unpaid, are presented for your awareness and do not
              necessarily imply, and we make no claims as to, safety or
              appropriateness for any particular individual or prediction of
              effectiveness, outcome or success.{" "}
            </p>
            <p className="p_heading"> Insurance Content: </p>
            <p>
              {" "}
              Insurance and insurance related Content (including, without
              limitation, insurance coverage and benefit Content) (“Insurance
              Content”) is intended for general reference purposes and for your
              convenience only. Such Insurance Content may be based on Personal
              Information (as defined below) you provide to us as well as
              Healthcare Provider Content (such as CPT codes and other encounter
              data). The Insurance Content may be provided either directly by
              the insurance provider you identify or via a third-party
              clearinghouse. Insurance Content can change frequently and may
              become out of date, incomplete or inaccurate. You agree that you
              will (a) provide accurate and complete insurance-related Personal
              Information, and (b) verify and update your insurance-related
              Personal Information (including, without limitation, verifying
              such Personal Information obtained by automated means from an
              insurance card you provide). Although The One Moment strives to
              provide you with accurate Insurance Content, we are not
              responsible for any inaccurate, incomplete or outdated Insurance
              Content, and you are responsible for complying with subparts (a)
              and (b) hereof.{" "}
            </p>
            <p className="p_heading"> 6. YOUR PERSONAL INFORMATION </p>
            <p>
              {" "}
              Protecting patient privacy and keeping your information secure are
              among our biggest priorities. Our Privacy Policy details how we
              may use, share, and maintain the information that you voluntarily
              share with The One Moment, which may include, without limitation,
              your name, address, social security number and contact
              information, insurance information, medical history and current
              medical needs, billing information, and other personally
              identifiable information (collectively, “Personal Information”).{" "}
            </p>
            <p>
              {" "}
              You may elect to enter information into a medical history form
              (“Medical History Form”) on behalf of yourself or a third party
              from whom you have authorization to provide such information. You
              can request (and in doing so, authorize) The One Moment to provide
              this information to your chosen Healthcare Provider. You
              acknowledge and agree that such information will be reviewed and
              approved by you or someone authorized by you at the time of your
              appointment to ensure its accuracy. You also acknowledge that The
              One Moment may use the data or information you provide in a
              Medical History Form in accordance with our Privacy Policy.{" "}
            </p>
            <p className="p_heading"> 7. YOUR RESPONSIBILITIES </p>
            <p className="p_heading"> 7.1 Your Account Credentials </p>
            <p>
              {" "}
              When you create a The One Moment account, you will provide an
              email address and create a password (collectively, “Credentials”).
              You should keep your Credentials private and not share your
              Credentials with anyone else. You must immediately notify us if
              your password has been stolen or compromised by sending an email
              to support@theonemoment.com. You may also be able to connect to
              the Services through a third party service, such as Facebook or
              Google. If you connect to the Services through a third party
              service, you give us permission to access and use your information
              from such third party service as permitted by such third party
              service, and to store your log-in credentials for such third party
              service. The One Moment has no control over, and assumes no
              responsibility for, the content, accuracy, privacy policies, or
              practices of or opinions expressed by any such third party.{" "}
            </p>
            <p className="p_heading"> 7.2 Your Responsibilities Generally </p>
            <p>
              {" "}
              The Services are free, but you are still responsible for your
              healthcare expenses. Usual, customary and any other charges for
              any medical or related services rendered by Healthcare Providers
              will apply and will be entirely your responsibility. You are
              responsible for ensuring that all information that you provide to
              The One Moment is accurate and up-to-date, including your
              insurance information. Some Services may not be available through
              The One Moment or your Healthcare Provider depending upon a number
              of factors, including your insurance participation. Ultimately,
              you must resolve any dispute between you or any Healthcare
              Provider arising from any transaction hereunder directly with the
              Healthcare Provider.{" "}
            </p>
            <p>
              {" "}
              You are responsible for all use of the Services and for all use of
              your Credentials, including use by others to whom you have given
              your Credentials. You may only use the Site and the Services for
              lawful, non-commercial purposes. You may not use the Site in any
              manner that could damage, disable, overburden, or impair our
              servers or networks, or interfere with any other party’s use and
              enjoyment of the Site or the Services. You may not attempt to gain
              unauthorized access to any of the Services, user accounts, or
              computer systems or networks, through hacking, password mining or
              any other means. You may not accumulate or index, directly or
              indirectly, any Content or portion of the Site and/or Services
              (including, without limitation, Healthcare Provider Content,
              appointment availability, price information, and Insurance
              Content) for any purpose whatsoever.{" "}
            </p>
            <p>
              {" "}
              You are also responsible for reviewing and complying with the
              terms set forth in our Acceptable Use Policy.{" "}
            </p>
            <p>
              {" "}
              In addition to our rights in these Terms of Use, we may take any
              legal action and implement any technological measures to prevent
              violations of the restrictions hereunder and to enforce these
              Terms of Use or our Acceptable Use Policy.{" "}
            </p>
            <p className="p_heading">
              {" "}
              7.3 Responsibilities of Healthcare Providers and Others in the
              Healthcare or Medical Industries{" "}
            </p>
            <p>
              {" "}
              If you are a Healthcare Provider or other person or entity in the
              healthcare or medical industries, regardless of whether you
              maintain an account with The One Moment or whether you schedule or
              intend to schedule appointments (including appointments for
              Designated Provider Services, as defined in the Additional Terms)
              through the Services, you acknowledge and agree that:
            </p>
            <p>
              {" "}
              (a) You will not use the Services to view, access or otherwise
              use, directly or indirectly, price, availability, or other Content
              for any purpose other than your own personal use as a patient or
              prospective patient.{" "}
            </p>
            <p>
              {" "}
              (b) You will not use the Services to establish, attempt to
              establish, or enforce, directly or indirectly, any agreement or
              coordination of the prices charged for any product or service; the
              kinds, frequencies or amounts of any product or service offered;
              or the customer or customer categories for any product or service,
              or otherwise engage or attempt to engage in price fixing, output
              restriction, or customer or market allocation.{" "}
            </p>
            <p>
              {" "}
              (c) You will not use the Services, directly or indirectly, to
              engage in any anti-competitive, deceptive or unfair practices, or
              otherwise violate applicable antitrust, competition or consumer
              protection laws, or regulations.{" "}
            </p>
            <p className="p_heading">
              {" "}
              8. CHANGES TO THE SERVICES; NEW SERVICES; ADDITIONAL TERMS{" "}
            </p>
            <p className="p_heading">
              {" "}
              8.1 Changes to the Services; New Services
            </p>
            <p>
              {" "}
              We may from time to time add new features to the Services,
              substitute a new service for one of the existing Services, or
              discontinue or suspend one of the existing Services. Under no
              circumstances will The One Moment be liable for any suspension or
              discontinuation of any of the Services or portion thereof, and the
              use of new services will be governed by this Agreement.{" "}
            </p>
            <p className="p_heading"> 8.2 Additional Terms </p>
            <p>
              {" "}
              Some Services may have additional terms (including, without
              limitation, policies, guidelines, and rules) that will further
              govern your use of that particular Service, and supplement this
              Agreement. If you choose to register for, access or use any such
              Services, you may be presented with such additional terms, which
              may also be found in the Additional Terms. By using those
              Services, you agree to comply with any such additional terms,
              which are incorporated by reference into this Agreement.{" "}
            </p>
            <p className="p_heading"> 9. LINKS TO OTHER WEBSITES </p>
            <p>
              {" "}
              While using the Services, you may encounter links to other
              websites. These links are provided for your convenience only and
              we do not endorse these sites or the products and services they
              provide. You acknowledge and agree that we are not responsible or
              liable for the content or accuracy of these other websites.
              Although we attempt to link to trustworthy websites, it is
              possible that they will contain materials that are objectionable,
              unlawful, or inaccurate and we will not be responsible or liable
              for the legality or decency of material contained in or accessed
              through such other websites.{" "}
            </p>
            <p className="p_heading"> 10. CONTENT YOU POST OR SUBMIT </p>
            <p>
              {" "}
              You will have the opportunity to submit feedback regarding your
              experiences with Healthcare Providers you find through the
              Services, to submit inquiries concerning possible medical needs
              and to participate in the other interactive or community features
              of the Site (collectively, “Posted Information”). It is important
              that you act responsibly when providing Posted Information. Your
              Posted Information must comply with our Acceptable Use Policy, and
              your Healthcare Provider reviews must comply with our guidelines
              for reviews found at https://theonemoment.com/verifiedreviews.{" "}
            </p>
            <p>
              {" "}
              The One Moment reserves the right to investigate and, at our
              discretion, take appropriate legal action against anyone who
              violates these Terms of Use or the Acceptable Use Policy,
              including without limitation, removing any offending communication
              from the Services and terminating the account of such violators or
              blocking your use of the Services.{" "}
            </p>
            <p>
              {" "}
              By posting Posted Information through the Services, you agree to
              and hereby do grant, and you represent and warrant that you have
              the right to grant, to The One Moment and its contractors an
              irrevocable, perpetual, royalty-free, fully sublicensable, fully
              paid up, worldwide license to use, copy, publicly perform,
              digitally perform, publicly display, and distribute such Posted
              Information and to adapt, edit, translate, prepare derivative
              works of, or incorporate into other works, such Posted
              Information.{" "}
            </p>
            <p className="p_heading"> 11. YOUR USE OF CONTENT </p>
            <p>
              {" "}
              All of the Content is owned by us or our licensors and is
              protected by copyright, trademark, patent, and trade secret laws,
              other proprietary rights, and international treaties. You
              acknowledge that the Services and any underlying technology or
              software used in connection with the Services contain The One
              Moment’s proprietary information. We give you permission to use
              the Content for personal, non-commercial purposes only and do not
              transfer any intellectual property rights to you by virtue of
              permitting your use of the Services. You may print, download, and
              store information from the Site for your own convenience, but you
              may not copy, distribute, republish (except as permitted in this
              paragraph), sell, or exploit any of the Content, or exploit the
              Site or Services in whole or in part, for any commercial gain or
              purpose whatsoever. Except as expressly provided herein, neither
              The One Moment nor its suppliers grant you any express or implied
              rights, and all rights in the Site and the Services not expressly
              granted by The One Moment to you are retained by The One Moment.{" "}
            </p>
            <p className="p_heading"> 12. DISCLAIMER </p>
            <p>
              {" "}
              We created The One Moment to improve patients’ healthcare
              experience, and we want your experience with The One Moment to be
              exceptional. While we work hard to make that happen, you
              acknowledge that we have no control over, and no duty to take any
              action regarding: (a) which users gain access to the Site and/or
              the Services, (b) what Content you access, (c) what effects the
              Content may have on you, (d) how you may interpret or use the
              Content, or (e) what actions you may take as a result of having
              been exposed to the Content. You release us from all liability for
              you having acquired, you having not acquired, or your use of
              Content. We make no representations or warranties regarding
              suggestions or recommendations of services or products offered or
              purchased through the Site and/or the Services. We have no special
              relationship with or fiduciary duty to you. WE PROVIDE THE
              SERVICES “AS IS” AND “AS AVAILABLE.” WE MAKE NO EXPRESS OR IMPLIED
              WARRANTIES OR GUARANTEES ABOUT THE SERVICES. TO THE MAXIMUM EXTENT
              PERMITTED BY LAW, WE HEREBY DISCLAIM ALL SUCH WARRANTIES,
              INCLUDING ALL STATUTORY WARRANTIES, WITH RESPECT TO THE SERVICES
              AND THE SITE, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES THAT
              THE SERVICES ARE MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE,
              FIT FOR A PARTICULAR PURPOSE OR NEED, OR NON-INFRINGING. WE DO NOT
              GUARANTEE THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF
              THE SERVICES WILL BE EFFECTIVE, RELIABLE OR ACCURATE OR WILL MEET
              YOUR REQUIREMENTS. WE DO NOT GUARANTEE THAT YOU WILL BE ABLE TO
              ACCESS OR USE THE SERVICES (EITHER DIRECTLY OR THROUGH THIRD-PARTY
              NETWORKS) AT TIMES OR LOCATIONS OF YOUR CHOOSING. WE ARE NOT
              RESPONSIBLE FOR THE ACCURACY, RELIABILITY, TIMELINESS OR
              COMPLETENESS OF INFORMATION PROVIDED BY USERS OF THE SERVICES OR
              ANY OTHER DATA OR INFORMATION PROVIDED OR RECEIVED THROUGH THE
              SERVICES. EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE ONE MOMENT
              MAKES NO WARRANTIES ABOUT THE INFORMATION SYSTEMS, SOFTWARE AND
              FUNCTIONS MADE ACCESSIBLE THROUGH THE SERVICES OR ANY OTHER
              SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE
              INFORMATION. THE ONE MOMENT DOES NOT WARRANT THAT THE SITE OR THE
              SERVICES WILL OPERATE ERROR-FREE, BUG-FREE OR FREE FROM DEFECTS,
              THAT LOSS OF DATA WILL NOT OCCUR, OR THAT THE SERVICES, SOFTWARE
              OR SITE ARE FREE OF COMPUTER VIRUSES, CONTAMINANTS OR OTHER
              HARMFUL ITEMS.{" "}
            </p>
            <p className="p_heading"> 13. GENERAL LIMITATION OF LIABILITY </p>
            <p>
              {" "}
              WHILE WE’RE ALWAYS IMPROVING, AND WE HOPE YOU HAVE AN EXCELLENT
              EXPERIENCE WITH THE ONE MOMENT, YOUR SOLE AND EXCLUSIVE REMEDY FOR
              ANY DISPUTE WITH US IS THE CANCELLATION OF YOUR ACCOUNT. IN NO
              EVENT SHALL OUR CUMULATIVE LIABILITY TO YOU FOR ANY AND ALL CLAIMS
              RELATING TO OR ARISING OUT OF YOUR USE OF THE SERVICES OR THE
              SITE, REGARDLESS OF THE FORM OF ACTION, EXCEED THE GREATER OF: (a)
              THE TOTAL AMOUNT OF FEES, IF ANY, THAT YOU PAID TO CREATE OR
              MAINTAIN AN ACCOUNT WITH THE SITE OR THE SERVICES, OR (b) KW 10;
              EXCEPT THAT, FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF
              YOUR USE OF THE TRANSACTION PROCESSING SERVICES (AS DEFINED IN THE
              ADDITIONAL TERMS), IN NO EVENT SHALL OUR CUMULATIVE LIABILITY
              THEREOF REGARDLESS OF THE FORM OF ACTION EXCEED THE TOTAL AMOUNT
              OF TRANSACTION PROCESSING FEES (AS DESCRIBED IN THE ADDITIONAL
              TERMS), IF ANY, REMITTED TO AND RETAINED BY THE ONE MOMENT FOR
              PROVIDING TRANSACTION PROCESSING SERVICES FOR APPOINTMENTS MADE BY
              YOU IN THE SIX (6) MONTHS PRIOR TO THE EVENT GIVING RISE TO THE
              APPLICABLE CLAIM.{" "}
            </p>
            <p>
              {" "}
              IN NO EVENT SHALL WE BE LIABLE TO YOU (OR TO ANY THIRD PARTY
              CLAIMING UNDER OR THROUGH YOU) FOR ANY INDIRECT, SPECIAL,
              INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM YOUR
              USE OF, OR INABILITY TO USE, THE SITE AND/OR THE SERVICES. THESE
              EXCLUSIONS APPLY TO ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS
              OF GOODWILL, COMPUTER FAILURE OR MALFUNCTION, ANY OTHER COMMERCIAL
              DAMAGES OR LOSSES, OR MEDICAL MALPRACTICE OR NEGLIGENCE OF
              HEALTHCARE PROVIDERS UTILIZED THROUGH USE OF THE SERVICES, EVEN IF
              WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
              BECAUSE SOME COURTS OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
              THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
              DAMAGES, IN SUCH COURTS OR JURISDICTIONS, OUR LIABILITY SHALL BE
              LIMITED IN ACCORDANCE HEREIN TO THE MAXIMUM EXTENT PERMITTED BY
              LAW.{" "}
            </p>
            <p className="p_heading"> 14. TERMINATION </p>
            <p>
              {" "}
              If you’re not using the Service appropriately, we’ll want to work
              with you to set things straight. But we reserve the right, if we
              determine it is best, to terminate, suspend and/or deactivate your
              account immediately, without notice, if there has been a violation
              of this Agreement or other policies and terms posted on the Site
              or through the Services by you or by someone using your
              Credentials. We may also terminate, suspend or deactivate your
              account for any other reason, including inactivity for an extended
              period. The One Moment shall not be liable to you or any third
              party for any termination, suspension or deactivation of your
              access to the Site and/or the Services. Further, you agree not to
              attempt to use the Site and/or the Services after any such
              termination, suspension or deactivation (except where deactivation
              is due solely to inactivity, and you are permitted to create
              another account). Sections 1, 2, 3, 4, 6, 8, 10, 11, 12, 13, 14,
              15, and 16 shall survive any termination or expiration of these
              Terms of Use.{" "}
            </p>
            <p className="p_heading"> 15. INDEMNIFICATION </p>
            <p>
              {" "}
              Upon a request by us, you agree to defend, indemnify, and hold
              harmless us, our employees, contractors, officers, directors,
              agents, parent and other affiliates, from all liabilities, claims,
              demands and expenses, including attorney’s fees, that arise from
              or are related to (a) your use of the Site and/or Services, or (b)
              the violation of this Agreement (including without limitation the
              Terms of Use, the Acceptable Use Policy, and the Additional
              Terms), or of any intellectual property or other right of any
              person or entity, by you or any person using your Credentials. The
              foregoing indemnification obligation does not apply to
              liabilities, claims and expenses arising as a result of our own
              gross negligence or intentional misconduct.{" "}
            </p>
            <p className="p_heading"> 16. MISCELLANEOUS </p>
            <p className="p_heading">
              {" "}
              16.1 Electronic Contracting; Copyright Dispute{" "}
            </p>
            <p>
              {" "}
              Your affirmative act of using the Services and/or creating an
              account constitutes your electronic signature to this Agreement,
              which includes our Privacy Policy, Acceptable Use Policy and
              Additional Terms, and your consent to enter into such agreements
              with us electronically.{" "}
            </p>
            <p>
              {" "}
              Please review our Acceptable Use Policy for our copyright dispute
              policy.{" "}
            </p>
            <p className="p_heading"> 16.2 Changes to These Terms of Use </p>
            <p>
              We may change these Terms of Use and the other documents that are
              part of the Agreement at any time, as we reasonably deem
              appropriate. Upon any such change, we will post the amended terms
              on the Site; we may also attempt to notify you in some other way.
              Your continued use of the Site and/or the Services following such
              posting shall constitute your affirmative acknowledgement of the
              Terms of Use or other applicable Agreement document, the
              modification, and agreement to abide and be bound by the Terms of
              Use or other applicable Agreement document, as amended. We
              encourage you to periodically review these Terms of Use and the
              Agreement. IF AT ANY TIME YOU CHOOSE NOT TO ACCEPT THESE TERMS OF
              USE OR THE AGREEMENT, INCLUDING FOLLOWING ANY SUCH MODIFICATIONS
              HERETO, THEN YOU MUST STOP USING THE SITE AND THE SERVICES.
            </p>
            <p className="p_heading">16.3 Limitation of Claims</p>
            <p>
              No action arising under or in connection with this Agreement,
              regardless of the form, may be brought by you more than one (1)
              year after the cause of action arose; actions brought thereafter
              are forever barred.
            </p>
            <p className="p_heading">
              16.4 Choice of Law; Arbitration Clause and Class Action Waiver –
              Important – Please Review as This Affects Your Legal Rights
            </p>
            <p>
              {" "}
              We hope that we can work out any disagreements you might have with
              The One Moment. But if there is a dispute that needs to be further
              resolved, that process will take place according to this section.
              This Agreement shall be deemed to have been entered into and shall
              be construed and enforced in accordance with the laws of KUWAIT as
              applied to contracts made and to be performed entirely within
              Kuwait, without giving effect to the country’s conflicts of law
              statute. YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US (WHETHER
              OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH REGARD TO YOUR
              RELATIONSHIP WITH US, INCLUDING, WITHOUT LIMITATION, DISPUTES
              RELATED TO THIS AGREEMENT, YOUR USE OF THE SERVICES, AND/OR RIGHTS
              OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING,
              INDIVIDUAL ARBITRATION UNDER THE CODE OF CIVIL AND COMMERCIAL
              PROCEDURE, LAW NO 38 OF 1980 (“CCP”) (WHICH ADDRESSES ARBITRATION
              IN CHAPTER 12, ARTICLES 173 TO 188) AND THE JUDICIAL ARBITRATION
              IN CIVIL AND COMMERCIAL MATTER LAW, LAW NO 11 OF 1995 AS AMENDED
              BY LAW NO 12 OF 2013 ( “JUDICIAL ARBITRATION LAW”) RULES FOR
              ARBITRATION OF CONSUMER-RELATED DISPUTES. Neither you nor we will
              participate in a class action or class-wide arbitration for any
              claims covered by this Agreement to arbitrate. YOU ARE WAIVING THE
              ABILITY TO PARTICIPATE AS A CLASS REPRESENTATIVE OR MEMBER IN ANY
              CLASS OR COLLECTIVE CLAIM YOU MAY HAVE AGAINST US INCLUDING ANY
              RIGHT TO CLASS OR COLLECTIVE ARBITRATION OR ANY CONSOLIDATION OF
              INDIVIDUAL ARBITRATIONS. You also agree not to participate in
              claims brought in a private attorney general or representative
              capacity, or consolidated claims involving another person’s
              account, if we are a party to the proceeding. This dispute
              resolution provision will be governed by the Federal Arbitration
              Act and not by any state law concerning arbitration. Judgment on
              the award rendered by the arbitrator may be entered in any court
              having competent jurisdiction. Any provision of applicable law
              notwithstanding, the arbitrator will not have authority to award
              damages, remedies or awards that conflict with these Terms of Use
              or the Agreement.{" "}
            </p>
            <p className="p_heading"> 16.5 Severability </p>
            <p>
              {" "}
              In the event any one or more of the provisions of this Agreement
              shall for any reason be held to be invalid, illegal or
              unenforceable, the remaining provisions of this Agreement shall be
              unimpaired. Further, the invalid, illegal or unenforceable
              provision shall be replaced by a provision that comes closest to
              the intention of the parties that underlie the invalid, illegal or
              unenforceable provision, except to the extent no such provision is
              valid, legal and enforceable, in which case such invalid, illegal
              or unenforceable provision shall be limited or eliminated to the
              minimum extent necessary so that the other provisions of this
              Agreement remain in full force and effect and enforceable.{" "}
            </p>
            <p className="p_heading"> 16.6 Entire Agreement </p>
            <p>
              {" "}
              This Agreement and any supplemental terms, policies, rules and
              guidelines posted through the Services, each of which are
              incorporated herein by reference, including the Privacy Policy,
              the Acceptable Use Policy and the Additional Terms, constitute the
              entire agreement between you and us and supersede all previous
              written or oral agreements. If any part of this Agreement is held
              invalid or unenforceable, that portion shall be construed in a
              manner consistent with applicable law to reflect, as nearly as
              possible, the original intentions of the parties, and the
              remaining portions shall remain in full force and effect. The
              failure of The One Moment to exercise or enforce any right or
              provision of this Agreement shall not constitute a waiver of such
              right or provision. The failure of either party to exercise in any
              respect any right provided for herein shall not be deemed a waiver
              of any further rights hereunder.{" "}
            </p>
            <p className="p_heading"> 16.7 Headings </p>
            <p>
              {" "}
              The headings of the sections of this Agreement are for convenience
              only, do not form a part hereof, and in no way limit, define,
              describe, modify, interpret or construe the meaning, scope or
              intent of this Agreement or any terms or conditions therein.{" "}
            </p>
            <p className="p_heading"> 16.8 Assignment </p>
            <p>
              {" "}
              We may assign this Agreement at any time, including, without
              limitation, to any parent, subsidiary, or any affiliated company,
              or as part of the sale to, merger with, or other transfer of our
              company to another entity. You may not assign, transfer or
              sublicense this Agreement to anyone else and any attempt to do so
              in violation of this section shall be null and void.{" "}
            </p>
            <p className="p_heading"> 16.9 Eligibility </p>
            <p>
              {" "}
              You must be 18 years of age or over, or the legal age to form a
              binding contract in your jurisdiction if that age is greater than
              18 years of age, to create an account with us or use the Site and
              the Services. If you are between the ages of 13 and 18 or the
              applicable legal age in your jurisdiction, you can use the Site or
              Services only under the supervision of your parent or guardian who
              has agreed to these Terms of Use. Those under the age of 13 may
              not use the Site or Services. Parents or legal guardians of a
              child under the age of 18 may use the Site or Services on behalf
              of such minor child. By using the Site or Services on behalf of a
              minor child, you represent and warrant that you are the parent or
              legal guardian of such child, and that all references in these
              Terms of Use to “you” shall refer to such child or such other
              individual on whose behalf you have authorization to enter into
              these Terms of Use and you in your capacity as the parent or legal
              guardian of such child or as the authorized party of such
              individual.{" "}
            </p>
            <p>
              {" "}
              If you do not qualify under these Terms of Use, do not use the
              Site or Services. Use of the Services is void where prohibited by
              applicable law, and the right to access the Site is revoked in
              such jurisdictions. By using the Site and/or the Services, you
              represent and warrant that you have the right, authority, and
              capacity to enter into these Terms of Use. The Site is
              administered in KUWAIT and intended for KUWAITI users only; any
              use outside of KUWAIT or use related to activities outside of
              KUWAIT is prohibited and at the user’s own risk. Users are
              responsible for compliance with any local, state or federal laws
              applicable to their use of the Services or the Site.{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
