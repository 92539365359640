import i18n from "i18next";
import common_en from "./locales/en/translations.json"
import common_ar from "./locales/ar/translations.json"
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',  
    fallbackLng: "en",                            // language to use
    resources: {
        en: 
        {
            translation:common_en              // 'common' is our custom namespace
        },
        ar: 
        {
            translation:common_ar
        }
    },
});

export default i18n



  