import React, { Component } from "react";
import { Select } from "antd";
import "antd/dist/antd.css";
import TotalnurseTable from "./TotalnurseTable";
import Paper from "@material-ui/core/Paper";
import Plus from "../../Images/plus.png";
import Nurse_form from "./Nurse_form";
import { Input } from "antd";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import { apiurl } from "../../App";
import Axios from "axios";
import moment from "moment";
import ShowNotification from "../ShowNotification/ShowNotification";
import RightArrowOnly from "../../Images/right-arrow-only.png";
import ArrowLeft from "@material-ui/icons/ChevronLeftSharp"
class TotalnurseDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tableData: [],
      search: null,
      props_loading: true,
      nationality: [],
      visible: false,
      successmsg: "",
      issuccess: false,
      nurseoflength:[]
    };
  }

  componentWillMount() {
    this.getNationality();

    this.intervalId = setInterval(() => {
      this.getTableData();
    }, 120000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }

  getNationality = () => {
    Axios({
      method: "GET",
      header: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      url: apiurl + "nurse/getNationality",
    }).then((response) => {
      this.setState({
        nationality: response.data.data,
      }, () => {
        this.getTableData();
      });
    });
  };

  // get table data
  getTableData = () => {
    this.state.nurseoflengt = [];
    var self = this;
    Axios({
      method: "POST",
      url: apiurl + "nurse/getNurseDetails",
      data: {
        vendorId: localStorage.getItem("nurse_vendor_id") || "12",
      },
    })
      .then((response) => {
        var tableData = [];
      console.log("nurselog",response.data.data.length);
      this.state.nurseoflength = response.data.data;
        response.data.data.map((val) => {
          tableData.push({
            nurseName: val.name,
            gender: val.gender,
            age: val.age, //moment().diff(val.dob, "years"),
            experience: val.experience,
            nationality: val.nationality_name || '',
            id: val.nurseId,
          });
        });
        self.setState({
          tableData: tableData,
          props_loading: false,
          totalData: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  openmodal = () => {
    this.setState({ open: true });
  };
  onclosemodal = () => {
    this.setState({ open: false });
  };
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  };


  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  };

  render() {
    console.log("tableData", this.state.tableData);
    console.log("nationality", this.state.nationality);
    const { t } = this.props;

    const { Option } = Select;
    const { Search } = Input;
    const searchdata = [];
    this.state.tableData.filter((data, index) => {
      if (this.state.search === undefined || this.state.search === null) {
        searchdata.push({
          nurseName: data.nurseName,
          gender: data.gender,
          age: data.age,
          experience: data.experience,
          nationality: data.nationality,
          id: data.id,
        });
      } else if (
        (data.nurseName !== null &&
          data.nurseName
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.gender !== null &&
          data.gender
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.age !== null &&
          data.age.toString().includes(this.state.search.toString())) ||
        (data.experience !== null &&
          data.experience.toString().includes(this.state.search.toString())) ||
        (data.nationality !== null &&
          data.nationality
            .toLowerCase()
            .includes(this.state.search.toLowerCase()))
      ) {
        searchdata.push({
          nurseName: data.nurseName,
          gender: data.gender,
          age: data.age,
          experience: data.experience,
          nationality: data.nationality,
          id: data.id,
        });
      }
    });

    return (
      <div>
        <div className="title_dashboard">
       {localStorage.nurse_language=='ar'?
          <div className="title_header" style={{direction:localStorage.nurse_language=='ar'?"rtl":""}}>
          {this.props.isfromverification && <img src={RightArrowOnly} style={{marginLeft:localStorage.nurse_language=='ar'?"10px":"",cursor: "pointer"}} width={18} height={18} alt="arrow" onClick={() => { this.props.backVerification() }}/>}
          {t("manage_nurses_head")}
        </div> :
           <div className="title_header">
           {this.props.isfromverification && <ArrowLeft className='left_arrow_dashboard' onClick={() => { this.props.backVerification() }}> </ArrowLeft>}
           {t("manage_nurses_head")}
         </div>

       }
          <div  style={{direction:this.props.i18n.language=='ar'?"rtl": "",display:this.props.i18n.language=='ar'?"flex": "",alignItems:this.props.i18n.language=='ar'?"center": ""}}>
            <Search
              className="mr-5"
              placeholder={t('search')}
              onSearch={(value) => console.log(value)}
              style={{ width: 150 ,textAlign:this.props.i18n.language == "ar" ? "right" :'', direction:this.props.i18n.language == "ar"? "rtl": ""}}
              onChange={(e) => this.setState({ search: e.target.value })}
            />
            <img
              src={Plus}
              onClick={this.openmodal}
              style={{ cursor: "pointer", height: "35px", width: "35px",marginRight:this.props.i18n.language=='ar'?"20px": "" }}
            />
          </div>
        </div>
      {this.state.nurseoflength.length < 10  &&
       <div style={{
          fontSize: "17px !important", textAlign: "end", marginRight: "51px", color: "#ff0068",
          marginTop: "5px", fontSize: "16px", fontFamily: "titillium web !important"
        }}>{t("addMinimum10Nurses")}</div> 
         } 

        <TotalnurseTable
          tableData={searchdata}
          getTableData={() => this.getTableData()}
          props_loading={this.state.props_loading}
          totalData={this.state.totalData}
          getNurseHistory={(id, nurseName) => {
            this.props.getNurseHistory(id, nurseName);
          }}
          nationality={this.state.nationality}
          t={t}
          i18n={this.props.i18n}
        />
        <Modalcomp
          visible={this.state.open}
          closemodal={this.onclosemodal}
          title={t("add_nurse")}
          clrchange="textclr"
          xswidth={"md"}
          t={t}
          i18n={this.props.i18n}
          modelwidthClass={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} `}
        >
          <Nurse_form
            visible={this.state.open}
            closemodal={this.onclosemodal}
            editopenModal={false}
            getTableData={() => this.getTableData()}
            nationality={this.state.nationality}
            handleopenSuccess={this.handleopenSuccess}
            t={t}
            i18n={this.props.i18n}
          />
        </Modalcomp>
        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
      </div>
    );
  }
}
export default TotalnurseDashboard;
