import React from "react";
import "./printdata.css"
import Logo from "../../Images/Logo.png";

export default class PrintData extends React.Component {
  render() {
    var printBodyData = this.props.printTableData.map((printdata, index) => {
      return (
        <tr>
          <td className="print_header_textss">{index + 1}</td>
          <td className="print_header_textss">{printdata.nursename}</td>
          <td className="print_header_textss">{printdata.gender}</td>
          <td className="print_header_textss">{printdata.age}</td>
          <td className="print_header_textss">{printdata.experience}</td>
          <td className="print_header_textss">{printdata.Nationality}</td>
          <td className="print_header_textss">{printdata.fromdate}</td>
          <td className="print_header_textss">{printdata.todate}</td>
          <td className="print_header_textss">{printdata.noofdays}</td>
        </tr>
      )
    })

    return (
      <div className="printtabledata">
        <img className="tom_logo_print" src={Logo} alt="image error"/>
        <div className="printDataTitle">Nurses On Leave</div>
        <table>
          <thead className="print_header">
            <th className="print_header_text">S.No</th>
            <th className="print_header_text">Nurse</th>
            <th className="print_header_text">Gender</th>
            <th className="print_header_text">Age</th>
            <th className="print_header_text">Experience</th>
            <th className="print_header_text">Nationality</th>
            <th className="print_header_text">From</th>
            <th className="print_header_text">To</th>
            <th className="print_header_text">No. of Days</th>
          </thead>
          <tbody>
            {printBodyData}
          </tbody>
        </table>
      </div>
    );
  }
}