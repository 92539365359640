import React from "react";
import TotalnurseDashboard from "./TotalnurseDashboard";
import NurseHistoryTable from "./NurseHistoryTable";
import { withTranslation } from 'react-i18next';

class ManageNurse extends React.Component {
  state = {
      nurseId: 0,
      nurseName: '',
    nurseHistory: false,
  };

  // call back to TotalnurseDashboard -> TotalnureseTable
  getNurseHistory = (id, nurseName) => {
    this.setState({
        nurseName,
      nurseId: id,
      nurseHistory: true,
    });
  };
  backToNurseTable = () => {
    this.setState({
      nurseHistory: false,
    });
  };
  render() {
    const { t } = this.props;

    return (
      <>
        {this.state.nurseHistory === false ? (
          <TotalnurseDashboard
            isfromverification={this.props.isfromverification}
            backVerification={this.props.backVerification}
            getNurseHistory={(id, nurseName) =>
              this.getNurseHistory(id, nurseName)
            }
            t={t}
            i18n={this.props.i18n}
          />
        ) : (
          <NurseHistoryTable
            nurseName={this.state.nurseName}
            nurseId={this.state.nurseId}
            backToNurseTable={() => this.backToNurseTable()}
            t={t}
            i18n={this.props.i18n}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(ManageNurse);
