import React from 'react';
import "./printdata.css";
import Logo from "../../Images/Logo.png";
import { formatMoney } from "../../App";

class PrintData extends React.Component {
  renderTableChunk = (dataChunk, startIndex, totalChunks, rowsPerPage) => {
    const { printtabledata, HeadingButton, type, DetailedHistory } = this.props;
    const totalPages = Math.ceil(totalChunks / rowsPerPage);
    const currentPage = Math.ceil(startIndex / rowsPerPage) + 1;

    const isDetailedHistory = DetailedHistory === true;
    const headerColumns = isDetailedHistory
      ? ["S.No", "Nurse", "Duty Hours", "From", "To", "No. of Days", "Cost"]
      : ["S.No", "Member", "Age", "Gender", "Contact Number", "Address"];
    const title = isDetailedHistory ? "Nurse Details" : "Member Details";
    const thStyle = { textAlign: "center" };
    return (
      <div className="printtabledata" key={startIndex}>
        <img className="tom_logo_print" src={Logo} alt="image error" />
        <div className="printDataTitle">{title}</div>
        <div className='kwd_vlaues_font'>All values in KWD</div>
        <table>
          <thead className="print_header">
            <tr>
              {headerColumns.map((header, index) => (
                <th key={index} className="print_header_text" style={thStyle}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataChunk.map((printdata, index) => (
              <tr key={startIndex + index}>
                <td className="print_header_textss" style={{ textAlign: "center" }}>{startIndex + index + 1}</td>
                {isDetailedHistory ? (
                  <>
                    <td className="print_header_textss">{printdata.customer}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.workingHours}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.startDate}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.endDate}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.no_of_days}</td>
                    <td className="print_header_textss" style={{ textAlign: 'right' }}>{formatMoney(printdata.totalcost)}</td>
                  </>
                ) : (
                  <>
                    <td className="print_header_textss">{printdata.customer}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.age}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.gender}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.phone}</td>
                    <td className="print_header_textss" style={{ textAlign: "center" }}>{printdata.address}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="print_footer">
          Page {currentPage} of {totalPages}
        </div>
        {dataChunk.length === rowsPerPage && startIndex + rowsPerPage < totalChunks && <div className="page-break"></div>}
      </div>
    );
  };

  render() {
    const rowsPerPage = 30; // Adjust this number based on your page layout and font size
    const { printTableData } = this.props;
    const totalChunks = Math.ceil(printTableData.length / rowsPerPage);

    const tableChunks = [];
    for (let i = 0; i < totalChunks; i++) {
      const startIndex = i * rowsPerPage;
      const dataChunk = printTableData.slice(startIndex, startIndex + rowsPerPage);
      tableChunks.push(this.renderTableChunk(dataChunk, startIndex, printTableData.length, rowsPerPage));
    }

    return <div>{tableChunks}</div>;
  }
}

export default PrintData;
