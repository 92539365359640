import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Map from "./Map";
import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";

class OngoingBookingModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const WrappedMap = withScriptjs(Map);

    return (
      <div>
        
            <div className="map_height">
              <WrappedMap
                // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCUOFpADz8oQmcJPFDbZjUmBMGCLHtOVQQ"
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB9cTfn_1pNmKa5u19FPFZPMcWD36UhyZo"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `500px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                locationdata={this.props.locationdata}
              />
            </div>
          
      </div>
    );
  }
}

export default OngoingBookingModal;
