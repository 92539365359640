import React, { Component } from "react";
// import Logo from "../../Images/Logo.png";
import Logo from "../../Images/logo_new.svg";
import TextField from "@material-ui/core/TextField";
import Nurse_Login from "../../Images/nurse_login.jpg";
import "./Forgot.css";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Inbox from "../../Images/inbox.svg";
import { Link, NavLink } from "react-router-dom";
import Axios from "axios";
import { apiurl } from "../../App";
import { notification } from "antd";
import { Spin } from "antd";
import i18n from "../../i18n/i18n";
import { withTranslation } from 'react-i18next';

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", hidden: true, isLoading: false };
  }
  toggleshow = () => {
    this.setState({ hidden: !this.state.hidden });
  };
  onchange = (e) => {
    this.setState({ email: e.target.value, emailErr: false });
  };

  submitMail = (e) => {
    e.preventDefault();
    try {
      if (this.state.email) {
        this.setState({ isLoading: true });
        const host = window.location.host;
        const pathName = window.location.pathname.split("/")[1];
        var obj_data = {
          email: this.state.email,
          host,
          pathname: pathName,
          module: "nurse",
        };
        Axios({
          method: "POST",
          url: apiurl + "admin/forgot_password_check_mail",
          data: obj_data,
        })
          .then((response) => {
            if (response.data.status === 1) {
              this.state.email = "";
              notification.success({
                message: response.data.msg,
              });
            } else {
              notification.error({
                message: response.data.msg,
              });
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
          });
      } else {
        this.setState({ emailErr: true });
      }
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const {t}=this.props
    return (
      <>
        <Spin
          className="bank_details_spinner_align"
          spinning={this.state.isLoading}
        >
          <div className="pharmacy_login_container">
            <Grid container>
              <Grid item xs={12} md={7} className="pharmacy_image_grid">
                <div className="pharmacy_image_container">
                  <div className="pharmacy_image_div">
                    <div className="pharmacy_image_login">
                      <img src={Nurse_Login} alt="1" className="pharmacy_image" />
                      <p className="pharmacy_textsss">{t("nurse_login_txt")}</p>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={5} className="pharmacy_grid_container">
                <div className="pharmacy_main_container" style={{ height: "49vh" }}>
                  <div className={`${this.props.i18n.language=='ar'?"arabic_cls":""} pharmacy_paper_div `}>
                    <div className="pharmacy_text_container_forgot">
                      <div className="logo_container_forgot">
                        <div className="logo_div">
                          <img className="logo_image" src={Logo} />
                        </div>
                      </div>
                      <div className="pharmacy_Welcometext-container_forgot">
                        <p className="Welcometext">{t('request_new_password')}</p>
                      </div>
                      <div className="pas_msg">
                       {t('forgot_password_content')}
                      </div>

                      <div className="pharmacy_email_container">
                        <TextField
                          type="text"
                          placeholder={t('enter_user_name')}
                          onChange={this.onchange}
                          value={this.state.email}
                          label={t('EMAIL')}
                          inputProps={this.props.i18n.language=='ar'?{style: { textAlign: 'right' }}:{}} // the change is here

                          InputProps={
                             this.props.i18n.language=='ar'?
                             {
                               startAdornment: (
                                   <InputAdornment position="start">
                                     <IconButton>
                                       <img className="inbox_icon" src={Inbox} />
                                     </IconButton>
                                   </InputAdornment>
                                 ),
                               }:{
                                 endAdornment: (
                                   <InputAdornment position="end">
                                     <IconButton>
                                       <img className="inbox_icon" src={Inbox} />
                                     </IconButton>
                                   </InputAdornment>
                                 ),
                               }
                         }
                        />
                      </div>

                      <div
                        style={{
                          display: this.state.emailErr ? "block" : "none",
                          color: "red",
                          textAlign: "center",
                          marginTop: "5px",
                        }}
                      >
                        Please Enter Valid Email
                      </div>

                      <div className="pharmacy_submit_container">
                        <p href="">
                          <button
                            className="login"
                            component={Link}
                            onClick={(e) => this.submitMail(e)}
                          >
                            {t('submit')}
                          </button>
                        </p>
                      </div>

                      <div className="cancel_container">
                        <a
                          component={NavLink}
                          href="/"
                          className="cancelbutton"
                        >
                          {t('cancel')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Spin>
      </>
    );
  }
}
export default withTranslation()(Forgot)








/* import React, { Component } from "react";
import Logo from "../../Images/Logo.png";
import TextField from "@material-ui/core/TextField";
import "./Forgot.css";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Inbox from "../../Images/inbox.svg";
import { Link, NavLink } from "react-router-dom";
import Nurse_Login from "../../Images/nurse_login.png";
import Axios from "axios";
import { apiurl } from "../../App";
import { notification } from "antd";
import { Spin } from "antd";

export default class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = { password: "", hidden: true };
  }
  toggleshow = () => {
    this.setState({ hidden: !this.state.hidden });
  };
  onchange = (e) => {
    this.setState({ password: e.target.value });
  };

  submitMail = (e) => {
    e.preventDefault();
    try {
      if (this.state.email) {
        this.setState({ isLoading: true });
        const host = window.location.host;
        const pathName = window.location.pathname.split("/")[1];
        var obj_data = {
          email: this.state.email,
          host,
          pathname: pathName,
          module: "nurse",
        };
        Axios({
          method: "POST",
          url: apiurl + "admin/forgot_password_check_mail",
          data: obj_data,
        })
          .then((response) => {
            if (response.data.status === 1) {
              notification.success({
                message: response.data.msg,
              });
            } else {
              notification.error({
                message: response.data.msg,
              });
            }
            this.setState({ isLoading: false });
          })
          .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
          });
      } else {
        this.setState({ emailErr: true });
      }
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <div className="pharmacy_forget_container">
        <Grid container>
          <Grid item xs={12} md={7} className="pharmacy_image_grid">
            <div className="pharmacy_image_container">
              <div className="pharmacy_image_div">
                <div className="pharmacy_image_login">
                  <img src={Nurse_Login} alt="1" className="pharmacy_image" />
                  <div className="nurse_cmpny">Nurse Company</div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={5} className="pharmacy_grid_container">
            <div className="pharmacy_main_container">
              <div className="pharmacy_paper_div">
                <div className="pharmacy_text_container">
                  <div className="logo_container">
                    <div className="logo_div">
                      <img className="logo_image" src={Logo} />
                    </div>
                  </div>
                  <div className="pharmacy_Welcometext-container">
                    <p className="Welcometext">REQUEST NEW PASSWORD</p>
                  </div>
                  <div className="pas_msg">
                    Enter the current email address associated with your ONE
                    MOMENT account, then click submit.We'll email you a link to
                    a page where you can easily create a new password
                  </div>

                  <div className="pharmacy_email_container">
                    <TextField
                      type="text"
                      placeholder="kishore@gmail.com"
                      onChange={this.onchange}
                      value={this.state.password}
                      label="EMAIL"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment>
                            <IconButton>
                              <img className="inbox_icon" src={Inbox} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className="pharmacy_submit_container">
                    <a href="">
                      <button
                        className="login"
                        component={Link}
                        onClick={(e) => this.submitMail(e)}
                      >
                        Submit
                      </button>
                    </a>
                  </div>

                  <div className="cancel_container">
                    <a component={NavLink} href="/" className="cancelbutton">
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
 */