import React, { Component } from "react";
import Print from "../../Images/print.svg";
import Pdf from "../../Images/pdf.svg";
import Excel from "../../Images/excel.svg";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import print from "../../Images/print.svg";
import pdf from "../../Images/pdf.svg";
import excel from "../../Images/excel.svg";
import ReactSVG from "react-svg";
import { Input } from "antd";
import ReactExport from "react-data-export";
import ReactToPrint from "react-to-print";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import Axios from "axios";
import { apiurl } from "../../App";
import StartHalf from "@material-ui/icons/StarHalf";
import StartFull from "@material-ui/icons/Star";
import "./ReviewReportMaster.css";
import Rating from "react-rating";
import back from "../../Images/rightarrow.svg";
import dateFormat from "dateformat";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import PrintData from "./printdata";
import { withTranslation } from 'react-i18next';
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
class NurseReviewReportMaster extends Component {
  constructor(props) {
    super(props)

    this.state = {
      is_show_report: false,
      openview: false,
      tabledata: [],
      tabledataApp: [],
      search: null,
      subsearch: null,
      totalValue: [],
      props_loading: false,
      nurse_id: 0,
      rating_from: 0,
      rating_to: 5,
      active_id: 0,
      nurse_name: "",
      sub_props_loading: false,
      rating_1: 0,
      rating_2: 0,
      rating_3: 0,
      rating_4: 0,
      rating_5: 0,
      open_view_data: [],
      rating_from: "",
      rating_to: "",
      thugs: "killer"
    }

  }

  componentDidMount() {
    this.GetReviewReport();
    this.intervalId = setInterval(() => {
      this.GetReviewReport();
    }, 120000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }

  GetReviewReport = () => {

    this.setState({ props_loading: true })
    var tabledata = [];
    Axios({
      method: "post",
      url: apiurl + "nurse/getNurseReviewReport",
      data: {
        vendorId: localStorage.getItem("nurse_vendor_id") || "5",
        rating_from: this.state.rating_from,
        rating_to: this.state.rating_to,
      },
    }).then((response) => {
      response.data.data.map((val, index) => {
        tabledata.push({
          nursename: val.nurse_name,
          gender: val.nurse_gender,
          age: val.nurse_age,
          experience: val.nurse_experience,
          nationality: val.nurse_nationality,
          rating:
            <Rating
              initialRating={val.nurse_review}
              readonly
              emptySymbol={<StartFull className="start_icon_half" />}
              fullSymbol={<StartFull className="start_icon_full" />}>
            </Rating>
          ,
          id: index,
        });
      });
      this.setState({
        tabledata: tabledata,
        totalValue: response.data.data,
        props_loading: false,
        // spinner:false
      });
    });
  };


  GetNurseAppReview = () => {
    let getDate = new Date();
    let currentDate = getDate.getFullYear() + "-" + ("0" + (getDate.getMonth() + 1)).slice(-2) + "-" + ("0" + getDate.getDate()).slice(-2);
    this.setState({ props_loading: true })
    var tabledatanurse = [];
    Axios({
      method: "post",
      url: apiurl + "nurse/getNurseAppReviewReport",
      data: {
        nurseId: 136,//this.state.nurse_id,
        currentDate: currentDate,
        rating_from: this.state.rating_from,
        rating_to: this.state.rating_to

      },
    }).then((response) => {
      console.log("nursecheck", response,);
      this.setState({
        rating_1: response.data.data.count_details.rating_1,
        rating_2: response.data.data.count_details.rating_2,
        rating_3: response.data.data.count_details.rating_3,
        rating_4: response.data.data.count_details.rating_4,
        rating_5: response.data.data.count_details.rating_5
      })
      response.data.data.data.map((val, index) => {
        tabledatanurse.push({
          patient: val.patient_name,
          gender: val.patient_gender,
          address: val.patient_address,
          nurse_name: val.nurse_name,
          nurseRating: val.rating,
          nursefeedback: val.feedback,
          // rating:
          //   <Rating
          //     value={val.start_rating}
          //     readonly
          //     emptySymbol={<StartFull className="start_icon_half" />}
          //     fullSymbol={<StartFull className="start_icon_full" />}>
          //   </Rating>


        });
      });
      console.log("develop", tabledatanurse);
      this.setState({
        thugs: "killer",
        tabledataApp: tabledatanurse,
        totalValue: response.data.data,
        props_loading: false,
        // spinner:false
      });
    });
  }

  GetReviewParticularReport = () => {

    this.setState({ sub_props_loading: true })
    var tabledata = [];
    Axios({
      method: "post",
      url: apiurl + "nurse/getParticularNurseReview",
      data: {
        nurseId: this.state.nurse_id,
        rating_from: this.state.rating_from,
        rating_to: this.state.rating_to
      },
    }).then((response) => {

      this.setState({
        rating_1: response.data.data.count_details.rating_1,
        rating_2: response.data.data.count_details.rating_2,
        rating_3: response.data.data.count_details.rating_3,
        rating_4: response.data.data.count_details.rating_4,
        rating_5: response.data.data.count_details.rating_5
      })
      response.data.data.data.map((val, index) => {
        tabledata.push({
          patientname: <div style={{ display: 'flex' }}>
            <img
              className="review_Avatar"
              alt="avatar-missing"
              src={val.patient_profile}
            />
            <div>  {val.patient_name} </div>
          </div>
          ,
          gender: val.patient_gender,
          age: val.patient_age,
          phone_number: val.patient_phone_no,
          address: val.patient_address,
          rating:
            <Rating
              initialRating={val.rating}
              readonly
              emptySymbol={<StartFull className="start_icon_half" />}
              fullSymbol={<StartFull className="start_icon_full" />}>
            </Rating>
          ,
          review: val.remarks,
          id: index,
        });
      });
      this.setState({
        open_view_data: tabledata,
        sub_props_loading: false,
        // spinner:false
      });
    });
  };

  searchChange = (e) => {
    if (this.state.is_show_report) {
      this.setState({
        subsearch: e.target.value,
      });
    }
    else {
      this.setState({
        search: e.target.value,
      });
    }

    this.setState({});
  };

  modelopen = (data, id) => {
    debugger;
    if (data === "view") {
      this.setState({
        nurse_id: this.state.totalValue[id]["nurse_id"],
        nurse_name: this.state.totalValue[id]["nurse_name"],
        is_show_report: true,
        rating_from: 0,
        rating_to: 5,
        active_id: 0,
        rating_1: 0,
        rating_2: 0,
        rating_3: 0,
        rating_4: 0,
        rating_5: 0,
        open_view_data: [],
        subsearch: null
      }, () => {
        this.GetNurseAppReview()
      });
    }
  };

  backToFirstTable = () => {

    this.setState({ is_show_report: false, nurse_id: 0, rating_from: 0, rating_to: 5, active_id: 0 })
  }

  changeRatingFilter = (type, from, to) => {

    if (this.state.active_id == type) {
      this.setState({ active_id: 0, rating_from: 0, rating_to: 5 }, () => {
        this.GetNurseAppReview()
      })
    }
    else {
      this.setState({ active_id: type, rating_from: from, rating_to: to }, () => {
        this.GetNurseAppReview()
      })
    }

  }
  h


  render() {
    const { Search } = Input;
    const { t } = this.props;
    const review_report_heading = ((localStorage.nurse_language == 'ar') ? [
      { id: "nursefeedback", label: t("review") },
      { id: "nurseRating", label: t("rating") },
      { id: "", label: "FeedBack" },
      { id: "nurse_name", label: "Nurse Name" },
      { id: "address", label: "Patient Address" },
      { id: "gender", label: "Gender" },
      { id: "patient", label: "To patient" },
      { id: "", label: "SNo" },
    ] : [
      { id: "", label: "SNo" },
      { id: "patient", label: "To patient" },
      { id: "gender", label: "Gender" },
      { id: "address", label: "Patient Address" },
      { id: "nurse_name", label: "NurseName" },
      { id: "nurseRating", label: t("rating") },
      { id: "nursefeedback", label: "Feedback" },
    ])

    // patient: val.patient_name,
    // gender: val.patient_gender,
    // address: val.patient_address,
    // nurse_name: val.nurse_name,
    // nurseRating: val.rating,
    // nursefeedback: val.feedback,


    const review_report_dashboard_heading = ((localStorage.nurse_language == 'ar') ? [
      { id: "review", label: t("review") },
      { id: "rating", label: t("rating") },
      { id: "", label: "FeedBack" },
      { id: "", label: "SNo" },
    ] : [
      { id: "", label: "SNo" },
      { id: "", label: "Feedback" },
      { id: "rating", label: t("rating") },
      { id: "review", label: t("review") },
    ])
    const Tabledatas = this.state.tabledata.filter((data) => {
      debugger;
      if (this.state.search === null)

        return data;
      else if (
        console.log("check")
        // (data.nursename !== null && data.nursename.toLowerCase().includes(this.state.search.toLowerCase()))
        //     ||
        // (data.gender != null &&
        //   data.gender
        //     .toLowerCase()
        //     .includes(this.state.search.toLowerCase())) ||
        // (data.age != null &&
        //   data.age.toString().includes(this.state.search.toString())) ||
        // (data.experience != null &&
        //   data.experience
        //     .toLowerCase()
        //     .includes(this.state.search.toLowerCase())) ||
        // (data.nationality != null &&
        //   data.nationality
        //     .toLowerCase()
        //     .includes(this.state.search.toLowerCase()))
      ) {
        return data;
      }
    });

    const subTabledatas = this.state.tabledataApp.filter((data) => {
      if (this.state.subsearch === null) return data;
      else if (
        console.log("checkin")
        // (data.patientname !== null &&
        //   data.patientname
        //     .toLowerCase()
        //     .includes(this.state.subsearch.toLowerCase())) ||
        // (data.gender != null &&
        //   data.gender
        //     .toLowerCase()
        //     .includes(this.state.subsearch.toLowerCase())) ||
        // (data.age != null &&
        //   data.age.toString().includes(this.state.subsearch.toString())) ||
        // (data.experience != null &&
        //   data.experience
        //     .toLowerCase()
        //     .includes(this.state.subsearch.toLowerCase())) ||
        // (data.nationality != null &&
        //   data.nationality
        //     .toLowerCase()
        //     .includes(this.state.subsearch.toLowerCase())) ||
        // (data.review != null &&
        //   data.review
        //     .toLowerCase()
        //     .includes(this.state.subsearch.toLowerCase()))
      ) {
        return data;
      }
    });
    return (
      <Paper>
        <div>
          <div className="title_dashboard">

            <div className="title_header" style={{ textTransform: 'uppercase' }}>
              {this.state.is_show_report && <span
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  marginRight: "15px",
                }}
                onClick={() => this.backToFirstTable()}
              >
                <ReactSVG src={back} />
              </span>}
              <span>

                <Link to="/Home/review_report">{t("review_report")}
                </Link>

              </span> <span> </span>
              <span style={{color:'black'}}>NURSE FEEDBACK {this.state.is_show_report && " - " + this.state.nurse_name}</span>
            </div>


            <div style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
              <Search
                placeholder={t('search')}
                onSearch={(value) => console.log(value)}
                style={{ width: 150, direction:this.props.i18n.language == "ar"? "rtl": ""}}
                className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} mr-2 ml-2`}
                onChange={(e) => this.searchChange(e)}
                value={this.state.is_show_report ? this.state.subsearch : this.state.search}
              />
              <div className={`${this.props.i18n.language == 'ar' ? "order_0" : ""} icon_head`}>
                <ReactSVG
                  src={pdf}
                  //  onClick={this.generatepdf}
                  style={{ marginRight: "15px", marginLeft: "15px" }}
                />
                {this.state.tabledata.length === 0 ? (
                  <ReactSVG src={excel} style={{ marginRight: "15px" }} />
                ) : (
                  <ExcelFile
                    filename={"TotalNursesHired"}
                    element={
                      <ReactSVG src={excel} style={{ marginRight: "15px" }} />
                    }
                  >
                    <ExcelSheet
                      //  dataSet={multiDataSet}
                      name="Review Report"
                    />
                  </ExcelFile>
                )}
                <ReactToPrint
                  trigger={() => <ReactSVG src={print} />}
                  content={() => this.componentRef}
                />
                <div style={{ display: "none" }}>
                  <PrintData
                    printTableData={this.state.tabledata}
                    ref={(el) => (this.componentRef = el)}
                  />
                </div>
              </div>
            </div>
          </div>
          {this.state.is_show_report &&
            <div>
              <div className="nurse_dashboard_buttons_wrap">
                <Card
                  className={`${this.state.active_id == 0 ? "card-normal " : (this.state.active_id == 1 ? "card-hghlght " : "card-deselect ")} review_report_button1 review_report_button_common_styles`} onClick={() => { this.changeRatingFilter(1, '0', '1') }}
                >
                  <p className="nurse_button_text"> 0-1 &nbsp;&nbsp;  <StartFull style={{ fill: 'white !important' }} /> &nbsp;&nbsp; {t("rating")} </p>
                  <div className="divider_container">
                    <div className="divider_1px"></div>
                  </div>
                  <div className="nurse_dash_numeric_wrap">
                    <p className="nurse_dash_numeric_value">
                      {this.state.rating_1 ? this.state.rating_1 : 0}
                    </p>
                  </div>
                </Card>
                <Card
                  className={`${this.state.active_id == 0 ? "card-normal " : (this.state.active_id == 2 ? "card-hghlght " : "card-deselect ")} review_report_button2 review_report_button_common_styles`}
                  onClick={() => { this.changeRatingFilter(2, '1.1', '2') }}
                >
                  <p className="nurse_button_text"> 1.1-2 &nbsp;&nbsp;  <StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /> &nbsp;&nbsp; {t("rating")} </p>
                  <div className="divider_container">
                    <div className="divider_1px"></div>
                  </div>
                  <div className="nurse_dash_numeric_wrap">
                    <p className="nurse_dash_numeric_value">
                      {this.state.rating_2 ? this.state.rating_2 : 0}
                    </p>
                  </div>
                </Card>
                <Card
                  className={`${this.state.active_id == 0 ? "card-normal " : (this.state.active_id == 3 ? "card-hghlght " : "card-deselect ")} review_report_button3 review_report_button_common_styles`}
                  onClick={() => { this.changeRatingFilter(3, '2.1', '3') }}
                >
                  <p className="nurse_button_text"> 2.1-3 &nbsp;&nbsp;  <StartFull style={{ fill: 'white !important' }} /> <StartFull style={{ fill: 'white !important' }} /> <StartFull style={{ fill: 'white !important' }} /> &nbsp;&nbsp; {t("rating")} </p>
                  <div className="divider_container">
                    <div className="divider_1px"></div>
                  </div>
                  <div className="nurse_dash_numeric_wrap">
                    <p className="nurse_dash_numeric_value">
                      {this.state.rating_3 ? this.state.rating_3 : 0}
                    </p>
                  </div>
                </Card>
                <Card
                  className={`${this.state.active_id == 0 ? "card-normal " : (this.state.active_id == 4 ? "card-hghlght " : "card-deselect ")} review_report_button4 review_report_button_common_styles`}
                  onClick={() => { this.changeRatingFilter(4, '3.1', '4') }}
                >
                  <p className="nurse_button_text"> 3.1-4 &nbsp;  <StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /> &nbsp; {t("rating")} </p>
                  <div className="divider_container">
                    <div className="divider_1px"></div>
                  </div>
                  <div className="nurse_dash_numeric_wrap">
                    <p className="nurse_dash_numeric_value">
                      {this.state.rating_4 ? this.state.rating_4 : 0}
                    </p>
                  </div>
                </Card>
                <Card
                  className={`${this.state.active_id == 0 ? "card-normal " : (this.state.active_id == 5 ? "card-hghlght " : "card-deselect ")} review_report_button5 review_report_button_common_styles`}
                  onClick={() => { this.changeRatingFilter(5, '4.1', '5') }}
                >
                  <p className="nurse_button_text"> 4.1-5 &nbsp; <StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /><StartFull style={{ fill: 'white !important' }} /> &nbsp; {t("rating")} </p>
                  <div className="divider_container">
                    <div className="divider_1px"></div>
                  </div>
                  <div className="nurse_dash_numeric_wrap">
                    <p className="nurse_dash_numeric_value">
                      {this.state.rating_5 ? this.state.rating_5 : 0}
                    </p>
                  </div>
                </Card>
              </div>
              {/* <div className="review_report_div">
                <Tablecomponent
                  heading={review_report_heading}
                  rowdata={subTabledatas.length === 0 ? [] : subTabledatas}
                  props_loading={this.state.sub_props_loading}
                  DeleteIcon="close"
                  EditIcon="close"
                  HistoryIcon="close"
                  GrandTotal="close"
                  LocationOnIcon="close"
                  VisibilityIcon="close"
                  actionclose="close"
                  t={t}
                  i18n={this.props.i18n}
                />
              </div> */}
              <div className="review_report_dashboard_div">
                <Tablecomponent
                  heading={review_report_heading}
                  rowdata={subTabledatas.length === 0 ? [] : subTabledatas}
                  props_loading={this.state.props_loading}
                  podss={this.state.thugs}

                  DeleteIcon="close"
                  EditIcon="close"
                  HistoryIcon="close"
                  GrandTotal="close"
                  LocationOnIcon="close"
                  modelopen={(e, id) => this.modelopen(e, id)}
                  t={t}
                  i18n={this.props.i18n}
                />
              </div>

            </div>

          }
          {this.state.is_show_report == false &&



            <div className="review_report_dashboard_div">
              <Tablecomponent
                heading={review_report_dashboard_heading}
                rowdata={Tabledatas.length === 0 ? [] : Tabledatas}
                props_loading={this.state.props_loading}
                DeleteIcon="close"
                EditIcon="close"
                HistoryIcon="close"
                GrandTotal="close"
                LocationOnIcon="close"
                modelopen={(e, id) => this.modelopen(e, id)}
                t={t}
                i18n={this.props.i18n}
              />
            </div>
          }
        </div>
      </Paper>
    )
  }
}
export default withTranslation()(NurseReviewReportMaster);
