import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { MdDone } from "react-icons/md";
import "./Stepper.css";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import moment from 'moment';

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(117, 232, 155) 0%,rgb(117, 232, 155 ) 50%,rgb(117, 232, 155 ) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(117, 232, 155 ) 0%,rgb(117, 232, 155 ) 50%,rgb(117, 232, 155 ) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: "35px",
    height: "35px",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg,rgb(117, 232, 155 ) 0%, rgb(117, 232, 155 ) 50%, rgb(117, 232, 155 ) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(117, 232, 155 )  0%, rgb(117, 232, 155 )  50%, rgb(117, 232, 155 ) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: (
      <div className="icon_settings">
        <MdDone />
      </div>
    ),
    2: (
      <div className="icon_settings">
        <MdDone />
      </div>
    ),
    3: (
      <div className="icon_settings">
        <MdDone />
      </div>
    ),
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({}));

export default function CustomizedSteppers(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (props.businessDays?.deal_approval_time) {
      setActiveStep(1);
    }
  }, [props.businessDays])

  function getSteps() {
    return [
      {
        label: "Booked",
        description: '',
      },
      {
        label: "Approved",
        description: '',
      },
      {
        label: "Deal Activated",
        description: "",
      },
    ];
  }

  return (
    <div className="steeper_Container">
      <Stepper activeStep={activeStep} orientation="vertical">
        {getSteps().map((step) => (
          <Step key={step.label} className="booked_text_edit">
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              style={{ fontSize: "16px" }}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <div className="stepper___days">
        <label>{props.businessDays.business_days} Business Days</label>
      </div>
    </div>
  );
}
