import React from 'react';
// import VideoSrc from '../../assets/videos/example_video.mp4';
import VideoSrc from '../../assests/videos/example_video.mp4';

class VideoPlayerPopup extends React.Component {
  
    constructor(props) {
       super(props);
        this.state = {
            popupOpen: false,
        }
    }

    handlePopupOpenClose = (isOpen) => {
        this.setState({ popupOpen: isOpen });
    }

    render = () => {
        return (
            <div>
                {/* {VideoSrc && (<video src={VideoSrc} type="video/mp4" controls style={{width:"100%", height:"300px !important"}} />)} */}
                {VideoSrc && (<video src={''} type="video/mp4" controls style={{width:"100%", height:"300px !important"}} />)}
              </div>
        )
    }
}

export default VideoPlayerPopup;