import React, { Component } from "react";
import "antd/dist/antd.css";
import "./AdvertisementMaster.css";
import dateFormat from "dateformat";
import Paper from "@material-ui/core/Paper";
import BookingDetails from "./BookingDetails";
import { apiurl } from "../../App";
import Axios from "axios";
import AdvertiseList from "./AdvertiseList";
import { withTranslation } from 'react-i18next';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import moment from "moment";


const current_date = dateFormat(new Date(), "dd mmm yyyy");

class AdvertisementMaster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: "rrr",
      data: [],
      ClinicList: null,
      clinicId: null,
      active_tab: 1,
      ad_details: [],
      edit_booking_data: {},
      paymentDataObj: null,
      isShowPaymentModel: false,
    };
  }

  componentWillMount() {
    let data = JSON.parse(localStorage.getItem("paymentObject"));
    this.setState({ paymentDataObj: data });

    if (data != null && localStorage.getItem("cardType") == "knet") {
      this.setState({ isShowPaymentModel: true });
    }
    else {
      this.setState({ isShowPaymentModel: false });
    }

    if(localStorage.getItem("contentTypeData")=="AdvertisementNotify"){
      this.state.active_tab=2;
      localStorage.removeItem('contentTypeData');
    }else{
      this.state.active_tab=1;
    }
  }

  changeDynamic = (data, key) => {
    if (key == "clinicId") {
      this.setState({ clinicId: data });
    }
    this.setState({ [key]: data });
  };

  edit_details = (data) => {
    this.setState({ active_tab: 1, edit_booking_data: data });
  };

  Changetab = (data) => {
    this.setState({ active_tab: data, edit_booking_data: {} });
  };

  componentWillUnmount() {
    this.setState({ isShowPaymentModel: false });
    this.setState({ paymentDataObj: null });
    localStorage.setItem("paymentObject", null);
  };

  handleClosePaymentFailedTypeModel = () => {
    this.setState({ isShowPaymentModel: false });
    this.setState({ paymentDataObj: null });
    localStorage.setItem("paymentObject", null);
  };

  handleClosePaymentTypeModel = () => {
    let logindata = JSON.parse(localStorage.getItem("login_data"));
    let Object = {
      email: logindata.email,
      username: logindata.user_name,
      amount: this.state.paymentDataObj.amt,
      paymentid: this.state.paymentDataObj.paymentid,
      transactionid: this.state.paymentDataObj.tranid,
      dateTime: moment(new Date()).format('DD-MMM-YY, hh:mm a'),
      title: "Knet Payment Success Details"
    }

    Axios({ method: "post", url: apiurl + "admin/knetPaymentSuccessDetailsMail", data: Object }).then((response) => {
      this.setState({ isShowPaymentModel: false });
      this.setState({ paymentDataObj: null });
      localStorage.setItem("paymentObject", null);
    })
      .catch((error) => {
        this.setState({ isShowPaymentModel: false });
        this.setState({ paymentDataObj: null });
        localStorage.setItem("paymentObject", null);
      });
  };

  render() {
    const { t } = this.props;
    return (
      <div className={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} `}>
        <Paper>
          <div className="dashboard_header head_font" style={{ display: "flex" }}>
            <div style={{ width: "50%", order: (this.props.i18n.language == 'ar' ? 1 : 0), textAlign: (this.props.i18n.language == 'ar' ? 'end' : 'left') }}>
              <div className="dashboard_title">{t('advertisement_booking_head')}</div>
            </div>
            <div
              style={{ width: "50%", justifyContent: (this.props.i18n.language == 'ar' ? 'left' : "end"), direction: (this.props.i18n.language == 'ar' ? 'rtl' : ""), display: "flex", order: (this.props.i18n.language == 'ar' ? 0 : 1) }}
            >
              <div
                className={
                  "advertisementtab " +
                  (this.state.active_tab == 1 ? "advertisementtab_active" : "")
                }
                onClick={(e) => {
                  this.Changetab(1);
                }}
              >
                {" "}
                {t('create_ad')}{" "}
              </div>
              <div
                className={
                  "advertisementtab " +
                  (this.state.active_tab == 2 ? "advertisementtab_active" : "")
                }
                onClick={(e) => {
                  this.Changetab(2);
                }}
              >
                {" "}
                {t('ad_list')}{" "}
              </div>
            </div>
          </div>
          {this.state.active_tab == 1 && (
            <BookingDetails
              refs="bookingdetail"
              generateAlert={this.props.generateAlert}
              userId={this.props.userId}
              clinicId={this.state.clinicId}
              edit_booking_data={this.state.edit_booking_data}
              t={t}
              i18n={this.props.i18n}
            />
          )}
          {this.state.active_tab == 2 && (
            <AdvertiseList
              ad_details={this.state.ad_details}
              getAdvertiseList={this.getAdBooking}
              userId={this.props.userId}
              generateAlert={this.props.generateAlert}
              edit_details={this.edit_details.bind()}
              t={t}
              i18n={this.props.i18n}
            />
          )}
        </Paper>

        <Dialog
          className="Dialogmodal ticket_table_success"
          open={this.state.isShowPaymentModel}
          aria-labelledby="customized-dialog-title"
          maxWidth={"sm"}
          fullWidth={true}
          disableBackdropClick={true}
        >
          <div>
            <div className="payment_type_header">Payment {this.state.paymentDataObj?.result == "CAPTURED" ? "Success" : "Failed"} Details</div>
            <div className="popup_content">
              <ul>
                <li><b>Payment Amount : </b> KWD  {this.state.paymentDataObj?.amt}</li>
                <li><b>Payment Id: </b> {this.state.paymentDataObj?.paymentid}</li>
                <li><b>Transaction Id: </b> {this.state.paymentDataObj?.tranid}</li>
                <li><b>Date & Time: </b> {moment(new Date()).format('DD-MMM-YY, hh:mm a')}</li>
                <li><b>Payment Status : </b><span className="payment_status"> {this.state.paymentDataObj?.result == "CAPTURED" ? "Success" : "Failed"}</span></li>
              </ul>
            </div>

            <div
              className="contract_approve_btn_div"
              style={{ marginBottom: "3rem" }}
            >
              <Button
                className="ticketSubmit"
                style={{ marginTop: "20px" }}
                onClick={() => {
                  this.state.paymentDataObj?.result == "CAPTURED" ? this.handleClosePaymentTypeModel() : this.handleClosePaymentFailedTypeModel();
                }}
              >
                {" "}
                Ok{" "}
              </Button>
            </div>
          </div>
        </Dialog>

      </div>
    );
  }
}

export default withTranslation()(AdvertisementMaster);
