import React from "react";
import CustomerHistoryTable from "./CustomerHistoryTable";
import NurseCustomerHistoryTable from "./NurseCustomerHistoryTable";
import { withTranslation } from 'react-i18next';

class CustomerHistoryMaster extends React.Component {
  state = {
    customerHistory: false,
  };

  // call back func to CustomerHistoryTable
  DetailedCustomerHistory = (id, customerName) => {
    this.setState({
      customerName,
      customerId: id,
      customerHistory: true,
    });
  };
  backToFirstTable = () => {
    this.setState({
      customerHistory: false,
    });
  };
  render() {
    const { t } = this.props;

    return (
      <>
        {this.state.customerHistory === false ? (
          <CustomerHistoryTable
            check="fjk"
            DetailedCustomerHistory={(id, customerName) =>
              this.DetailedCustomerHistory(id, customerName)
            }
            t={t}
            i18n={this.props.i18n}
          />
        ) : (
          <NurseCustomerHistoryTable
            customerName={this.state.customerName}
            customerId={this.state.customerId}
            backToFirstTable={() => this.backToFirstTable()}
            t={t}
            i18n={this.props.i18n}
          />
        )}
      </>
    );
  }
}
export default withTranslation()(CustomerHistoryMaster);
