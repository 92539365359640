import React from "react";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import "./NurseServiceCancellationTable.css";
import Axios from "axios";
import { apiurl } from "../../App";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Paper from "@material-ui/core/Paper";
import print from "../../Images/print.svg";
import pdf from "../../Images/pdf.svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import excel from "../../Images/excel.svg";
import ReactSVG from "react-svg";
import ReactExport from "react-data-export";
import ReactToPrint from "react-to-print";
import PrintData from "./printdata";
import { Input } from "antd";
import dateformat from "dateformat";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import Filter from '../../Images/filter.svg';
import AdvanceFilter from "../AdvanceFillter/AdvanceFilter";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
var dateFormat = require("dateformat");
var now = new Date();
var current_day = dateFormat(now, "yyyy-mm-dd");
var moment = require("moment");
class NurseServiceCancellationTable extends React.Component {
  state = {
    openview: false,
    tabledata: [],
    search: null,
    props_loading: true,
    wk_Mn_Yr_Full_Data: [],
    spinner: false,
    selected_nurseId:0,
    selected_working_hrs:"",
    open_filter:false,
    from_date:dateformat(new Date(), "yyyy-mm-dd"),
    to_date:dateformat(new Date(), "yyyy-mm-dd")
  };
  modelopen = (data) => {
    if (data === "view") {
      this.setState({ openview: true });
    } else if (data === "edit") {
      this.setState({ editopen: true });
    }
  };
  closemodal = () => {
    this.setState({ openview: false, editopen: false });
  };
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.setState({});
  };
  // PDF FUNCTION
  generatepdf = () => {
    if (this.state.tabledata.length === 0) {
      alert("Table data is empty");
    } else {
      // alert("ee")
      const doc = new jsPDF("a3");
      var bodydata = [];
      this.state.tabledata.map((data, index) => {
        bodydata.push([
          index + 1,
          data.customer,
          data.nursename,
          data.duty_hours,
          data.cancellleddate,
          data.time,
        ]);
      });
      doc.autoTable({
        beforePageContent: function (data) {
          doc.text("Canceled Appointments", 15, 23); // 15,13 for css
        },
        margin: { top: 30 },
        showHead: "everyPage",
        theme: "grid",
        head: [
          [
            "S.No",
            "Member",
            "Nurse",
            "Duty Hours",
            "Canceled on",
          ],
        ],
        body: bodydata,
      });
      doc.save("CancelledAppointments.pdf");
    }
  };
  componentDidMount = () => {
    this.getmethod();

    this.intervalId = setInterval(() => {
      this.getmethod();
    }, 120000);
  };

  	
componentWillUnmount() {
  // Clear the interval when the component is unmounted to avoid memory leaks
  clearInterval(this.intervalId);
}

  getmethod = (rangeday) => {
    function formatTimeShow(h_24) {
      var h = Number(h_24.substring(0, 2)) % 12;
      if (h === 0) h = 12;
      return (
        (h < 10 ? "0" : "") +
        h +
        ":" +
        h_24.substring(3, 5) +
        (Number(h_24.substring(0, 2)) < 12 ? " AM" : " PM")
      );
    }
    this.setState({
      props_loading: true,
    });
    Axios({
      method: "post",
      url: apiurl + "Nurse/getnursecancelappointmentweb",
      data: {
        nursevendorId: localStorage.nurse_vendor_id
          ? localStorage.nurse_vendor_id
          : 0,
        fromDate: this.state.from_date,
        toDate: this.state.to_date,
        nurseId:this.state.selected_nurseId,
        working_hrs:this.state.selected_working_hrs,
        period: "Day",
      },
    }).then((response) => {
      var tabledata = [];
      response.data.data &&
        response.data.data.map((val) => {
          tabledata.push({
            customer: val.PatientName,
            nursename: val.Nursename,
            duty_hours: val.duty_hrs,
            cancellleddate: moment(val.CancelDate).format("DD-MMM-YY"),
            // fromdate:moment(val.from_date).format("DD-MMM-YY"),
            id: val.id,
          });
        });
      this.setState({
        tabledata: tabledata,
        props_loading: false,
      });
    });
  };
  // DATE RANGE PICKER FUNCTION
  dayReport = (data) => {
    function formatTimeShow(h_24) {
      var h = Number(h_24.substring(0, 2)) % 12;
      if (h === 0) h = 12;
      return (
        (h < 10 ? "0" : "") +
        h +
        ":" +
        h_24.substring(3, 5) +
        (Number(h_24.substring(0, 2)) < 12 ? " AM" : " PM")
      );
    }

    var startdate = dateformat(data[0].startDate, "yyyy-mm-dd");
    var enddate = dateformat(data[0].endDate, "yyyy-mm-dd");
    this.setState({ spinner: true ,from_date:startdate,to_date: enddate});
    var self = this;
    Axios({
      method: "post",
      url: apiurl + "Nurse/getnursecancelappointmentweb",
      data: {
        nursevendorId: localStorage.nurse_vendor_id
          ? localStorage.nurse_vendor_id
          : 0,
        fromDate: startdate,
        toDate: enddate,
        period: "Day",
        nurseId:this.state.selected_nurseId,
        working_hrs:this.state.selected_working_hrs
      },
    }).then((response) => {
      var tabledata = [];
      var tableDatafull = [];
      response.data.data &&
        response.data.data.map((val, index) => {
          tabledata.push({
            customer: val.PatientName,
            nursename: val.Nursename,
            duty_hours:  val.duty_hrs,
            cancellleddate: moment(val.CancelDate).format("DD-MMM-YY"),
            id: index,
          });
          tableDatafull.push(val);
        });
      this.setState({
        tabledata: tabledata,
        wk_Mn_Yr_Full_Data: tableDatafull,
        props_loading: false,
        spinner: false,
      });
    });
  };
  // PRINT FUNCTION
  generateprint = () => {
    this.setState({
      printComOpen: true,
    });
  };


  
  openAdvanceFilter = () =>{
    this.setState({open_filter:true})
  }
  closeAdvanceFilter = () =>{
    this.setState({open_filter:false})
  }
  
  submitAdvanceFilter = (selected_nurseId,selected_working_hrs) =>{
    this.setState({selected_nurseId,selected_working_hrs,open_filter:false}, ()=>{
     this.getmethod();
    })
 }


  render() {
    const { Search } = Input;
    const { t } = this.props;

    const heading= ((localStorage.nurse_language=='ar') ?[
      { id: "cancellleddate", label: t("tbl_head_canceled_on") },
      { id: "duty_hours", label: t("tbl_head_duty_hours") },
      { id: "nursename", label: t("tbl_head_nurse") },
      { id: "customer", label: t('tbl_head_member') },
      { id: "", label: t("tbl_head_s_no") },
    ]:[
      { id: "", label: t("tbl_head_s_no") },
      { id: "customer", label: t('tbl_head_member') },
      { id: "nursename", label: t("tbl_head_nurse") },
      { id: "duty_hours", label: t("tbl_head_duty_hours") },
      { id: "cancellleddate", label: t("tbl_head_canceled_on") },
    ])
    const Tabledatas = this.state.tabledata.filter((data) => {
      if (this.state.search === null) return data;
      else if (
        (data.customer !== null &&
          data.customer
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.nursename != null &&
          data.nursename
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.duty_hours != null &&
          data.duty_hours.toString().includes(this.state.search.toString())) ||
        (data.cancellleddate != null &&
          data.cancellleddate
            .toLowerCase()
            .includes(this.state.search.toLowerCase()))
      ) {
        return data;
      }
    });
    // EXCEL FUNCTION
    var multiDataSetbody = [];
    this.state.tabledata.map((xldata, index) => {
      if (index % 2 !== 0) {
        multiDataSetbody.push([
          { value: index + 1, style: { alignment: { horizontal: "center" } } },
          { value: xldata.customer },
          { value: xldata.nursename },
          { value: xldata.duty_hours },
          { value: xldata.cancellleddate },
        ]);
      } else {
        multiDataSetbody.push([
          {
            value: index + 1,
            style: {
              alignment: { horizontal: "center" },
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.customer,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.nursename,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.duty_hours,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.cancellleddate,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          
        ]);
      }
    });
    const multiDataSet = [
      {
        columns: [
          {
            title: "S.No",
            width: { wpx: 35 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Member",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Nurse",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Duty Hours",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Canceled on",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          
        ],
        data: multiDataSetbody,
      },
    ];
    return (
      <div>
        <Paper>
          <div className="title_dashboard">
            <div className="title_header"> {t('canceled_bookings_head')}</div>
            <div
              style={{
                fontSize: "16px",
                display: "flex",
                alignItems: "center",
              }}
              className="range_picker_reduce"
            >
              <div className={`${this.props.i18n.language=='ar'?"order_3":""} `}>
                  <DateRangeSelect
                    dynalign={"dynalign"}
                    rangeDate={(item) => this.dayReport(item)}
                    t={t}
                    i18n={this.props.i18n}
                  />
              </div>
              
             
              <img src={Filter} className={`${this.props.i18n.language=='ar'?"order_2":""} `} style={{height:'22px',marginRight:'20px'}} onClick={this.openAdvanceFilter}/>

              <Search
                placeholder={t('search')}
                onSearch={(value) => console.log(value)}
                style={{ width: 150,direction:this.props.i18n.language=="ar"?"rtl":"" }}
                onChange={(e) => this.searchChange(e)}
                className={`${this.props.i18n.language=='ar'?"order_1":""} `}
              />
              <div className={`${this.props.i18n.language=='ar'?"order_0":""} icon_head`}>
                <ReactSVG
                  src={pdf}
                  onClick={this.generatepdf}
                  style={{ marginRight: "15px", marginLeft: "15px" }}
                />
                {this.state.tabledata.length === 0 ? (
                  <ReactSVG src={excel} style={{ marginRight: "15px" }} />
                ) : (
                  <ExcelFile
                    filename={"CancelledAppointments"}
                    element={
                      <ReactSVG src={excel} style={{ marginRight: "15px" }} />
                    }
                  >
                    <ExcelSheet
                      dataSet={multiDataSet}
                      name="Canceled Appointments"
                    />
                  </ExcelFile>
                )}
                <ReactToPrint
                  trigger={() => <ReactSVG src={print} />}
                  content={() => this.componentRef}
                />
                <div style={{ display: "none" }}>
                  <PrintData
                    printTableData={this.state.tabledata}
                    ref={(el) => (this.componentRef = el)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>
        <div className="canceled_appointment_table_div">
            <Tablecomponent
              heading={heading}
              rowdata={Tabledatas.length === 0 ? [] : Tabledatas}
              props_loading={this.state.props_loading}
              DeleteIcon="close"
              EditIcon="close"
              VisibilityIcon="close"
              modelopen={(e) => this.modelopen(e)}
              HistoryIcon="close"
              LocationOnIcon="close"
              actionclose="close"
              t={t}
              i18n={this.props.i18n}
            />
        </div>
        
        <AdvanceFilter open={this.state.open_filter}
          onClose={this.closeAdvanceFilter}
          submitAdvanceFilter={this.submitAdvanceFilter}
          t={t}
          i18n={this.props.i18n}
          />
      </div>
    );
  }
}
export default NurseServiceCancellationTable;
