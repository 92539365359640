import React, { Component } from "react";
import Axios from "axios";
import { apiurl } from "../../App";
import Button from "@material-ui/core/Button";

export default class ContractApproval extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    rejectContract= () =>{
      Axios({
        method: "POST",
        url: apiurl + "admin/rejectVendorContract",
        data: {
          "contract_id":this.props.contract_id
        },
      })
        .then((response) => {
          if(response.data.data[0]["ErrorCode"]=="9999")
          {
            this.props.contractChange(false)
          }
           
        })
    }

    acceptContract ()
    {
      this.props.contractChange(true)
    }

    render()
    {
      const {t}= this.props
      return(
        <div className="contract_approval_popup">
            <div>
                {/* <p style={{width:'100%',fontSize:'14px', float: this.props.i18n.language=='ar'?'right':'left', display: this.props.i18n.language=='ar'?'flex':'block', justifyContent: this.props.i18n.language=='ar'?'end':''}}> 
                  <span style={{order:(this.props.i18n.language=='ar'?2:0)}}>{t('commission_percentage')}</span>
                  <span style={{order:1}}> : </span> 
                  <span style={{order:(this.props.i18n.language=='ar'?0:2)}}>{this.props.commission}</span> 
                </p> */}
                <textarea value={this.props.contract_terms} rows="15" readOnly style={{width:'100%'}}></textarea>
            </div>
            <div style={{marginTop:'5rem',display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <Button
                        className="reject_btn"
                        onClick={() =>
                          this.rejectContract()
                        }
                      > {t('decline')}
                  </Button>
                  <Button
                      className="accept_btn"
                      onClick={()=>{
                         this.acceptContract()
                      }}
                    > {t('accept')}                  
                </Button>
            </div>
        </div>
      )
    }
}