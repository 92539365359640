import React, { useState, useEffect } from 'react';

import offlineimage from "./assests/images/offline_image.jpg"

const OnlineStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [pingTime, setPingTime] = useState(null);
    const [internetLimitAlert, setInternetLimitAlert] = useState(false);


    useEffect(() => {
        const handleOnline = () => {
            if (navigator.connection) {
                if (navigator.connection.downlink < 3) {
                    setPingTime(navigator.connection.downlink);
                    setInternetLimitAlert(true);
                }
                // console.log(`Effective network type: ${navigator.connection.effectiveType}`);
                // console.log(`Downlink Speed: ${navigator.connection.downlink}Mb/s`);
                // console.log(`Round Trip Time: ${navigator.connection.rtt}ms`);
            } else {
                console.log('Navigator Connection API not supported');
            }
            setIsOnline(true);
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    const setwifiSpeed = (speed) => {
        alert(speed);
    }

    return (
        <div>
            {internetLimitAlert &&
                <div className='offlinemsg_body'>
                    <div className='offlinemsg_content'>
                        <div className='offlinemsg_body_img'>
                            <img src={offlineimage} />
                        </div>
                        <h2>Bad Internet connection Please check your internet.</h2>
                    </div>
                </div>
            }
            {!isOnline &&
                <div className='offlinemsg_body'>
                    <div className='offlinemsg_content'>
                        <div className='offlinemsg_body_img'>
                            <img src={offlineimage} />
                        </div>
                        <h2>You are offline. Please check your internet connection.</h2>
                    </div>
                </div>
            }
        </div>
    );
};

export default OnlineStatus;
