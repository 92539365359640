import React, { Component } from "react";
import Paper from "@material-ui/core/Paper";
import Moment from "react-moment";
import print from "../../Images/print.svg";
import pdf from "../../Images/pdf.svg";
import excel from "../../Images/excel.svg";
import  ReactSVG  from 'react-svg';
import { Input } from "antd";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import i18n from "../../i18n/i18n";
import { withTranslation } from 'react-i18next';

import NurseServiceCancellationTable from "./NurseServiceCancellationTable";

class NurseServiceCancellationHeader extends Component {
  render() {
    const { Search } = Input;
    const { t } = this.props;
    return (
      <div>
         <Paper>
          <NurseServiceCancellationTable t={t} i18n={this.props.i18n}/>
        </Paper>
      </div>
    );
  }
}

export default withTranslation()(NurseServiceCancellationHeader);