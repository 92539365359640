
import React from "react";
import TickMark from '../../Images/Group23311.svg';
import Alert from '../../Images/alert.svg';
import './ShowNotification.css';
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, withStyles } from "@material-ui/core/styles";



export default class ShowNotification extends React.Component {

    constructor(props) {
        super(props);
    }


    render ()
    {
        const { t } = this.props; 
        return(
        <Dialog
         className={`Dialogmodal notification_dialogue`}
          // onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.visible}
          fullWidth={true}
          disableBackdropClick={true}
          maxWidth={"xs"}

        >
            <div>
                <div style={{marginTop:'3rem',textAlign:'center'}}> 
                    {this.props.issuccess  && this.props.issuccess != "imageRequired" && <img src={TickMark} className="common_success_tick_mark"/>}
                    {this.props.issuccess == "imageRequired" && <img src={Alert} className="common_success_tick_mark"/>}
                    {!this.props.issuccess && this.props.issuccess != "imageRequired" && 
                    <img src={Alert} style={{color:'#ff0068'}} className="common_success_tick_mark"/> }

                </div>
                {this.props.issuccess  && this.props.issuccess != "imageRequired" &&  <div  className="success_label">{t("success_head")} </div> }
                {this.props.issuccess == "imageRequired" && <div  className="success_label1">{t('alert_head')} </div> }
                {!this.props.issuccess && this.props.issuccess != "imageRequired" &&  <div  style={{marginTop:'2rem',textAlign:'center',marginBottom:'1rem',fontSize:'20px',color:'#ff0068'}}>{t('alert_head')} </div> }
                {this.props.successmsg == "This Nurse is already Booked. Cannot Delete." ?
                 <div style={{marginTop:'1rem',textAlign:'center',fontSize:'16px',color:'black',
                 width:'80%',margin: 'auto',paddingTop: '20px'}}> {this.props.successmsg}</div> :
                <div style={{marginTop:'1rem',textAlign:'center',fontSize:'16px',color:'black'}}> {this.props.successmsg}</div> }
                <div style={{marginBottom:'3rem',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <Button className="CommonSuccessSubmit" onClick={()=>{this.props.handleCloseSuccess()}}> {t('ok')} </Button>
                </div>
            </div>
        </Dialog>
        )
        
    }
}