import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Map from "./Map";
import { GoogleMap, withScriptjs, withGoogleMap } from "react-google-maps";

class NurseLocationModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const WrappedMap = withScriptjs(Map);

    return (
      <div>
        <Grid container style={{ padding: "0px" }}>
          <Grid
            item
            sm={10}
            md={3}
            style={{ backgroundColor: "dimgrey", color: "white" }}
          >
            <div
              style={{
                fontSize: "16px",
                marginTop: "60px",
                marginLeft: "10px",
              }}
            >
              <div>{this.props.locationdata.Nursename}</div>
              <div>
                {this.props.locationdata.age} yrs ,{" "}
                {this.props.locationdata.nurseGender}
              </div>
              <div>{this.props.locationdata.nationality}</div>
              <div>
                {this.props.locationdata.nurseExperience} yrs Experience
              </div>
              <div>{this.props.locationdata.mobileno}</div>
            </div>
          </Grid>
          <Grid item sm={10} md={7}>
            <div className="map_height">
              <WrappedMap
                // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCUOFpADz8oQmcJPFDbZjUmBMGCLHtOVQQ"
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB9cTfn_1pNmKa5u19FPFZPMcWD36UhyZo"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `500px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default NurseLocationModal;
