import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import "./Drawerpage.css";
import { Dropdown } from "react-bootstrap";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import bell from "../../Images/ar_bell.png";
import Logo from "../../Images/Logo.svg";
import dashboard from "../../Images/dashboard.svg";
import nursehired from "../../Images/nursehired.svg";
import idlenurse from "../../Images/idlenurse.svg";
import nurseonleave from "../../Images/nurseonleave.svg";
import cancelledappointments from "../../Images/cancelledappointments.svg";
import deals from "../../Images/deals.svg";
import availability from "../../Images/availability.svg";
import advertisebooking from "../../Images/advertisebooking.svg";
import managenurse from "../../Images/managenurse.svg";
import revenue from "../../Images/revenue.svg";
import profile from "../../Images/profile.svg";
import CustomerHistory from "../../Images/CustomerHistory.svg";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import Dashboard from "../Nurse_dashboard/Nurse_dash_master";
import NursebookedHeader from "../NurseHired/NursebookedHeader";
import CompletedBookingMaster from "../CompletedBooking/CompletedBookingMaster";
import OngoingBookingMaster from "../OngoingBooking/OngoingBookingMaster";
import BookingYetToStartMaster from "../BookingYetToStart/BookingYetTostartMaster";
import IdlenurseHeader from "../IdleNurse/IdlenurseHeader";
import AvailabilityMaster from "../Availability/AvailabilityMaster";
import TotalleaveHeader from "../NurseLeave/TotalleaveHeader";
import NurseserviceCancellation from "../CancelledAppointments/NurseServiceCancellationHeader";
import AdvertisementMaster from "../AdvertisementBooking/AdvertisementMaster";
import DealsMaster from "../Deals/DealsMaster";
import RejectIcon from "../../Images/rejectIcon.svg"
import ArPlayIcon from "../../Images/ar_play.svg"

import Revenue from "../Revenue/RevenueMaster";

import ManageNurse from "../ManageNurse/index";
import CancelPayment from "../CancelPayment/CancelPayment";
import PaymentReceived from "../PaymentReceived/PaymentReceived";
import ProfileComp from "../Profilepage/Profilepage";
import CustomerHistoryMaster from "../CustomerHistory/index";
import Axios from "axios";
import { apiurl } from "../../App";
import { notification } from "antd";
import Popover from "@material-ui/core/Popover";
import ReviewReportMaster from "../ReviewReport/ReviewReportMaster";
import NurseReviewReport from "../NurseReviewReport/ReviewReportMaster";
import Review_report from "../../Images/Review_report.svg";
import TicketMaster from "../Ticketing/TicketMaster";
import TicketLogo from "../../Images/ticket_logo.svg";
import supportActive from "../../Images/support_active.png";
import supportGrey from "../../Images/support_image.png";
import TotalBookings from "../../Images/total_bookings.svg";
import CompletedBookings from "../../Images/completed_bookings.svg";
import OnGoingBookings from "../../Images/ongoing_bookings.svg";
import BookingYetToStart from "../../Images/booking_yet_to_start.svg";
import path_img from "../../Images/Path38456.svg";
import {
  MenuItem,
  ListItemText,
  ListItemIcon,
  MenuList,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import ReactSVG from "react-svg";
import NotificationList from "./Notification/NotificationPopup";
import { Language } from "@material-ui/icons";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Grid from "@material-ui/core/Grid";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import CollateralPlayIcon from "../../Images/collateralplay.svg";
import nurse_login from "../../Images/nurse_login.png";
import ManagePackageVerificationimg from "../../Images/manage_package_verification.svg";
// import GoLiveImg from "../../Images/go_live.png";
import GoLiveImg from "../../Images/go_for_live.svg";
import moment from "moment";

import RevenueChart from "../Revenue/RevenueChart";
import RevenueMember from "../Revenue/RevenueMember";
import RevenueSettlement from "../Revenue/RevenueSettlement";
import RevenueMaster from "../Revenue/RevenueMaster";



i18n.changeLanguage(localStorage.nurse_language);
const drawerWidth = 260;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 100,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  verificationdrawer: {
    height: "65px",
  },
});
var today = new Date();
var date = moment(today).format("DD-MMM-YY");
// var date =
//   today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
var time = today.toLocaleString("en-US", {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

class MiniDrawer extends React.Component {
  state = {
    open: true,
    viewmodal: false,
    ProfileData: [],
    date: date,
    time: time,
    current_location: "",
    nofityPopupOpen: false,
    anchorEl: null,
    notificationList: [],
    is_show_verification: false,
    approveStatus: "",
    managenur: "",
    activeStep: 0,
    stepList: [{ label: "Reviewed & Approved", dateTime: "23-Jun-23 10:00AM" }],
    stepList1: [
      { label: "Reviewed & Approved", dateTime: "23-Jun-23 10:00AM" },
    ],
    verificationpage: 0,
    videoListbyMenu: [],
    selectedVideo: [],
    isOpenVideoPopup: false,
    selectedVideoPath: "",
    currentSubmenuid: 0,
    videoPopupTitle: 0,
    nurseIdValue: 0
  };
  componentDidMount() {
    this.checkThreeStepVerification();
    this.getMenulist();
    this.ProfileGetApi();
    this.getNotificationList();
    this.getContractDetail();
    this.realTime();
    this.setState({
      current_location: window.location.href,
    });
    this.sendToken();
    this.changeLanguage();
  }
  realTime = () => {
    setInterval(() => {
      var today = new Date();
      // var today=new Intl.DateTimeFormat('en-US', {year: 'numeric', month: 'small',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date());
      console.log("gili", today);
      var time1 = today
        .toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })
        .toLowerCase();
      console.log("kat", time1);
      this.setState({ time: time1 });
    }, 500);
  };
  componentDidUpdate() {
    if (this.state.current_location !== window.location.href) {
      this.setState({
        current_location: window.location.href,
      });
    }
  }

  generateAlert = (description) => {
    notification.success({
      // message: "Success",
      description,
      onClick: () => {
        console.log("Notification Clicked!");
      },
    });
  };

  tetiiiuik() {
    console.log("efgddddcs");
  }

  ProfileGetApi = () => {
    console.log("fsdfvsdsdfv");
    var self = this;
    Axios({
      method: "POST",
      url: apiurl + "Nurse/getnursevendorprofile",
      data: {
        nursevendorId: localStorage.nurse_vendor_id
          ? localStorage.nurse_vendor_id
          : 0,
      },
    })
      .then((response) => {
        var ProfileData = [];
        ProfileData = response.data.data;
        console.log("pathan", ProfileData);
        this.setState({
          ProfileData,
          imageUrl: response.data.data[0].vendor_filename,
          props_loading: false,
        });
      })
      .catch((error) => { });
  };

  getNotificationList = () => {
    if (localStorage.nurse_vendor_id) {
      Axios({
        method: "POST",
        url: apiurl + "admin/notification_list",
        data: {
          vendor_id: localStorage.nurse_vendor_id
            ? localStorage.nurse_vendor_id
            : 0,
        },
      })
        .then((response) => {
          console.log(
            "render",
            JSON.stringify(response.data.data) ===
            JSON.stringify(this.state.notificationList)
          );

          if (
            JSON.stringify(response.data.data) !==
            JSON.stringify(this.state.notificationList)
          ) {
            this.setState({ notificationList: response.data.data });
          }
          clearInterval(this.IntervalId);
          this.IntervalId = setInterval(() => {
            this.getNotificationList();
          }, 10000);
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
        });
    } else {
      window.localStorage.clear();
      window.location.assign("/?/nursemodule");
    }
  };

  handleNotificationOpen = (event) => {
    this.props.history.push('/notification');
    // this.getNotificationList();
    // this.setState({
    //   nofityPopupOpen: true,
    //   anchorEl: event.currentTarget,
    // });
  };

  handleNotificationclose = () => {
    this.setState({
      nofityPopupOpen: false,
      anchorEl: null,
    });
  };

  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  viewmodalOpen = () => {
    this.setState({ viewmodal: true });
  };
  viewmodalClose = () => {
    this.setState({ viewmodal: false });
  };
  logoutClick = () => {
    if((localStorage.getItem('nurse_vendor_id') != '') && (localStorage.getItem('nurse_vendor_id') != 0)){
      this.state.nurseIdValue = localStorage.getItem('nurse_vendor_id');
      if(this.state.nurseIdValue != 0 && this.state.nurseIdValue != null){ this.clearVendorWebToken(); }
    }
    window.location.assign("/?/nursemodule");
    // this.props.onClose();
  };
  clearVendorWebToken = () => {
    let requestdata = {
      "vendor_id": this.state.nurseIdValue
    }
    Axios({
      method: "POST",
      url: apiurl + "admin/clearVendorWebToken",
      data: requestdata,
    })
      .then((response) => {
        this.state.nurseIdValue = 0;
        window.localStorage.clear();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  active_box = () => {
    this.setState({ current_location: window.location.href });
  };

  getContractDetail = () => {
    if (localStorage.getItem("brvendorId")) {
      Axios({
        method: "POST",
        url: apiurl + "admin/checkContractValid",
        data: {
          vendor_id: localStorage.getItem("brvendorId")
            ? localStorage.getItem("brvendorId")
            : "2",
        },
      })
        .then((response) => {
          clearInterval(this.contractInterval);
          if (response.data.data[0].ErrorCode == "9999") {
            this.contractInterval = setInterval(() => {
              this.getContractDetail();
            }, 600000);
          } else {
            window.localStorage.clear();
            window.location.assign("/?/nursemodule");
          }
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
        });
    }
  };

  handleChangeLanguage = () => {
    if (localStorage.nurse_language == "ar") {
      localStorage.nurse_language = "en";
      this.props.i18n.changeLanguage("en");
    } else {
      localStorage.nurse_language = "ar";
      this.props.i18n.changeLanguage("ar");
    }

    window.location.reload();
  };

  checkThreeStepVerification = () => {
    debugger;
    var data = {
      vendor_id: localStorage.getItem ("nurse_vendor_id")
        ? localStorage.getItem ("nurse_vendor_id")
        : 0,
    };

    Axios.post(apiurl + "Nurse/verifyThreeStepVerification", data)
    .then((response) => {
      if (response.status == 200) {
        debugger;
        let findex = response.data.data.findIndex((obj) => obj.id == 0);
        var filteredData = response.data.data.filter((x) => x.id == "A" || x.id == "R");
        var filteredData1 = response.data.data.filter((x) => x.menu == "Go Live" && x.id == "1");
        var rejectres = response.data.data.filter((x) => x.menu == "Reject Reason by Salesadmin");

        this.setState({ approved: filteredData });
        this.setState({ approvedstatus: filteredData1 });
        this.setState({ Rejected_reason: rejectres });

        if (findex == -1) {
          this.setState({
            verificationpage: 0,
            is_show_verification: false,
            activeStep: 0,
          });
        } else if (filteredData.length != 0 && filteredData1.length != 0) {
          this.setState({
            verificationpage: 0,
            is_show_verification: false,
            activeStep: findex == -1 ? 0 : findex,
          });
        } else if (findex == 2 && filteredData == 0) {
          this.setState({
            verificationpage: 0,
            is_show_verification: true,
            activeStep: findex == -1 ? 0 : findex - 1,
          });
        } else if (findex == 4 && filteredData == 0) {
          this.setState({
            verificationpage: 0,
            is_show_verification: true,
            activeStep: findex == -1 ? 0 : findex,
          });
        } else if (findex == 3 && filteredData == 0) {
          this.setState({
            verificationpage: 0,
            is_show_verification: true,
            activeStep: findex == -1 ? 0 : findex - 1,
          });
        } else if (findex == 3 && filteredData != 0) {
          this.setState({
            verificationpage: 0,
            is_show_verification: true,
            activeStep: findex == -1 ? 0 : findex + 1,
          });
        } else {
          this.setState({
            verificationpage: 0,
            is_show_verification: true,
            activeStep: findex == -1 ? 0 : findex,
          });
        }
        if (filteredData1.length > 0) {
          this.setState({
            is_show_verification: false
          });
        }
      } else {
        this.setState({
          verificationpage: 0,
          is_show_verification: false,
          activeStep: 0,
        });
      }
    })
    .catch((error) => {
      console.log(error);
    });
  };

  routeNavigationByVerification = (index) => {
    this.setState({ verificationpage: index });
  };

  getMenulist = () => {
    Axios({
      method: "POST",
      url: apiurl + "admin/getCollateralVideosByVendor",
      data: {
        vendorId: 5,
      },
    })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ videoListbyMenu: response.data.data });
        } else {
          this.setState({ videoListbyMenu: [] });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleMenuCardOnClick = (data) => {
    if (this.state.videoListbyMenu.length != 0 && data != "") {
      var video = this.state.videoListbyMenu.filter((obj) => {
        return obj.menu_name.toLowerCase() == data.toLowerCase();
      });
      if (video.length > 0) {
        this.setState({
          selectedVideo: video,
          isOpenVideoPopup: true,
          selectedVideoPath: video[0].video_path ? video[0].video_path : "",
          currentSubmenuid: video[0].sub_menu_id,
          videoPopupTitle: video[0].menu_name,
        });
      } else {
        this.setState({
          selectedVideo: [],
          isOpenVideoPopup: false,
          selectedVideoPath: "",
          currentSubmenuid: 0,
          videoPopupTitle: "",
        });
      }
    } else {
      this.setState({
        selectedVideo: [],
        isOpenVideoPopup: false,
        selectedVideoPath: "",
        currentSubmenuid: 0,
        videoPopupTitle: "",
      });
    }
  };

  backVerification = () => {
    this.setState({ verificationpage: 0 }, () => {
      this.checkThreeStepVerification();
    });
  };

  handleClickclose = () => {
    this.setState({ isOpenVideoPopup: false });
  };

  navClick = (data) => {
    this.setState({
      selectedVideoPath: data.video_path ? data.video_path : "",
      currentSubmenuid: data.sub_menu_id,
      videoPopupTitle: data.menu_name,
    });
  };

  updategoLiveStatus = () => {
    Axios({
      method: "POST",
      url: apiurl + "admin/updateGoLiveStatus",
      data: {
        vendor_id: localStorage.nurse_vendor_id
          ? localStorage.nurse_vendor_id
          : 0,
      },
    })
      .then((response) => {
        if (response.data.status == 1) {
          this.setState({
            verificationpage: 0,
            is_show_verification: false,
            activeStep: 0,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  sendToken = () => {
    Axios({
      method: "POST",
      url: apiurl + "admin/insertvendorwebtoken",
      data: {
        "vendor_id": localStorage.getItem("nurse_vendor_id"),
        "token": localStorage.getItem("TokenFCM")
      },
    })
      .then((response) => {
        console.log("update token response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  changeLanguage = () => {

    Axios({
      method: "POST",
      url: apiurl + "admin/insertvendorweblanguage",
      data: {
        "vendor_id": localStorage.getItem("nurse_vendor_id"),
        "language": this.props.i18n.language == 'ar' ? 'ea' : 'en',

      },
    })
      .then((response) => {
        console.log("update token response", response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { classes, theme, children, onClose, selectedValue, t } = this.props;
    const location = window.location.href;

    function rejectIcon() {
      return (
        <div className="rejectIcon">
          <img src={RejectIcon} />
        </div>
      );
    }
    return (
      <div
        className={` ${this.props.i18n.language == "ar" ? "arabic_cls" : ""
          } drawerpage_container`}
      >
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: this.state.open,
            })}
          >
            <Toolbar disableGutters={!this.state.open}>
              <div className="drawer-logo-container">
                <img className="logo" src={Logo} alt="logo" />
              </div>
              {/* <div className="hamburger_icon"> */}
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, {
                  [classes.hide]: this.state.open,
                })}
              >
                <MenuIcon />
              </IconButton>
              {/* </div> */}
              <div
                className={`${this.state.open
                  ? "dropdown-container"
                  : "dropdown-container_close"
                  }`}
              >
                <Dropdown>
                  <Badge
                    color="secondary"
                    variant="dot"
                    className={classes.margin}
                    invisible={
                      this.state.notificationList.length == 0 ? "true" : "false"
                    }
                    style={{direction:(localStorage.nurse_language=='ar')?"rtl":"",textAlign:(localStorage.nurse_language=='ar')?"right":""}}
                  >
                    <Language
                      className="language_icon"
                      onClick={(e) => this.handleChangeLanguage(e)}
                    ></Language>
                    <div
                      className="notification-icon"
                      onClick={(e) => this.handleNotificationOpen(e)}
                    >
                      <img className="notification" src={bell} />
                    </div>

                    <Popover
                      open={this.state.nofityPopupOpen}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleNotificationclose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      className="hand-i"
                      style={{ zIndex: 1600,textAlign:this.props.i18n.language == 'ar' ? 'right' : 'left', direction: (this.props.i18n.language == 'ar' ? 'rtl' : '') }}
                    >
                      <NotificationList
                        notificationList={this.state.notificationList}
                      />
                    </Popover>
                  </Badge>
                <div className="uesrname-border">
                  <Dropdown.Toggle
                    variant="my_style"
                    id="dropdown-basic"
                    ref={(input) => (this.menuElement = input)}
                  >
                    {this.state.ProfileData &&
                      this.state.ProfileData[0] &&
                      this.state.ProfileData[0].vendor_name}
                  </Dropdown.Toggle>
                  <div className="date-wrapper1">
                  <div className="date-wrapper2">
                    <large className="date">
                      {this.state.date},{" "}
                      <span style={{ textTransform: "lowercase" }}>
                        {this.state.time}
                      </span>
                    </large>
                  </div>
                </div>
                </div>
                  <Dropdown.Menu className="dropdown-menu">
                    {this.state.ProfileData.map((val) => {
                      return (
                        <div
                          className={` ${this.props.i18n.language == "ar" ? "arabic_cls" : ""
                            }" dropdown-img`}
                        >
                          {/* <NavLink activeClassName="active" to="/Home/profilepage"> */}

                          <img
                            className="Avatar"
                            alt="avatar-missing"
                            src={val.vendor_profile_path}
                          />

                          {/* </NavLink> */}
                        </div>
                      );
                    })}
                    {this.state.ProfileData.map((val) => {
                      return (
                        <div
                          className="name_email"
                          onClick={() => {
                            this.menuElement.click();
                          }}
                        >
                          <NavLink
                            activeClassName="active"
                            to="/Home/profilepage"
                          >
                            <div
                              className="username"
                              style={{ color: "black", textDecoration: "none" }}
                            >
                              {val.vendor_name}
                            </div>
                          </NavLink>
                          <NavLink
                            activeClassName="active"
                            to="/Home/profilepage"
                          >
                            <div
                              style={{ color: "grey", textDecoration: "none" }}
                            >
                              {/* {val.vendor_contact_email} */}
                              {val.vendor_email}
                            </div>
                          </NavLink>
                        </div>
                      );
                    })}
                    <Divider />
                    <div className="profile_logout_butt">
                      <NavLink
                        activeClassName="active"
                        to="/Home/profilepage"
                        onClick={() => {
                          this.menuElement.click();
                        }}
                      >
                        <p>{t("profile")}</p>
                      </NavLink>
                      <a
                        component={NavLink}
                        href="/nursemodule/?/"
                        className="logout_butt"
                        onClick={this.logoutClick}
                        // onClose={this.props.onClose}
                      >
                        {t("logout")}
                      </a>
                    </div>
                    <Divider />

                    <div className="profile_logout_privacy ">
                      <NavLink
                        target="_blank"
                        activeClassName="active"
                        to="/PrivacyPolicy"
                        onClick={() => {
                          this.menuElement.click();
                        }}
                      >
                        <p style={{ color: "#3c3939", textDecoration: "none" }}>
                          {t("privacy_policy")}
                        </p>
                      </NavLink>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                
              </div>
              {this.state.ProfileData.map((val) => {
                return (
                  // <NavLink activeClassName="active" to="/Home/profilepage">
                  <Avatar
                    className={` ${this.props.i18n.language == "ar" ? "arabic_cls" : ""
                      }  Avatar-image classes.avatar`}
                    alt="avatar-missing"
                    src={val.vendor_profile_path}
                  // onClick={this.viewmodalOpen}
                  />
                  // </NavLink>
                );
              })}
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
              [classes.verificationdrawer]: this.state.is_show_verification,
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: this.state.open,
                [classes.drawerClose]: !this.state.open,
                [classes.verificationdrawer]: this.state.is_show_verification,
              }),
            }}
            open={this.state.open}
            anchor={this.props.i18n.language == "ar" ? "right" : "left"}
          >
            <div className={classes.toolbar}>
              {/* <IconButton onClick={this.handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton> */}
            </div>

            {!this.state.is_show_verification && (
              <MenuList className="appbar_sideicons" onClick={this.active_box}>
                <MenuItem
                  component={Link}
                  to="/Home/dashboard"
                  className={`${this.state.current_location.includes(
                    "/dashboard" || "/dashboard"
                  ) && "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={dashboard} />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("home")} />
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Home/nursehired"
                  className={`${this.state.current_location.includes("/nursehired") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={TotalBookings} className="menu_icon" />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("total_bookings")} />
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Home/completedbooking"
                  className={`${this.state.current_location.includes("/completedbooking") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={CompletedBookings} className="menu_icon" />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("completed_bookings")} />
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/Home/ongoingbooking"
                  className={`${this.state.current_location.includes("/ongoingbooking") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={OnGoingBookings} className="menu_icon" />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("ongoing_bookings")} />
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Home/bookingyettostart"
                  className={`${this.state.current_location.includes(
                    "/bookingyettostart"
                  ) && "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={BookingYetToStart} className="menu_icon" />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("bookings_yet_to_start")} />
                </MenuItem>

                {/* <MenuItem
                component={Link}
                to="/Home/cancelledappointments"
                className={`${this.state.current_location.includes(
                  "/cancelledappointments"
                ) && "active_text_heading"
                  }`}
              >
                <ListItemIcon>
                  <div className="icon-container">
                    <ReactSVG src={cancelledappointments} className="menu_icon" />
                  </div>
                </ListItemIcon>
                <ListItemText primary={t("canceled_bookings")} />
              </MenuItem> */}

                <MenuItem
                  component={Link}
                  to="/Home/idlenurse"
                  className={`${this.state.current_location.includes("/idlenurse") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={idlenurse} className="menu_icon" />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("idle_nurses")} />
                </MenuItem>

                {/* <MenuItem
                  component={Link}
                  to="/Home/availability"
                  className={`${this.state.current_location.includes("/availability") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={availability} className="menu_icon" />
                      <div>
                      </div>
                    </div>
                    <div>
                      <img
                        src={`${this.state.current_location.includes("/dashboard")
                          ? CollateralPlayIcon
                          : ""
                          }`}
                        className="menu_play_img"
                        onClick={() => {
                          this.handleMenuCardOnClick("Manage Schedule");
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("manage_schedule_menu")} />
                </MenuItem> */}
                {/* 
                <MenuItem
                  component={Link}
                  to="/Home/nurseleave"
                  className={`${this.state.current_location.includes("/nurseleave") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <ReactSVG src={nurseonleave} className="menu_icon" />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("nurses_on_leave")} />
                </MenuItem> */}

                <MenuItem
                  component={Link}
                  to="/Home/advertisement"
                  className={`${this.state.current_location.includes("/advertisement") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <div>
                        <ReactSVG
                          src={advertisebooking}
                          className="menu_icon"
                        />
                        <div>
                          {/* <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Advertisement Booking') }} /> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* src={this.state.current_location == "/deals" ? CollateralPlayIcon :""}  */}
                      {/* src={location.endsWith("Dashboard") ? CollateralPlayIcon : ""} */}
                      <img
                        src={`${this.state.current_location.includes("/dashboard")
                          ?  localStorage.nurse_language == 'ar'? ArPlayIcon: CollateralPlayIcon
                          : ""
                          }`}
                        className="menu_play_img"
                        onClick={() => {
                          this.handleMenuCardOnClick("Advertisement Booking");
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("advertisement_booking")} />
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Home/deals"
                  className={`${this.state.current_location.includes("/deals") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <div>
                        <ReactSVG src={deals} className="menu_icon" />
                        <div>
                          {/* <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Deals') }} /> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        src={`${this.state.current_location.includes("/dashboard")
                          ? localStorage.nurse_language == 'ar'? ArPlayIcon: CollateralPlayIcon
                          : ""
                          }`}
                        className="menu_play_img"
                        onClick={() => {
                          this.handleMenuCardOnClick("Deal");
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("deals")} />
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Home/revenue"
                  className={`${this.state.current_location.includes("/revenue") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <div>
                        <ReactSVG src={revenue} className="menu_icon" />
                        <div>
                          {/* <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Revenue') }} /> */}
                        </div>
                      </div>
                    </div>
                    {/* <div>
                    <img src={`${this.state.current_location.includes("/dashboard") ? CollateralPlayIcon : ""}`}
                     className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Revenue') }} />
                  </div> */}
                  </ListItemIcon>
                  <ListItemText primary={t("revenue_and_settlement")} />
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Home/managenurse"
                  className={`${this.state.current_location.includes("/managenurse") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <div>
                        <ReactSVG src={managenurse} className="menu_icon" />
                        {/* <div>
                        <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Manage Nurse') }} />
                      </div> */}
                      </div>
                    </div>
                    <div>
                      <img
                        src={`${this.state.current_location.includes("/dashboard")
                          ?  localStorage.nurse_language == 'ar'? ArPlayIcon: CollateralPlayIcon
                          : ""
                          }`}
                        className="menu_play_img"
                        onClick={() => {
                          this.handleMenuCardOnClick("Manage Nurse");
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("manage_nurses")} />
                </MenuItem>

                {/* <MenuItem
                  component={Link}
                  to="/Home/review_report"
                  className={`${this.state.current_location.includes("/review_report") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <div>
                        <ReactSVG src={Review_report} className="menu_icon" />
                        <div> */}
                {/* <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Review Report') }} /> */}
                {/* </div>
                      </div>
                    </div> */}
                {/* <div>
                    <img src={`${this.state.current_location.includes("/dashboard") ? CollateralPlayIcon : ""}`}
                     className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Review Report') }} />
                  </div> */}
                {/* </ListItemIcon>
                  <ListItemText primary={t("review_report_menu")} />
                </MenuItem> */}

                <MenuItem
                  component={Link}
                  to="/Home/ticket"
                  className={`${this.state.current_location.includes("/ticket") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container" style={{ display: (this.props.i18n.language == 'ar' ? 'flex' : ''), justifyContent: (this.props.i18n.language == 'ar' ? 'end' : '') }}>
                      {/* <div>
                      <ReactSVG src={TicketLogo} />
                    </div> */}
                      <div id="supportIcon">
                        {this.state.current_location.includes("/ticket") ? (
                          <img src={supportActive} width="100%" alt="" />
                        ) : (
                          <img src={supportGrey} width="100%" alt="" />
                        )}
                        <div>
                          {/* <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Supports') }} /> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        src={`${this.state.current_location.includes("/dashboard")
                          ?  localStorage.nurse_language == 'ar'? ArPlayIcon: CollateralPlayIcon
                          : ""
                          }`}
                        className="menu_play_img"
                        onClick={() => {
                          this.handleMenuCardOnClick("Support");
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("ticketing")} />
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Home/profilepage"
                  className={`${location.includes("/profilepage") && "active_text_heading"
                    }`}
                  onClick={this.viewmodalOpen}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <div>
                        <ReactSVG src={profile} className="menu_icon" style={{marginRight:localStorage.nurse_language == 'ar'? "-8px":""}}/>
                        <div>
                          {/* <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Profile') }} /> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <img
                        src={`${this.state.current_location.includes("/dashboard")
                          ?  localStorage.nurse_language == 'ar'? ArPlayIcon: CollateralPlayIcon
                          : ""
                          }`}
                        className="menu_play_img"
                        onClick={() => {
                          this.handleMenuCardOnClick("Profile");
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={t("profile")} />
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/Home/customerhistory"
                  className={`${this.state.current_location.includes("/customerhistory") &&
                    "active_text_heading"
                    }`}
                >
                  <ListItemIcon>
                    <div className="icon-container">
                      <div>
                        <ReactSVG src={CustomerHistory} className="menu_icon" />
                        <div>
                          {/* <img src={CollateralPlayIcon} className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Customer History') }} /> */}
                        </div>
                      </div>
                    </div>
                    {/* <div>
                    <img src={`${this.state.current_location.includes("/dashboard") ? CollateralPlayIcon : ""}`}
                    className="menu_play_img" onClick={() => { this.handleMenuCardOnClick('Customer History') }} />
                  </div> */}
                  </ListItemIcon>
                  <ListItemText primary={t("customer_history_menu")} />
                </MenuItem>
              </MenuList>
            )}
          </Drawer>
          {!this.state.is_show_verification && (
            <main className={classes.content}>
              <div className={classes.toolbar} />

              <Route
                path={`${this.props.match.path}/dashboard`}
                component={Dashboard}
                exact
              />
              <Route
                path={`${this.props.match.path}/nursehired`}
                component={NursebookedHeader}
                exact
              />
              <Route
                path={`${this.props.match.path}/completedbooking`}
                component={CompletedBookingMaster}
                exact
              />
              <Route
                path={`${this.props.match.path}/ongoingbooking`}
                component={OngoingBookingMaster}
                exact
              />
              <Route
                path={`${this.props.match.path}/bookingyettostart`}
                component={BookingYetToStartMaster}
                exact
              />
              <Route
                path={`${this.props.match.path}/idlenurse`}
                component={IdlenurseHeader}
                exact
              />
              <Route
                path={`${this.props.match.path}/availability`}
                component={AvailabilityMaster}
                exact
              />
              <Route
                path={`${this.props.match.path}/nurseleave`}
                component={TotalleaveHeader}
                exact
              />
              <Route
                path={`${this.props.match.path}/cancelledappointments`}
                component={NurseserviceCancellation}
                exact
              />
              <Route
                path={`${this.props.match.path}/advertisement`}
                // component={AdvertisementMaster}
                render={(props) => (
                  <AdvertisementMaster
                    {...props}
                    generateAlert={this.generateAlert}
                  />
                )}
                exact
              />
              <Route
                path={`${this.props.match.path}/deals`}
                component={DealsMaster}
                exact
              />

              <Route
                path={`${this.props.match.path}/revenue`}
                component={RevenueChart} //RevenueMaster
              // component={RevenueMember}
              />
              <Route
                path={`${this.props.match.path}/revenueChart/revenue/:startDate/:endDate/:id`}
                component={RevenueMaster}
              />
              <Route
                path={`${this.props.match.path}/revenueChart/settlement/:startDate/:endDate/:id`}
                component={RevenueSettlement}
              />

              {/* <Route
                path={`${this.props.match.path}/revenueChart/medlist/:startDate/:endDate/:id`}
                component={RevenueMaster}
              /> */}

              <Route
                path={`${this.props.match.path}/managenurse`}
                component={ManageNurse}
                exact
              />
              <Route
                path={`${this.props.match.path}/review_report`}
                component={ReviewReportMaster}
                exact
              />
              <Route
                path={`${this.props.match.path}/nursereview_report`}
                component={NurseReviewReport}
                exact
              />
              <Route
                path={`${this.props.match.path}/cancelpayment`}
                component={CancelPayment}
                exact
              />
              <Route
                path={`${this.props.match.path}/paymentreceived`}
                component={PaymentReceived}
                exact
              />
              <Route
                path={`${this.props.match.path}/profilepage`}
                // component={ProfileComp}
                render={(props) => (
                  <ProfileComp {...props} ProfileGetApi={this.ProfileGetApi} />
                )}
                exact
              />
              <Route
                path={`${this.props.match.path}/customerhistory`}
                component={CustomerHistoryMaster}
                exact
              />
              <Route
                path={`${this.props.match.path}/ticket`}
                component={TicketMaster}
                exact
              />
              <div>
                {children}
                {/* <Profilepage
                open={this.state.viewmodal}
                onClose={this.viewmodalClose}
              /> */}
              </div>
            </main>
          )}
        </div>

        {this.state.is_show_verification &&
          this.state.verificationpage == 0 && (
            <Grid container>
              <Grid sm={12} md={12} className="p-3 text-center mt-2 mb-3">
                <div className="verification_head">
                  <h1>{t("stepRegistrationToGoLive")}</h1>
                </div>
              </Grid>

              <Grid sm={12} md={12} className="ml-5 mr-5">
                <Grid container>
                  {/* <Grid sm={4} md={(this.state.activeStep > 1 ? 3 : 4)} style={{ marginTop: '4rem' }}></Grid> */}
                  {/* <Grid sm={5} md={(this.state.activeStep > 1 ? 3 : 4)} style={{ marginTop: '4rem' }} className={`${this.state.activeStep >= 0 ? 'active_card' : 'disable_card'} pl-3 pr-3`}> */}
                  <Grid sm={3} md={3}></Grid>
                  <Grid
                    sm={3}
                    md={3}
                    style={{ marginTop: "4rem", marginLeft: "24px" }}
                    className={`${this.state.activeStep >= 0
                      ? "active_card"
                      : "disable_card"
                      } pl-4 pr-3`}
                  >
                    <div className="verification_img_div">
                      <div className="verification_img_div1">
                        {/* <div  className="step_head_img"></div>      */}
                        <img
                          className="step_head_img"
                          src={ManagePackageVerificationimg}
                          alt=""
                          onClick={() => {
                            if (this.state.activeStep >= 0)
                              this.routeNavigationByVerification("1");
                          }}
                        />
                        <div className="ofing" style={{ marginLeft: "-15px" }}>
                          <img
                            className="ofing1"
                            src={CollateralPlayIcon}
                            // className="play_img"
                            onClick={() => {
                              this.handleMenuCardOnClick("Manage Nurse");
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className="mt-3 text-center"
                        style={{
                          fontSize: "16px",
                          marginLeft: "10px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        {t("manage_nurses")}
                      </div>
                    </div>
                  </Grid>
                  {/* {this.state.activeStep > 1 &&  */}
                  {/* // <Grid sm={3} md={3} className={`${this.state.activeStep > 4 ? 'active_card' : 'disable_card'} pl-3 pr-3`}> */}
                  <Grid
                    sm={3}
                    md={3}
                    style={{ marginLeft: "-14px" }}
                    className={`${this.state.activeStep > 4 ? "disable_card" : "active_card"
                      } pl-2 pr-3`}
                  >
                    <div
                      className="verification_img_div"
                      style={{ marginTop: "4rem" }}
                    >
                      {this.state.approved[0]?.id == "R" ? (
                        <span className="step_head_img">
                          <div
                            style={{
                              fontSize: "15px",
                              overflow: "auto",
                              height: "160px",
                              border: "1px solid #510f30",
                              borderRadius: "12px",
                              padding: "8px",
                              marginBottom: "5px",
                            }}
                          >
                            {this.state.Rejected_reason.map((item) => (
                              <div key={item.menu}>{item.id}</div>
                            ))}
                          </div>
                        </span>
                      ) : this.state.approved[0]?.id == "A" ? (
                        <img
                          className="step_head_img"
                          src={GoLiveImg}
                          alt=""
                          onClick={() => {
                            console.log("eee");
                            this.updategoLiveStatus();
                          }}
                        />
                      ) : null}


                      {/* <img className='step_head_img' src={GoLiveImg} alt="" /> */}
                    </div>
                    {/* <div className="mt-3 text-center" style={{ fontSize: '16px', color: '#510F30' }}> CONGRATS YOU ARE LIVE </div> */}
                    {/* <Grid sm={4} md={(this.state.activeStep > 1 ? 3 : 4)} style={{ marginTop: '4rem' }}></Grid> */}
                  </Grid>
                  <Grid sm={3} md={3}></Grid>
                  {/* } */}
                </Grid>
              </Grid>
              <Grid sm={12} md={12} className="ml-5 mr-5 stepper_line_show">
                {/* {this.state.managenur != "N" ? */}
                {/* { this.state.approveStatus == "A"? */}
                <Grid container>
                  <Grid sm={3} md={3}></Grid>
                  <Grid sm={6} md={6} className="mt-5">
                    <Stepper
                      activeStep={this.state.activeStep}
                      orientation="horizontal"
                      alternativeLabel={true}
                    >
                      {this.state.stepList.map((label) => (
                        <Step key={label}>
                          <StepLabel>
                            {/* <div className="mt-3 text-center" style={{ fontSize: '16px', color: '#510F30' }}> CONGRATS YOU ARE LIVE </div> */}
                          </StepLabel>
                        </Step>
                      ))}
                      {this.state.stepList1.map((label) => (
                        <Step>
                          <StepLabel StepIconComponent={
                            this.state.approved[0]?.id == "R" &&
                            rejectIcon
                          }>
                            {/* onClick={() => { if (this.state.activeStep >= 0) this.routeNavigationByVerification("1") }} */}
                            {this.state.approved[0]?.id == "A" ? (
                              <div
                                className="click_underline"
                                style={{
                                  fontSize: "16px",
                                  color: "#510F30",
                                  cursor: "pointer",
                                  marginLeft:localStorage.nurse_language=='ar'?"25px":""
                                }}
                              >
                                {t("Congratulations")}
                                <br /> {t("youAreLive")}
                              </div>
                            ) : this.state.approved[0]?.id == "R" ? (
                              <span className="declined_re" style={{marginLeft:localStorage.nurse_language=='ar'?"25px":""}}>
                                {t("Declined")}
                              </span>
                            ) : (
                              <div
                                className="mt-3 text-center"
                                style={{ fontSize: "16px", color: "#510F30",marginLeft:localStorage.nurse_language=='ar'?"25px":"" }}
                              >
                                {t("WaitingFor")} <br /> {t("verification")}
                              </div>
                            )}

                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Grid>

                  {this.state.approved[0]?.id == "A" && (
                    <div
                      style={{
                        height: "200px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          marginTop: "-29px",
                        }}
                      >
                        <Grid sm={3} md={3}></Grid>
                        <Grid container>
                          <Grid sm={3} md={3}></Grid>
                          <Grid
                            sm={6}
                            md={6}
                            className="row d-flex justify-content-center ml-2"
                          >
                            <div className="row"></div>
                            <button
                              className="start_button"
                              onClick={() => { if (this.state.activeStep > 1) this.updategoLiveStatus() }}
                            >Start</button>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  )}
                </Grid>

                {/* : <Grid container>

                  <Grid sm={3} md={3}></Grid>
                  <Grid sm={3} md={3} className="mt-5">
                    <Stepper activeStep={this.state.activeStep}
                      orientation="horizontal" alternativeLabel={true}>
                      {this.state.stepList.map((label) => (
                        <Step key={label}>
                          <StepLabel> */}
                {/* <div className="mt-3 text-center" style={{ fontSize: '16px', color: '#510F30' }}> CONGRATS YOU ARE LIVE </div> */}
                {/* </StepLabel>

                        </Step>
                      ))}

                    </Stepper>
                  </Grid>
                  <Grid sm={6} md={6}></Grid>
                </Grid>
              } */}
              </Grid>
            </Grid>
          )}
        {this.state.is_show_verification &&
          this.state.verificationpage == 1 && (
            <ManageNurse
              isfromverification="true"
              backVerification={this.backVerification}
            />
          )}

        {/* <Modalcomp
          visible={this.state.isOpenVideoPopup}/> */}

        <Modalcomp
          visible={this.state.isOpenVideoPopup}
          closemodal={this.handleClickclose}
          clrchange="text_color"
          title={this.state.videoPopupTitle}
          xswidth="md"
          t={t}
          i18n={this.props.i18n}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <video
                src={this.state.selectedVideoPath}
                style={{ width: "100%" }}
                controls
              ></video>
              <div>
                <ul className="collaterals_video_ul">
                  {this.state.selectedVideo.map((obj) => (
                    <li
                      className={`${this.state.currentSubmenuid == obj["sub_menu_id"]
                        ? "active"
                        : ""
                        } video_menu_label`}
                      onClick={() => {
                        this.navClick(obj);
                      }}
                    >
                      {" "}
                      {/* {obj["sub_menu_name"]}{" "} */}
                      {
                        (obj["sub_menu_name"] == "How to Add" && this.props.i18n.language == "ar") ? t('how_to_add') :
                          (obj["sub_menu_name"] == "How to Edit" && this.props.i18n.language == "ar") ? t('how_to_edit') :
                            (obj["sub_menu_name"])

                      }
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Modalcomp>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};
export default compose(
  withStyles(styles, { withTheme: true }),
  withTranslation()
)(MiniDrawer);
