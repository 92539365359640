import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { Paper } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Stepper from "./Stepper";
import Workflow from "../../Images/workflow.svg";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import DeleteMedia from "./DeleteMedia";
import NotfoundIcon from "../../Images/NotFound.svg";
import { apiurl } from "../../App";
import axios from "axios";
import { notification, Spin } from "antd";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import DealImage from "../../Images/deal_image.png";
import moment from "moment";

import "./DealList.css";
import { Tabs } from "antd";
import ReactPagination from "../Pagination/Pagination";
const { TabPane } = Tabs;

export default class DealList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      open: false,
      openstepper: [],
      dyndeallist: [],
      dyndealAlllist: [],
      total_count: "",
      selectedData: {},
      limit: 10,
      pageno: 1,
      is_show_repeat: "false",
      is_show_edit: "false",
      active_pageno: 0,
    };
  }

  handleOpen = (id) => {
    this.setState({ open: true, currentDeleteId: id });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentDidMount() {
    this.getlistdata();
  }

  getPaginateList = (data) => {
    this.setState({ dataOnload: true });
    var self = this;
    axios({
      method: "post",
      url: apiurl + "Diet/getsingle_deals",
      data: {
        vendor_id: localStorage.getItem("nurse_vendor_id")
          ? localStorage.getItem("nurse_vendor_id")
          : "12",
        limit: this.state.limit,
        pageno: data + 1,
      },
    }).then((response) => {
      console.log("wwww", response.data.data[0].details);
      var dyndeallist = [];
      var dyndealAlllist = [];
      this.setState({ total_count: response.data.data[0].totalCount });

      response.data.data[0].details.map((listdata) => {
        dyndealAlllist.push(listdata);
      });
      self.setState(
        {
          dyndeallist: dyndeallist,
          dyndealAlllist: dyndealAlllist,
          dataOnload: false,
        },
        () => {
          if (dyndealAlllist.length > 0) {
            dyndealAlllist[0]["activerow"] = 1;
            const fromDate = moment(dyndealAlllist[0].deal_valid_from);
            const toDate = moment(dyndealAlllist[0].deal_valid_to);
            const business_days = toDate.diff(fromDate, "days") + 1;
            dyndealAlllist[0]["business_days"] = Math.abs(business_days);
            console.log("heeriye", dyndealAlllist[0]);
            this.setEditDetail(dyndealAlllist[0]);
          }
        }
      );
    });
  };

  storePageNo = (data) => {
    this.setState({ pageno: data + 1, active_pageno: data });
  };

  UNSAFE_componentWillReceiveProps(newprops) {
    if (newprops.afteredit) {
      this.getlistdata();
      newprops.aftereditfalse();
    }
  }

  getlistdata = (notifymsg) => {
    debugger;
    this.setState({ dataOnload: true });
    var self = this;
    axios({
      method: "post",
      url: apiurl + "Diet/getsingle_deals",
      data: {
        vendor_id: localStorage.getItem("nurse_vendor_id")
          ? localStorage.getItem("nurse_vendor_id")
          : "12",
        limit: this.state.limit,
        pageno: 1,
      },
    }).then((res) => {
      console.log("wwwwqqqqq", res.data.data[0].details);
      var dyndeallist = [];
      var dyndealAlllist = [];
      this.setState({ total_count: res.data.data[0].totalCount });

      res.data.data[0].details.map((listdata, i) => {
        dyndealAlllist.push(listdata);
      });

      if (notifymsg) {
        notification.info({
          description: notifymsg,
          placement: "topRight",
        });
      }

      self.setState(
        {
          dyndeallist: dyndeallist,
          dyndealAlllist: dyndealAlllist,
          dataOnload: false,
        },
        () => {
          if (dyndealAlllist.length > 0) {
            dyndealAlllist[0]["activerow"] = 1;
            const fromDate = moment(dyndealAlllist[0].deal_valid_from);
            const toDate = moment(dyndealAlllist[0].deal_valid_to);
            const business_days = toDate.diff(fromDate, "days") + 1;
            dyndealAlllist[0]["business_days"] = Math.abs(business_days);
            console.log("heeriye", dyndealAlllist[0]);
            this.setEditDetail(dyndealAlllist[0]);
          }
        }
      );
    });
  };

  openstepper = (id) => {
    if (
      this.state.openstepper.find((removeid) => {
        return removeid === id;
      })
    ) {
      this.state.openstepper.splice(
        this.state.openstepper.findIndex((findindex) => {
          return findindex === id;
        }),
        1
      );
    } else {
      this.state.openstepper.push(id);
    }
    this.setState({});
  };

  deleteDealLIst = () => {
    this.setState({ dataOnload: true, open: false });
    var self = this;
    axios({
      method: "DELETE",
      url: apiurl + "Diet/deleteDeals",
      data: {
        id: this.state.selectedData.edit_id,
      },
    }).then((response) => {
      self.setState({ active_pageno: 0, total_count: 0 }, () => {
        self.getlistdata("Deal Deleted Successfully");
      });
    });
  };
  getDealsBody() {
    console.log("dealsdody", this.state.dyndealAlllist);
    if (this.state.dyndealAlllist && this.state.dyndealAlllist.length > 0) {
      return this.state.dyndealAlllist.map((dealDetails, index) => (
        this.props.i18n.language=='ar'? (
          <tr
          className={dealDetails.activerow == 1 ? "active_row" : ""}
          style={{ border: "2px dashed #D1D1D1", cursor: "pointer" }}
          key={index}
          onClick={(e) => {
            this.SelectedRow(dealDetails);
          }}
        >
          <td style={{ padding: "15px", fontSize: "14px", color: "#707070",textAlign:'right'  }}>  {" "}{dealDetails.deal_status}{" "} </td>
          <td style={{ padding: "15px", fontSize: "14px", color: "#707070",textAlign:'right'  }}> {" "} {dealDetails.nurse_duty_hrs + " " + "hrs"}{" "}    </td>
          <td style={{ padding: "15px",fontSize: "14px",color: "#707070",textAlign:'right' }}> {" "} {dealDetails.deal_active == 1 ? "Active" : "Inactive"}{" "}</td>
          <td
            style={{
              padding: "15px",
              fontSize: "14px",
              color: "#707070",
              textAlign: "right",
            }}
          >
            <span>
              {dealDetails.deal_amount}
              <span>
                {dealDetails.deal_options == "Amount" ? (
                  <span style={{ fontSize: "10px" }}> د.ك</span>
                ) : (
                  "%"
                )}
              </span>
            </span>
            {/* {dealDetails.deal_amount +
              " " +
              (dealDetails.deal_options == "Amount" ? "KWD" : "%")}{" "} */}
          </td>
          <td style={{ padding: "15px", fontSize: "14px", color: "#707070",textAlign:'right'  }}> {" "} {dealDetails.deal_title}{" "} </td>
        </tr>
        ) : (
          <tr
          className={dealDetails.activerow == 1 ? "active_row" : ""}
          style={{ border: "2px dashed #D1D1D1", cursor: "pointer" }}
          key={index}
          onClick={(e) => {
            this.SelectedRow(dealDetails);
          }}
        >
          <td style={{ padding: "15px", fontSize: "14px", color: "#707070" }}>
            {" "}
            {dealDetails.deal_title}{" "}
          </td>
          <td
            style={{
              padding: "15px",
              fontSize: "14px",
              color: "#707070",
              // textAlign: "center",
            }}
          >
            <span>
              {dealDetails.deal_amount}
              <span>
                {dealDetails.deal_options == "Amount" ? (
                  <>
                  <span>.000</span>
                  <span style={{ fontSize: "10px" }}> KWD</span>
                  </>
                ) : (
                  "%"
                )}
              </span>
            </span>
            {/* {dealDetails.deal_amount +
              " " +
              (dealDetails.deal_options == "Amount" ? "KWD" : "%")}{" "} */}
          </td>
          <td
            style={{
              padding: "15px",
              fontSize: "14px",
              color: "#707070",
              // textAlign: "center",
            }}
          >
            {" "}
            {dealDetails.deal_active == 1 ? "Active" : "Inactive"}{" "}
          </td>
          <td style={{ padding: "15px", fontSize: "14px", color: "#707070" }}>
            {" "}
            {dealDetails.nurse_duty_hrs + " " + "hrs"}{" "}
          </td>
          <td style={{ padding: "15px", fontSize: "14px", color: "#707070" }}>
            {" "}
            {dealDetails.deal_status}{" "}
          </td>
        </tr>
        )
        
      ));
    } else {
      return (
        <tr>
          {" "}
          <td
            colSpan={3}
            style={{ padding: "5px", fontSize: "14px", color: "#707070" }}
          >
            {" "}
            No Data Found{" "}
          </td>{" "}
        </tr>
      );
    }
  }

  SelectedRow(data) {
    this.state.dyndealAlllist.forEach((row, i) => {
      if (row["id"] == data["id"]) {
        row["activerow"] = 1;
      } else {
        row["activerow"] = 0;
      }
    });
    const fromDate = moment(data.deal_valid_from);
    const toDate = moment(data.deal_valid_to);
    const business_days = toDate.diff(fromDate, "days") + 1;
    data.business_days = Math.abs(business_days);

    this.setEditDetail(data);
  }

  setEditDetail = (data) => {
    console.log("setedit", data);
    console.log("dealas", data.deal_options);
    var todate = moment();
    var enddate =
      data.deal_valid_to.split("T").length > 0
        ? moment(data.deal_valid_to, "YYYY-MM-DD")
        : moment();
    const selectedData = {
      edit_id: data.id,
      edit_deal_title: data.deal_title,
      deal_options: data.deal_options,
      deal_service_type: data.deal_service_type,
      deal_valid_from: data.deal_valid_from,
      deal_valid_to: data.deal_valid_to,
      deal_amount: data.deal_amount,
      business_days:
        data.business_days != undefined && data.business_days != null
          ? data.business_days
          : "",
      is_show_repeat: data.is_show_repeat,
      is_show_edit: data.is_show_edit,
      nurse_duty_hrs: data.nurse_duty_hrs,
    };
    this.setState({ selectedData, editbookingDetails: data });
  };

  repeat_ad() {
    this.state.editbookingDetails.isrepeat = true;
    this.setState({}, () => {
      this.props.edit_details(this.state.editbookingDetails);
    });
  }

  render() {
    const { t } = this.props;
    return (
      <Spin className="spinner_align" spinning={this.state.dataOnload}>
        <div className="booking_createlist booking_createlist--advertisement">
          <Grid
            container
            className="calendar_container"
            spacing={3}
            style={{ height: "calc(100vh - 150px)" }}
          >
            <Grid
              item
              xs={12}
              md={6}
              className={`${
                this.props.i18n.language == "ar" ? "order_1" : ""
              } `}
            >
              {this.state.selectedData.edit_id && (
                <Grid
                  container
                  direction="row"
                  style={{
                    margin: "10px",
                    marginTop: "4rem",
                    marginLeft: "2rem",
                    justifyContent:this.props.i18n.language == "ar" ? "right" : "",
                    paddingRight:this.props.i18n.language == "ar" ? "36px" : ""
                  }}
                >
                  <Grid
                    item
                    xs={7}
                    className={`${
                      this.props.i18n.language == "ar" ? "order_1" : ""
                    } `}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex" }}
                      className="deal_data_div"
                    >
                      <Grid
                        item
                        xs={6}
                        className={`${
                          this.props.i18n.language == "ar" ? "order_1" : ""
                        } `}
                      >
                        <Grid
                          container
                          direction="column"
                          style={{
                            fontSize: "16px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10%",
                              marginBottom: "15px",
                              textAlign:
                                this.props.i18n.language == "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            <label>{t("title")}</label>
                            <span>
                              {this.state.selectedData.edit_deal_title}
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10%",
                              marginBottom: "15px",
                              textAlign:
                                this.props.i18n.language == "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            <label>{t("days")}</label>
                            <span>{this.state.selectedData.business_days}</span>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10%",
                              textAlign:
                                this.props.i18n.language == "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            <label>{t("tbl_head_from")}</label>
                            <span>
                              {this.state.selectedData.deal_valid_from
                                ? moment(
                                    this.state.selectedData?.deal_valid_from
                                  ).format("DD-MMM-YY")
                                : null}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid
                          container
                          direction="column"
                          style={{
                            fontSize: "16px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10%",
                              marginBottom: "15px",
                              textAlign:
                                this.props.i18n.language == "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            <label>{t("tbl_head_nurse")}</label>
                            <span>
                              {this.state.selectedData.deal_service_type}
                            </span>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10%",
                              marginBottom: "15px",
                              textAlign:
                                this.props.i18n.language == "ar"
                                  ? "right"
                                  : "left",
                              direction:
                                  this.props.i18n.language == "ar"
                                    ? "rtl"
                                    : "",
                            }}
                          >
                            <label>{t("deal")}</label>
                            <span>
                              {this.state.selectedData.deal_amount
                                ? this.state.selectedData.deal_amount
                                : null}
                                <span>
                              {this.state.selectedData.deal_options
                                ? this.state.selectedData.deal_options ==
                                  ("Amount")
                                  ? 
                                  <>
                                  <span>.000</span>
                                  <span className="KWD_font"> {t("kwd")}</span>
                                </>
                                  : (" %")
                                : null}</span>
                              <img
                                src={DealImage}
                                height={20}
                                width={20}
                                alt="deal"
                                style={{
                                  position: "absolute",
                                  marginLeft: "5px",
                                  marginRight:this.props.i18n.language == "ar"
                                  ? "5px"
                                  : "",
                                }}
                              />
                            </span>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "10%",
                              textAlign:
                                this.props.i18n.language == "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            <label>{t("tbl_head_to")}</label>
                            <span>
                              {this.state.selectedData?.deal_valid_to
                                ? moment(
                                    this.state.selectedData?.deal_valid_to
                                  ).format("DD-MMM-YY")
                                : null}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          marginTop: "20%",
                          marginBottom: "10%",
                          justifyContent:this.props.i18n.language == "ar"
                            ? "right"
                            : "",
                        }}
                      >
                        <Button
                          className="deal_edit_book"
                          style={{
                            display:
                              this.state.selectedData.is_show_edit == "true"
                                ? "block"
                                : "none",
                            marginRight:this.props.i18n.language == "ar"?"": "5px",
                            textTransform: "capitalize",
                          }}
                          onClick={() =>
                            this.props.edit_details(
                              this.state.editbookingDetails
                            )
                          }
                        >
                          <EditIcon className="list_edit" />
                          {t("edit")}
                        </Button>
                        {/* <Button
                            className="delete_book"
                            style={{ marginRight: "5px" }}
                            // onClick={() => this.deleteDealLIst()}
                            onClick={() => this.handleOpen(this.state.selectedData.edit_id)}
                          >
                            <DeleteIcon className="listdelete_icon" /> 
                            Delete
                          </Button> */}
                        <Button
                          style={{
                            display:
                              this.state.selectedData.is_show_repeat == "true"
                                ? "block"
                                : "none",
                          }}
                          className="deal_repet_book"
                          onClick={(e) => {
                            this.repeat_ad();
                          }}
                        >
                          {" "}
                          {t("repeat")}{" "}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={12} md={3} style={{}}>
                      <Stepper businessDays={this.state.editbookingDetails} />
                    </Grid> */}
                  <Grid
                    item
                    xs={12}
                    md={3}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <div style={{ height: "100%" }}>
                      <div
                        style={{
                          position: "relative",
                          top: "24%",
                          textAlign: "center",
                          zIndex: "99",
                          width: "110px",
                        }}
                      >
                        <label
                          style={{
                            fontSize: "16px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        ></label>
                        <div style={{ color: "#fff" }}>
                          <label style={{ fontSize: "18px" }}>
                            {this.state.selectedData?.deal_amount? this.state.selectedData?.deal_amount : null}{" "}
                            &nbsp;
                          </label>
                          <label style={{ fontSize: "18px", color: "#00000" }}>
                            {this.state.selectedData?.deal_options ? this.state.selectedData?.deal_options == "Amount" ? " KWD" : " %" : null}
                          </label>
                        </div>
                        <div style={{ fontSize: "20px", color: "#fff" }}>
                          {this.state.selectedData?.deal_amount ? "Off" : null}
                        </div>
                      </div>
                      <img
                        src={DealImage}
                        height={110}
                        width={110}
                        alt="deal"
                        style={{ position: "relative" }}
                      />
                    </div> */}
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={6} className="alignad_box">
              <Grid item xs={12} md={12} className="create_container">
                <div className="">
                  <div
                    className="tablediv"
                    style={{
                      border: "0px",
                      maxHeight: "calc(100vh - 345px)",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    <table style={{ width: "100%" }}>
                      {this.props.i18n.language == "ar" ? (
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "right",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("tbl_head_status")}
                            </th>
                           

                            <th
                              style={{
                                textAlign: "right",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("tbl_head_duty_hours")}
                            </th>
                            <th
                              style={{
                                textAlign: "right",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("active_inactive")}
                            </th>
                            <th
                              style={{
                                textAlign: "right",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("deal")}
                            </th>
                            <th
                              style={{
                                textAlign: "right",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("title")}
                            </th>
                          </tr>
                        </thead>
                      ) : (
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("title")}
                            </th>
                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("deal")}
                            </th>

                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("active_inactive")}
                            </th>

                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                // padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("tbl_head_duty_hours")}
                            </th>

                            <th
                              style={{
                                textAlign: "left",
                                fontSize: "16px",
                                padding: "15px",
                                color: "black",
                                fontWeight: 500,
                              }}
                            >
                              {t("tbl_head_status")}
                            </th>
                          </tr>
                        </thead>
                      )}

                      <tbody>{this.getDealsBody()}</tbody>
                    </table>
                  </div>
                  {this.state.total_count !== "" &&
                    this.state.total_count > 10 && (
                      <div className="pagination__container" style={{direction : (localStorage.nurse_language == 'ar' ? 'rtl' : '')}}>
                        <div className="pagination__box deal_list">
                          <ReactPagination
                            limit={this.state.limit}
                            total_count={this.state.total_count}
                            getAdDetails={this.getPaginateList}
                            storePageNo={this.storePageNo}
                            initialPage={this.state.active_pageno}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Modalcomp
            xswidth={"xs"}
            clrchange="textclr"
            title="Delete Deals"
            visible={this.state.open}
            closemodal={this.handleClose}
            t={t}
            i18n={this.props.i18n}
          >
            <DeleteMedia
              closemodal={this.handleClose}
              deleteitem={this.deleteDealLIst}
              closeDeleteModel={this.handleClose}
            />
          </Modalcomp>
        </div>
      </Spin>
    );
  }
}
