import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import './Modalcomp.css'

export default class DeleteMedia extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="record_delete_container">
                <Grid container>
                    <Grid item xs={12} md={12} className="media_title_container">
                    {/* style={{marginBottom : "3rem !important"}} */}
                        <div className="mediadelete_container">
                            <p className="delete_para">{this.props.msg ? this.props.msg : t("common_delete_msg")}</p>
                            </div>
                        <div className="mediadelete_container"   style={{direction:this.props.i18n.language == "ar"? "rtl": ""}}
                        onClick={() => this.props.closemodal()}>
                            <Button className="detete_no">{t("no")}</Button>
                            <Button className="detete_yes"   style={{marginRight:this.props.i18n.language == "ar"? "10px": ""}} onClick={() => this.props.deleterow()}>{t('yes')}</Button>
                        </div>

                    </Grid>
                </Grid>
            </div>
        )
    }
}
