import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import dateFormat from "dateformat";
import Calender from "./Calendar";
import { Select, notification } from "antd";
import Axios from "axios";
import { apiurl } from "../../App";
import ShowNotification from "../ShowNotification/ShowNotification";

import "./Availability.css";

const { Option } = Select;

export default class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nurseNames: [],
      fromdate: new Date(),
      todate: new Date(),
      nurseId: "",
      nurseId_cal: "1",
      nurseavaliable: false,
      visible: false,
      successmsg: "",
      issuccess: false,
    };
  }

  nurseNames = () => {
    Axios({
      method: "POST",
      url: apiurl + "Nurse/getnurse",
      data: { nursevendorId: localStorage.getItem("nurse_vendor_id") || "12" },
    })
      .then((response) => {
        this.setState({ nurseNames: response.data.data });
      })
      .catch((err) => { });
  };

  componentWillMount() {
    this.nurseNames();
  }

  getNurseNames = () => {
    let nurses = [];
    for (let i = 0; i < this.state.nurseNames.length; i++) {
      nurses.push(
        <Option key={i + 1} value={this.state.nurseNames[i].NurseId}>
          {this.state.nurseNames[i].name}
        </Option>
      );
    }

    return nurses;
  };

  storeDate = (data, key) => {
    if (key === "fromdate") {
      this.setState({ fromdate: data, Selectrequired: false });
    }

    if (key === "todate") {
      this.setState({ todate: data, Selectrequired: false });
    }
  };

  LeaveorBlock = (endPoint) => {
    if (!this.state.selectsettrue) {
      this.setState({ Selectrequired: true });
    } else if (
      new Date(this.state.fromdate) > new Date(this.state.todate) ||
      new Date(this.state.fromdate) === new Date(this.state.todate)
    ) {
    } else {
      var data = {
        nurseId: this.state.nurseId,
        from_date: dateFormat(new Date(this.state.fromdate), "yyyy-mm-dd"),
        to_date: dateFormat(new Date(this.state.todate), "yyyy-mm-dd"),
        nursevendorId: localStorage.getItem("nurse_vendor_id") || "12",
      };
      // dateFormat(new Date(this.state.fromdate),"yyyy-mm-dd"),

      Axios({
        method: "POST",
        url: apiurl + `Nurse/${endPoint}`,
        data: data,
      })
        .then((response) => {
          debugger;
          if (response.data.msg == "Nurse Booking Already Exists") {
            this.handleopenSuccess("Already booked. Leave not approved.", false)
          } else {
            // this.handleopenSuccess(response.data.msg, true)
            this.handleopenSuccess("Leave approved successfully!", true)
          }
          // this.handleopenSuccess(response.data.msg,true)
          this.setState({ nurseavaliable: true });
        })
        .catch((err) => { });
    }
  };

  getRangeData = (data) => {
    if (data.enddate === null) {
      this.setState({ fromdate: data.startdate });
    } else {
      if (data.startdate < data.enddate) {
        this.setState({ fromdate: data.startdate, todate: data.enddate });
      } else {
        this.setState({ fromdate: data.enddate, todate: data.startdate });
      }
    }
  };

  storeNurse = (data) => {
    this.setState({
      nurseId: data,
      Selectrequired: false,
      selectsettrue: true,
      nurseavaliable: true,
    });
  };


  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  };

  render() {
    var errorstate =
      new Date(this.state.fromdate) > new Date(this.state.todate) ||
      new Date(this.state.fromdate) === new Date(this.state.todate);
    const { t } = this.props;
    return (
      <div className="availability_main_div">
        <Grid container>
          <Grid item sm={12} md={6}>
            <Calender
              getDate={(data) => this.getRangeData(data)}
              nurseavaliablefalse={() =>
                this.setState({ nurseavaliable: false })
              }
              nurseavaliable={this.state.nurseavaliable}
              nurseId={this.state.nurseId}
              isfromavailability={true}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <div
              style={{ padding: "20px" }}
              className="opacity_letter_availability"
            >
              {/* <Select style={{ width: "100%",marginBottom:"15px" }} onChange={(data) => this.storeNurse(data)} ></Select> */}
              <div className="formdiv" style={{ width: "40%" }}>
                <label className="labeltxt">{t('tbl_head_nurse')}</label>
              </div>
              <div style={{ width: "60%" }}></div>
              <Select
                style={{ width: "40%", marginBottom: "15px" }}
                onChange={(data) => this.storeNurse(data)}
                placeholder="Select Nurse"
              >
                {this.getNurseNames()}
              </Select>
              <div className="errormsgAvailability">
                {this.state.Selectrequired && "Field required"}
              </div>
              <div
                style={{
                  width: '40%',
                  float: 'left'
                }}
              >
                <Labelbox
                  type="datepicker"
                  className="user_datepicker"
                  labelname={t('tbl_head_from')}
                  value={this.state.fromdate}
                  changeData={(data) => this.storeDate(data, "fromdate")}
                  minDate={new Date()}
                  format="dd-MMM-yy"
                  disableFuture={true}
                  disableToolbar
                />
              </div>
              <div
                style={{
                  width: '40%',
                  float: 'right'
                }}
              >
                <Labelbox
                  type="datepicker"
                  className="user_datepicker"
                  labelname={t('tbl_head_to')}
                  value={this.state.todate}
                  changeData={(data) => this.storeDate(data, "todate")}
                  errmsg={"To date should be greater than from date"}
                  error={errorstate}
                  minDate={new Date()}
                  format="dd-MMM-yy"
                  disableFuture={true}
                  disableToolbar
                />
              </div>
              <div className="avail_button" style={{ marginTop: '2rem' }}>
                <div>
                  <button
                    type="button"
                    class="leave_btn"
                    onClick={() => this.LeaveorBlock("insertnurseleavedate")}
                  >
                    {t('leave')}
                  </button>
                </div>
                {/* <div>
                  <button
                    type="button"
                    class="btn btn-primary btn-lg block_btn"
                    onClick={() => this.LeaveorBlock("Blocknursedate")}
                  >
                    Block
                  </button>
                </div> */}
              </div>
            </div>
          </Grid>
        </Grid>

        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
      </div>
    );
  }
}
