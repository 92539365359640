import React from "react";
import Labelbox from "../../helpers/labelbox/labelbox";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import "./Managenursemodal.css";
import No_image_available from "../../Images/No_image_available.svg";

let moment = require("moment");
const styles = {};
export default class Profilepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cancel: null };
  }
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  GetNationality(data) {
    let arr = this.props.nationality.filter((obj) => {
      return obj.id == data;
    });
    if (arr.length > 0) {
      return arr[0].nationality;
    } else {
      return "";
    }
  }

  render() {
    const styles = "";
    const { viewData, classes, onClose, cancel, selectedValue, t, ...other } =
      this.props;
    return (
      <div className="nurse_popup_main_div new_ovwer_flow">
        <div className="nurse_image_container">
          <div className="ant-upload ant-upload-select">
            <img
              src={
                viewData.profile_image
                  ? viewData.profile_image
                  : No_image_available
              }
              className="upload-img-circle"
              alt="avatar"
              style={{ width: "100%", height: "123px" }}
            />
          </div>
        </div>

        <Grid container spacing={2} style={{ padding: "0px 20px 0px 20px", margin: 'auto', direction: this.props.i18n.language == 'ar' ? "rtl" : "" }}>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "3rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("nurse_name")}
              value={viewData.name}
              readOnly={true}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "3rem" }}
          >
            <Labelbox
              labelname={t("gender")}
              type="text"
              value={viewData.gender}
              readOnly={true}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "3rem" }}
          >
            <Labelbox
              type="text"
              className="user_datepicker"
              labelname={t("date_of_birth")}
              value={viewData.dob}
              readOnly={true}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("nationality")}
              value={viewData.nationality_name}
              readOnly={true}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="number"
              maxLength="2"
              labelname={t("experience_with_year")}
              value={viewData.experience}
              readOnly={true}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("qualification")}
              value={viewData.qualification}
              readOnly={true}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="number"
              labelname={t("contact_number")}
              value={viewData.mobileno}
              readOnly={true}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("email")}
              value={viewData.email}
              readOnly={true}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("languages")}
              value={viewData.language}
              readOnly={true}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("8_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '400 !important' }}>
                    {t("cost_month_kwd")}
                    <span style={{ fontSize: '10px' }}> {t("kwD")}</span>
                  </span>
                </span>
              </label>
              <div>
                <input
                  className="brdrcls"
                  min="0"
                  value={viewData.cost_eight_hours_month}
                  type="number"
                  readOnly
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("12_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '400 !important' }}>
                    {t("cost_month_kwd")}
                    <span style={{ fontSize: '10px' }}> {t("kwD")}</span>
                  </span>
                </span>
              </label>
              <div>
                <input
                  className="brdrcls"
                  min="0"
                  value={viewData.cost_twelve_hours_month}
                  type="number"
                  readOnly
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("skills")}
              value={viewData.skills}
              readOnly={true}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("8_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '400 !important' }}>
                    {t("cost_day_kwd")}
                    <span style={{ fontSize: '10px' }}> {t("kwD")}</span>
                  </span>
                </span>
              </label>
              <div>
                <input
                  className="brdrcls"
                  min="0"
                  value={viewData.cost_eight_hours}
                  type="number"
                  readOnly
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("12_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '400 !important' }}>
                    {t("cost_day_kwd")}
                    <span style={{ fontSize: '10px' }}> {t("kwD")}</span>
                  </span>
                </span>
              </label>
              <div>
                <input
                  className="brdrcls"
                  min="0"
                  value={viewData.cost_twelve_hours}
                  type="number"
                  readOnly
                  onKeyDown={(e) => e.preventDefault()}
                />
              </div>
            </div>
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("address")}
              value={viewData.address}
              readOnly={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}
const Trainer_viewWrapped = withStyles(styles)(Profilepage);