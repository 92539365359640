import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import Button from "@material-ui/core/Button";
import "./Nurse_form.css";
import { Upload } from "antd";
import "antd/dist/antd.css";
import ValidationLibrary from "../../helpers/validationfunction";
import Axios from "axios";
import { apiurl } from "../../App";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import UploadMedia from "./UploadMedia";
import ShowNotification from "../ShowNotification/ShowNotification";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import UserProfile from "../../Images/user_profile.svg";
import ReactSVG from "react-svg";
import PropTypes from "prop-types";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

class Nurse_form extends Component {
  state = {
    imageChanged: false,
    nurseActive: false,
    loading: false,
    modal: false,
    modalclose: false,
    imageUrl: "",
    imageData: [],
    gender: [
      {
        id: 1,
        value: "Male",
      },
      {
        id: 2,
        value: "Female",
      },
    ],
    manageNurse: {
      name: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      dob: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      experience: {
        value: "",
        validation: [
          { name: "required" },
          { name: "alphaNumaricOnly" },
          { name: "allowNumericOnlyNotZero" },
          { name: "custommaxLength", params: "2" },
        ],
        error: null,
        errmsg: null,
      },
      gender: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      nationality: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },
      mobile_number: {
        value: "",
        validation: [{ name: "required" }, { name: "mobile" }],
        error: null,
        errmsg: null,
      },
      qualification: {
        value: "",
        validation: [{ name: "required" }],
        error: null,
        errmsg: null,
      },

      cost_per_month_8hrs: {
        value: "",
        validation: [{ name: "required" }, { name: "duty_hrs" }],
        error: null,
        errmsg: null,
      },
      cost_per_month_12hrs: {
        value: "",
        validation: [{ name: "required" }, { name: "duty_hrs" }],
        error: null,
        errmsg: null,
      },
      cost_per_day_8hrs: {
        value: "",
        validation: [{ name: "required" }, { name: "" }],
        error: null,
        errmsg: null,
      },
      cost_per_day_12hrs: {
        value: "",
        validation: [{ name: "required" }, { name: "" }],
        error: null,
        errmsg: null,
      },
      a_ddress: {
        value: "",
        validation: [{ name: "required" }, { name: "" }],
        error: null,
        errmsg: null,
      },
      E_mail: {
        value: "",
        validation: [{ name: "required" }, { name: "email" }],
        error: null,
        errmsg: null,
      },
      language: {
        value: "",
        validation: [{ name: "required" }, { name: "" }],
        error: null,
        errmsg: null,
      },
      skills: {
        value: "",
        validation: [{ name: "required" }, { name: "" }],
        error: null,
        errmsg: null,
      },
    },
    visible: false,
    successmsg: "",
    issuccess: false,
    dobYears: []
  };

  handleQualificationChange = (data) => {
    // This regular expression allows Arabic letters, English letters, commas, dots, and spaces.
    const textOnly = data.replace(/[^\u0600-\u06FFa-zA-Z,. \s]/g, "");
    this.changeDynamic(textOnly, "qualification");
  };
  

  handleNameChange = (data) => {
    // This regular expression allows Arabic letters, English letters, and spaces.
    const textOnly = data.replace(/[^\u0600-\u06FFa-zA-Z\s]/g, "");
    this.changeDynamic(textOnly, "name");
  };
  handleNameChange = (data) => {
    // This regular expression allows a wide range of Unicode letters and spaces.
    const textOnly = data.replace(/[^\p{L}\s]/gu, "");
    this.changeDynamic(textOnly, "name");
  };
  

  changeDynamic = (value, key) => {
    this.setState({
      manageNurse: {
        ...this.state.manageNurse,
        [key]: {
          ...this.state.manageNurse[key],
          value: value,
        },
      },
    });
  };

  handleLanguageChange = (data) => {
    // This regular expression allows Arabic letters, English letters, commas, dots, and spaces.
    const textOnly = data.replace(/[^\u0600-\u06FFa-zA-Z,. \s]/g, "");
    this.changeDynamic(textOnly, "language");
  };
  

  changeDynamic = (value, key) => {
    this.setState({
      manageNurse: {
        ...this.state.manageNurse,
        [key]: {
          ...this.state.manageNurse[key],
          value: value,
        },
      },
    });
  };

  handleSkillsChange = (data) => {
    // This regular expression allows Arabic letters, English letters, commas, dots, and spaces.
    const textOnly = data.replace(/[^\u0600-\u06FFa-zA-Z,. \s]/g, "");
    this.changeDynamic(textOnly, "skills");
  };
  

  changeDynamic = (value, key) => {
    this.setState({
      manageNurse: {
        ...this.state.manageNurse,
        [key]: {
          ...this.state.manageNurse[key],
          value: value,
        },
      },
    });
  };

  componentWillMount() {
    const { editData, editopenModal } = this.props;
    this.state.dobYears = [];
    for (let i = 21; i <= 60; i++) {
      this.state.dobYears.push(<p key={i}>{i}</p>);
    }

    if (editopenModal === true) {
      this.setState((prevState) => ({
        imageUrl: editData.profile_image,
        manageNurse: {
          ...prevState.manageNurse,
          name: { ...prevState.manageNurse.name, value: editData.name },
          dob: { ...prevState.manageNurse.dob, value: editData.dob },
          experience: { ...prevState.manageNurse.experience, value: editData.experience },
          gender: { ...prevState.manageNurse.gender, value: editData.gender },
          nationality: { ...prevState.manageNurse.nationality, value: editData.nationality_id },
          mobile_number: { ...prevState.manageNurse.mobile_number, value: editData.mobileno },
          cost_per_month_8hrs: { ...prevState.manageNurse.cost_per_month_8hrs, value: editData.cost_eight_hours_month },
          cost_per_month_12hrs: { ...prevState.manageNurse.cost_per_month_12hrs, value: editData.cost_twelve_hours_month },
          cost_per_day_8hrs: { ...prevState.manageNurse.cost_per_day_8hrs, value: editData.cost_eight_hours },
          cost_per_day_12hrs: { ...prevState.manageNurse.cost_per_day_12hrs, value: editData.cost_twelve_hours },
          language: { ...prevState.manageNurse.language, value: editData.language },
          qualification: { ...prevState.manageNurse.qualification, value: editData.qualification },
          E_mail: { ...prevState.manageNurse.E_mail, value: editData.email },
          a_ddress: { ...prevState.manageNurse.a_ddress, value: editData.address },
          skills: { ...prevState.manageNurse.skills, value: editData.skills },
        },
        nurseActive: 1,
      }));
    } else {
      this.setState((prevState) => ({
        manageNurse: {
          ...prevState.manageNurse,
          dob: { ...prevState.manageNurse.dob, value: "" },
        },
      }));
    }


  }

  nurseActiveCheck = (e) => {
    this.setState({ nurseActive: e.target.checked });
  };

  changeDynamic = (data, key) => {
    if (key == "mobile_number") {
      if (data.length > 10) {
        return
      }
    }
    if (key == "cost_per_month_8hrs") {
      if (data.length > 4) {
        return
      }
      else if (data === "0") {
        return
      }
    }
    if (key == "cost_per_month_12hrs") {
      if (data.length > 4) {
        return
      }
      else if (data === "0") {
        return
      }

    }
    if (key == "cost_per_day_8hrs") {
      if (data.length > 4) {
        return
      }
      else if (data === "0") {
        return
      }
    }

    if (key == "cost_per_day_12hrs") {
      if (data.length > 4) {
        return
      }
      else if (data === "0") {
        return
      }

    }


    let manageNurse = this.state.manageNurse;
    let errorcheck = ValidationLibrary.checkValidation(
      data,
      manageNurse[key].validation
    );
    manageNurse[key].value = data;
    manageNurse[key].error = !errorcheck.state;
    manageNurse[key].errmsg = errorcheck.msg;
    this.setState({ manageNurse });
  };

  checkValidation = () => {
    let manageNurse = this.state.manageNurse;
    let self = this;
    let manageNurseKeys = Object.keys(manageNurse);
    for (let i in manageNurseKeys) {
      let errorcheck = ValidationLibrary.checkValidation(
        manageNurse[manageNurseKeys[i]].value,
        manageNurse[manageNurseKeys[i]].validation
      );
      manageNurse[manageNurseKeys[i]].error = !errorcheck.state;
      manageNurse[manageNurseKeys[i]].errmsg = errorcheck.msg;
    }
    let filtererr = manageNurseKeys.filter(
      (obj) => manageNurse[obj].error === true
    );

    if (this.state.manageNurse.dob.value == "" ||
      this.state.manageNurse.dob.value == null ||
      this.state.manageNurse.dob.value == undefined) {
      const { t } = this.props
      const fieldRequired = t("fieldRequired");
      this.state.manageNurse.dob.errmsg = fieldRequired
    }
    if (
      this.state.imageUrl == "" ||
      this.state.imageUrl == null ||
      this.state.imageUrl == undefined
    ) {
      const { t } = this.props;
      const profileImageRequired = t("profileImageRequired")
      self.props.handleopenSuccess(profileImageRequired, "imageRequired");
    }
    else if (filtererr.length > 0) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false });
      this.onSubmitData();
    }
    this.setState({ manageNurse });
    if (this.state.manageNurse.dob.value == "" ||
      this.state.manageNurse.dob.value == null ||
      this.state.manageNurse.dob.value == undefined) {
      const { t } = this.props
      const fieldRequired = t("fieldRequired");
      this.state.manageNurse.dob.errmsg = fieldRequired
    }
  };

  onSubmitData = () => {
    this.manageNurse();
  };

  convertDateFormat(inputDate) {
    const monthMapping = {
      'Jan': '01',
      'Feb': '02',
      'Mar': '03',
      'Apr': '04',
      'May': '05',
      'Jun': '06',
      'Jul': '07',
      'Aug': '08',
      'Sep': '09',
      'Oct': '10',
      'Nov': '11',
      'Dec': '12',
    };

    const parts = inputDate.split('-');
    const day = parts[0];
    const monthAbbreviation = parts[1];
    const year = parts[2];
    const month = monthMapping[monthAbbreviation];
    const outputDate = `${year}-${month}-${day}`;
    return outputDate;
  }

  formatDate(inputDate) {
    const originalDate = new Date(inputDate);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, '0');
    const day = String(originalDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
  };



  manageNurse = () => {
    let formData = new FormData();
    formData.set("name", this.state.manageNurse.name.value);
    formData.set("dob", this.state.manageNurse.dob.value);
    formData.set("experience", this.state.manageNurse.experience.value);
    formData.set("gender", this.state.manageNurse.gender.value);
    formData.set("mobileno", this.state.manageNurse.mobile_number.value);
    formData.set("address", this.state.manageNurse.a_ddress.value);
    formData.set("email", this.state.manageNurse.E_mail.value);
    formData.set("nationalityId", this.state.manageNurse.nationality.value);
    formData.set("costeighthours", this.state.manageNurse.cost_per_day_8hrs.value);
    formData.set("costTwelvehours", this.state.manageNurse.cost_per_day_12hrs.value);
    formData.set("skills", this.state.manageNurse.skills.value);
    formData.set("vendorId", localStorage.getItem("nurse_vendor_id") || "12");
    formData.set("createdby", 19);
    formData.set("qualification", this.state.manageNurse.qualification.value);
    formData.set("language", this.state.manageNurse.language.value);
    formData.append("uploadFile", this.state.imageUrl.split('?')[0]);
    formData.set("is_active", this.state.nurseActive === true ? 1 : 1);
    formData.set(
      "costeighthoursmonth",
      this.state.manageNurse.cost_per_month_8hrs.value
    );
    formData.set(
      "costTwelvehoursmonth",
      this.state.manageNurse.cost_per_month_12hrs.value
    );

    if (this.props.editopenModal === false) {
      this.insertNurse(formData);
    }
    if (this.props.editopenModal === true) {
      formData.set("id", this.props.editData.nurseId);
      formData.set("modifiedby", 19);
      this.updateNurseDetails(formData);
    }
  };

  insertNurse = (formData) => {
    let self = this;
    Axios({
      method: "POST",
      url: apiurl + "nurse/insertNurse",
      data: formData,
    })
      .then((response) => {
        if (response.data.status == 1) {
          self.props.handleopenSuccess("Nurse added successfully!", true);
          self.props.getTableData();
          self.props.closemodal();
        } else if (response.data.status == 2) {
          this.handleopenSuccess(response.data.msg, false);
        } else {
          this.handleopenSuccess("Nurse Added failed.", false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  updateNurseDetails = (nurseData) => {
    let self = this;
    Axios({
      method: "POST",
      url: apiurl + "nurse/editNurseInfo",
      data: nurseData,
    })
      .then((response) => {
        if (response.data.status == 1) {
          if (localStorage.nurse_language == 'ar') {
            self.props.handleopenSuccess(". تم التحديث بنجاح", true);
          } else {
            self.props.handleopenSuccess("Nurse updated successfully!", true);
          }
          self.props.getTableData();
          self.props.closemodal();
        }
        else if (response.data.status == 2) {
          // Check the language preference and display the appropriate message
          if (localStorage.nurse_language === 'ar') {
            this.props.handleopenSuccess(".محجوز بالفعل , لا يمكن التعديل", false);
          } else {
            this.handleopenSuccess("Already booked. Not able to edit.", false);
          }
        } else {
          this.handleopenSuccess("Nurse Details Update Failed.", false);
        }

        //
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (info) => {
    this.state.imageUrl = "";
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      const formData = new FormData();
      formData.append('module_id', "5");
      formData.append('file', info.file.originFileObj);
      formData.append('pathLocation', "NURSE-PROFILE/")

      Axios({
        method: "POST",
        url: apiurl + "admin/awsS3FileUpload",
        data: formData,
      }).then((response) => {
        this.setState({
          imageurlS3: response.data.filepath.Location,
          imageUrl: response.data.filepath.Location,
        });
      });
    }
  };

  handleChange333 = (e) => {
    console.log("wwww", e.target.files[0]);

    const formData = new FormData();
    formData.append('module_id', "5");
    formData.append('file', e.target.files[0]);
    formData.append('pathLocation', "NURSE PROFILE/")

    Axios({
      method: "POST",
      url: apiurl + "admin/awsS3FileUpload",
      data: formData,
    }).then((response) => {
      if (response.data.filepath.Location != null) {
        this.setState({ imageUrl: response.data.filepath.Location });
      }
    });
  };

  data = (date, dateString) => {
    console.log(date, dateString);
  };
  onclose = () => {
    this.setState({ modalclose: !this.state.modalclose });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      this.handleopenSuccess("You can only upload JPG/PNG file!", false);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.handleopenSuccess("Image must smaller than 2MB!", false);
    }
    return isJpgOrPng && isLt2M;
  };

  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  };

  render() {
    const { t } = this.props;
    const uploadButton = (
      <div>
        <div className="nurse_upload_icon">
          <ReactSVG src={UserProfile} style={{ cursor: "pointer" }} />
        </div>
      </div>
    );

    return (
      <div className="nurse_popup_main_div new_ovwer_flow">
        <div className="nurse_image_container">
          <Upload
            name="avatar"
            className="avatar-uploader user-avatar-upload"
            showUploadList={false}
            action={apiurl + "admin/uploadProfileImage"}
            beforeUpload={this.beforeUpload}
            onChange={(e) => {
              this.handleChange(e);
            }}
          >
            <div className="nurse_pic_align">
              <AddAPhotoIcon className="add_icon" />
            </div>
            {this.state.imageUrl ? (
              <img
                src={this.state.imageUrl}
                className="upload-img-circle"
                alt="avatar"
                style={{ width: "100%", height: "123px" }}
              />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>

        <Grid container spacing={2} style={{ padding: "0px 20px 0px 20px", margin: 'auto', direction: this.props.i18n.language == 'ar' ? "rtl" : "" }}>
          <Grid
            item
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "3rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("nurse_name")}
              changeData={(data) => this.handleNameChange(data)}
              value={this.state.manageNurse.name.value}
              error={this.state.manageNurse.name.error}
              errmsg={this.state.manageNurse.name.errmsg}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "3rem" }}
          >
            <Labelbox
              type="select"
              labelname={t("gender")}
              valuelabel={"value"}
              valuebind={"value"}
              dropdown={this.state.gender}
              changeData={(data) => this.changeDynamic(data, "gender")}
              value={this.state.manageNurse.gender.value}
              error={this.state.manageNurse.gender.error}
              errmsg={this.state.manageNurse.gender.errmsg}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "3rem" }}
          >
            <Labelbox
              type="select" date_of_birth
              labelname={
                <>
                  <span>{t("date_of_birth")}</span>
                  <span style={{
                    fontSize: "11px",
                    fontWeight: "500"
                  }}></span>
                </>}
              valuelabel={"key"}
              valuebind={"key"}
              dropdown={this.state.dobYears}
              changeData={(data) => this.changeDynamic(data, "dob")}
              value={this.state.manageNurse.dob.value ? this.state.manageNurse.dob.value : "Select"}
              error={this.state.manageNurse.dob.error}
              errmsg={this.state.manageNurse.dob.errmsg}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="select"
              labelname={t("nationality")}
              valuelabel={"nationality"}
              valuebind={"id"}
              dropdown={this.props.nationality}
              changeData={(data) => this.changeDynamic(data, "nationality")}
              value={this.state.manageNurse.nationality.value}
              error={this.state.manageNurse.nationality.error}
              errmsg={this.state.manageNurse.nationality.errmsg}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="number"
              maxLength="2"
              labelname={
                <>
                  <span>{t("experience_with_year")}</span>
                  {localStorage.nurse_language == 'ar' ? "" : <span style={{
                    fontSize: "11px",
                    fontWeight: "500"
                  }}>&nbsp;{t("yrs")}</span>}

                </>}

              changeData={(data) => this.changeDynamic(data, "experience")}
              value={this.state.manageNurse.experience.value}
              error={this.state.manageNurse.experience.error}
              errmsg={this.state.manageNurse.experience.errmsg}
            />
          </Grid>

          <Grid
            item
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("qualification")}
              value={this.state.manageNurse.qualification.value}
              changeData={(data) => this.handleQualificationChange(data)}
              error={this.state.manageNurse.qualification.error}
              errmsg={this.state.manageNurse.qualification.errmsg}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="number"
              maxLength={1}
              labelname={t("contact_number")}
              changeData={(data) => this.changeDynamic(data, "mobile_number")}
              value={this.state.manageNurse.mobile_number.value}
              error={this.state.manageNurse.mobile_number.error}
              errmsg={this.state.manageNurse.mobile_number.errmsg}
            />
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("email")}
              changeData={(data) => this.changeDynamic(data, "E_mail")}
              value={this.state.manageNurse.E_mail.value}
              error={this.state.manageNurse.E_mail.error}
              errmsg={this.state.manageNurse.E_mail.errmsg}
            />
          </Grid>

          <Grid
            item
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("languages")}
              changeData={(data) => this.handleLanguageChange(data)} // Call the new handler here
              value={this.state.manageNurse.language.value}
              error={this.state.manageNurse.language.error}
              errmsg={this.state.manageNurse.language.errmsg}
            />
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("8_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '500' }}>
                    {t("cost_month_kwd")}
                    <span style={{ fontSize: '10px', fontWeight: '500' }}> {t("kwD")}</span>
                  </span>{" "}
                </span>
              </label>
              <div>
                <input
                  className={`${this.state.manageNurse.cost_per_month_8hrs.error && "brdred"
                    } brdrcls`}
                  min="0"
                  value={this.state.manageNurse.cost_per_month_8hrs.value}
                  type="number"
                  onChange={(e) =>
                    this.changeDynamic(e.target.value, "cost_per_month_8hrs")
                  }
                  onKeyDown={(e) =>
                    (e.key === "e" || e.key === "+" || e.key === "-") &&
                    e.preventDefault()
                  }
                />
                {
                  <div className="Errormsg">
                    <div>
                      {this.state.manageNurse.cost_per_month_8hrs.error &&
                        this.state.manageNurse.cost_per_month_8hrs.errmsg}
                    </div>
                  </div>
                }
              </div>
            </div>
          </Grid>
          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("12_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '500' }}>
                    {t("cost_month_kwd")}
                    <span style={{ fontSize: '10px', fontWeight: '500' }}> {t("kwD")}</span>
                  </span>
                </span>
              </label>
              <div>
                <input
                  className={`${this.state.manageNurse.cost_per_month_12hrs.error &&
                    "brdred"
                    } brdrcls`}
                  min="0"
                  value={this.state.manageNurse.cost_per_month_12hrs.value}
                  type="number"
                  onChange={(e) =>
                    this.changeDynamic(e.target.value, "cost_per_month_12hrs")
                  }
                  onKeyDown={(e) =>
                    (e.key === "e" || e.key === "+" || e.key === "-") &&
                    e.preventDefault()
                  }
                />
                {
                  <div className="Errormsg">
                    <div>
                      {this.state.manageNurse.cost_per_month_12hrs.error &&
                        this.state.manageNurse.cost_per_month_12hrs.errmsg}
                    </div>
                  </div>
                }
              </div>
            </div>
          </Grid>


          <Grid
            item
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("skills")}
              changeData={(data) => this.handleSkillsChange(data)} // Call the new handler here
              value={this.state.manageNurse.skills.value}
              error={this.state.manageNurse.skills.error}
              errmsg={this.state.manageNurse.skills.errmsg}
            />
          </Grid>


          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("8_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '500' }}>
                    {t("cost_day_kwd")}
                    <span style={{ fontSize: '10px', fontWeight: '500' }}> {t("kwD")}</span>
                  </span>
                </span>
              </label>
              <div>
                <input
                  className={`${this.state.manageNurse.cost_per_day_8hrs.error &&
                    "brdred"
                    } brdrcls`}
                  min="0"
                  value={this.state.manageNurse.cost_per_day_8hrs.value}
                  type="number"
                  onChange={(e) =>
                    this.changeDynamic(e.target.value, "cost_per_day_8hrs")
                  }
                  onKeyDown={(e) =>
                    (e.key === "e" || e.key === "+" || e.key === "-") &&
                    e.preventDefault()
                  }
                />
                {
                  <div className="Errormsg">
                    <div>
                      {this.state.manageNurse.cost_per_day_8hrs.error &&
                        this.state.manageNurse.cost_per_day_8hrs.errmsg}
                    </div>
                  </div>
                }
              </div>
            </div>
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <div className="formdiv">
              <label className={`${this.props.i18n.language == "ar" ? "ml-auto" : ""} labeltxt`} style={{ width: "95%" }}>
                <span>{t("12_hours_duty")}</span>
                <span className="sub_label">
                  {" "}
                  <span style={{ fontSize: '15px', fontWeight: '500' }}>
                    {t("cost_day_kwd")}
                    <span style={{ fontSize: '10px', fontWeight: '500' }}> {t("kwD")}</span>
                  </span>
                </span>
              </label>
              <div>
                <input
                  className={`${this.state.manageNurse.cost_per_day_12hrs.error &&
                    "brdred"
                    } brdrcls`}
                  min="0"
                  value={this.state.manageNurse.cost_per_day_12hrs.value}
                  type="number"
                  onChange={(e) =>
                    this.changeDynamic(e.target.value, "cost_per_day_12hrs")
                  }
                  onKeyDown={(e) =>
                    (e.key === "e" || e.key === "+" || e.key === "-") &&
                    e.preventDefault()
                  }
                />
                {
                  <div className="Errormsg">
                    <div>
                      {this.state.manageNurse.cost_per_day_12hrs.error &&
                        this.state.manageNurse.cost_per_day_12hrs.errmsg}
                    </div>
                  </div>
                }
              </div>
            </div>
          </Grid>

          <Grid
            items
            xs={4}
            md={4}
            className="items_container"
            style={{ marginTop: "1rem" }}
          >
            <Labelbox
              type="text"
              labelname={t("address")}
              changeData={(data) => this.changeDynamic(data, "a_ddress")}
              value={this.state.manageNurse.a_ddress.value}
              error={this.state.manageNurse.a_ddress.error}
              errmsg={this.state.manageNurse.a_ddress.errmsg}
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          md={12}
          className="buttons_container"
          justify="center"
          style={{ direction: this.props.i18n.language == 'ar' ? "rtl" : "" }}
        >
          <div className="cancel-create-button1">
            <div className="cancel-create-button2">
              <Button
                className="Usergrp-Cancel"
                onClick={() => this.props.closemodal()}
              >
                {t("cancel")}
              </Button>
              <Button className="Usergrp-Create" onClick={this.checkValidation} style={{ marginRight: this.props.i18n.language == 'ar' ? "15px" : "" }}>
                {this.props.editopenModal === true ? t("update") : t("create")}
              </Button>
            </div>
          </div>
        </Grid>

        <Modalcomp
          clrgreen
          modelwidthClass={`${this.props.i18n.language == "ar" ? "arabic_cls" : ""
            } upload_ins_popup`}
          title={t("upload_instruction")}
          visible={this.state.open}
          closemodal={this.handleClose}
          clrchange="textclr"
          t={t}
          i18n={this.props.i18n}
        >
          <UploadMedia t={t} i18n={this.props.i18n} />
        </Modalcomp>
        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={this.handleCloseSuccess}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
      </div>
    );
  }
};

Nurse_form.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  closemodal: PropTypes.func.isRequired,
  getTableData: PropTypes.func.isRequired,
  editData: PropTypes.object,
  editopenModal: PropTypes.func,
  editData: PropTypes.shape({
    t: PropTypes.func.isRequired,
    media_filename: PropTypes.string,
    id: PropTypes.string,
    is_active: PropTypes.string,
  }),
};
Nurse_form.defaultProps = {
  editData: null,
};
export default Nurse_form;
