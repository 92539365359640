import React, { Component } from "react";

import Paper from "@material-ui/core/Paper";

import Availability from "./Availability";
import i18n from "../../i18n/i18n";
import { withTranslation } from 'react-i18next';
class RevenueMaster extends Component {
  render() {
    const { t } = this.props;
    return (
      <Paper>
        <div className="title_dashboard" style={{justifyContent:(this.props.i18n.language=='ar'?'end':'start')}}>
          <div className="title_header">{t('manage_schedule')}</div>
        </div>
        <Availability t={t} i18n={this.props.i18n}/>
      </Paper>
    );
  }
}
export default withTranslation()(RevenueMaster);
