import React from "react";
import ReactPaginate from "react-paginate";
import "./Pagination.css";

var round = "";

export default class ReactPagination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      perPage: this.props.limit,
      currentPage: 0,
      offset: 5,
      pageCount: this.props.total_count,
    };

    round = Math.ceil(this.state.pageCount / this.state.perPage);
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset,
      },
      () => this.props.getAdDetails(this.state.currentPage)
    );
  };
  render() {
    return (
      <>
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={round}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={this.handlePageClick}
          containerClassName={"mypagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          initialPage={this.props.initialPage}
        />
      </>
    );
  }
}
