import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import TickMark from '../../Images/Group23311.svg';
import "./PaymentSuccess.css";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { apiurl, imageUrl, decryptKey, paymentsuccessurl} from "../../App";
import SuccessTickMark from '../../Images/tickmark.svg';

import Axios from "axios";

export default class PaymentSuccess extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
         is_success:true,
         sec:5
      }
    }

    componentDidMount()
    {
      debugger;
        let searchParams = new URLSearchParams(window.location.search);
        let resultValue = searchParams.get('result');
        if (resultValue && resultValue!="")
        {
            resultValue = resultValue.replace(/p1L2u3S/g, '+' ).replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '=')
            let response_data= this.decrypt(resultValue, decryptKey);
            if(response_data !="")
            {
                response_data = response_data.split("&")
                  // Creating a dictionary to store key-value pairs
                const dataObj = {};

                // Populating the dictionary with key-value pairs
                response_data.forEach(pair => {
                  const [key, value] = pair.split('=');
                  dataObj[key] = value;
                });

                if(dataObj.result=="CAPTURED")
                {
                  let requestdata = {
                    paymentid: dataObj['paymentid'],
                    result: dataObj['result'],
                    auth: dataObj['auth'],
                    avr: dataObj['avr'],
                    ref: dataObj['ref'],
                    tranid: dataObj['tranid'],
                    postdate: dataObj['postdate'],
                    trackid: dataObj['trackid'],
                    udf1: dataObj['udf1'],
                    udf2: dataObj['udf2'],
                    udf3: dataObj['udf3'],
                    udf4: dataObj['udf4'],
                    udf5: dataObj['udf5'],
                    amt: dataObj['amt']
                  }

                  Axios({
                    method: "POST",
                    url: apiurl + "Patient/knetPaymentTransactions",
                    data: requestdata,
                  })
                    .then((response) => {
                     this.setState({is_success:true})
                      localStorage.setItem('paymentstatus','completed');
                      localStorage.setItem('resultindicator', dataObj['paymentid']);
                      localStorage.setItem('referenceid',dataObj['tranid']);
                      localStorage.setItem('paymentGatewayName',"KNET");
                      localStorage.setItem('paymentObject', JSON.stringify(dataObj));
                      this.startTimer()
                    })
                    .catch((err) => {
                      this.setState({is_success:false})
                      localStorage.setItem('paymentstatus','failed');
                      this.startTimer()
                    });

                  
                  
                // window.location.href= window.location.origin +"/trainingcenter/?/Home/advertise";
                }
                else
                {
                  this.setState({is_success:false})
                  localStorage.setItem('paymentstatus','failed');
                  localStorage.setItem('resultindicator', dataObj['paymentid']);
                  localStorage.setItem('referenceid',dataObj['tranid']);
                  localStorage.setItem('paymentGatewayName',"KNET");
                  localStorage.setItem('paymentObject', JSON.stringify(dataObj));
                  this.startTimer()
                }

                
            }
            else
            {
              this.startTimer()
            }
        }
        else
        {
          this.startTimer()
        }
    }

    
    startTimer ()
    {
      clearInterval(this.IntervalId);
        this.IntervalId = setInterval(() => {
          this.setState({sec : this.state.sec-1},()=>{
            if(this.state.sec==0)
            {
               window.location.href="nursemodule/?/Home/advertisement"
            }
          })
        }, 1000);
    }

    decrypt (data,termResourceKey)
    {
      const decodedData = atob(data);
      let result = '';
      const keyLength = termResourceKey.length;
      for (let i = 0; i < decodedData.length; i++) {
          const char = decodedData[i];
          const keyChar = termResourceKey[i % keyLength];
          result += String.fromCharCode(char.charCodeAt(0) - keyChar.charCodeAt(0));
      }
      return result;
    }
    

    updatePaymentStatus(id,status,payment_id)
    {
        var obj={
                    "ad_id":id,
                    "status":status ,
                    "payment_id": payment_id  
                }
          
          Axios({
            method: "POST",
            url: apiurl + "Diet/adUpdatePayment",
            data: obj,
          })
            .then((response) => {
               console.log(response)
            })
            .catch((error) => {
              // alert(JSON.stringify(error))
            });
    }

    render()
    {
        return(
            <div>
                <div style={{width:'75%',marginLeft:'auto',marginRight:'auto',height:'calc(100vh)',marginTop:'4rem'}}>
                      <div style={{display: (this.state.is_success?"block":"none")}}>
                          <div style={{textAlign:'center'}}> 
                               <img src={SuccessTickMark} className="tick_mark"/>
                          </div>
                          <div style={{textAlign:'center',marginTop:'1rem',fontSize:'5rem',fontWeight:'bold'}}> 
                              Your payment was successful
                          </div>
                          <div style={{textAlign:'center',marginTop:'1rem',fontSize:'2rem'}}>
                              you will be redirect to Nurse site with in {this.state.sec} seconds
                          </div>

                      </div>
                      <div style={{display: (this.state.is_success?"none":"block")}}>
                            <div style={{textAlign:'center'}}> 
                               <CancelOutlinedIcon className="cross_mark"></CancelOutlinedIcon>
                            </div>
                            <div style={{textAlign:'center',marginTop:'1rem',fontSize:'5rem',fontWeight:'bold'}}> 
                                Your payment failed
                            </div>
                            <div style={{textAlign:'center',marginTop:'1rem',fontSize:'2rem'}}>
                                you will be redirect to Nurse site with in {this.state.sec} seconds
                            </div>
                      </div>
                </div>
            </div>
        )
    }
}